/* @flow */
import React, { useContext } from 'react';
import View from './View';
import {
    default as adaptResidentialListingData,
    getTracker as getResidentialListingTracker,
} from './adaptResidentialListingData';
import { default as adaptProjectProfileData, getTracker as getProjectProfileTracker } from './adaptProjectProfileData';
import type { GetBuyResidentialListing, GetEventTracker, GetProjectProfile } from '../../../../models/lexa';
import type { AgentContactService, OnSuccess } from './types';
import defaultAgentContactService from './service';
import { type User } from '@realestate.com.au/user';
import type { PrefillData } from './enquiry-prefill';
import ChannelContext from '../../../../util/ChannelContext';
import ViewWithLogin from './ViewWithLogin';

type BaseProps<GetListing> = {|
    data: GetListing & GetEventTracker,
    agentContactService?: AgentContactService,
    onSuccess: OnSuccess,
    getUser?: () => User,
    prefillData?: PrefillData,
    savePrefillData?: (PrefillData) => void,
|};

export type ResidentialListingProps = BaseProps<GetBuyResidentialListing>;

export const ResidentialListingContactAgentForm = ({
    data,
    agentContactService = defaultAgentContactService,
    onSuccess,
    getUser,
    prefillData,
    savePrefillData,
}: ResidentialListingProps) => {
    const channel = useContext(ChannelContext);
    const adaptedData = adaptResidentialListingData(channel)(data);
    const { preEnquiryLogin, shouldRenderAgentContactForm } = adaptedData;

    if (shouldRenderAgentContactForm) {
        return preEnquiryLogin ? (
            <ViewWithLogin
                {...adaptedData}
                data={data}
                agentContactService={agentContactService}
                onSuccess={onSuccess}
                tracker={getResidentialListingTracker(data)}
                getUser={getUser}
                prefillData={prefillData}
                savePrefillData={savePrefillData}
                channel={channel}
            />
        ) : (
            <View
                {...adaptedData}
                data={data}
                agentContactService={agentContactService}
                onSuccess={onSuccess}
                tracker={getResidentialListingTracker(data)}
                getUser={getUser}
                prefillData={prefillData}
                savePrefillData={savePrefillData}
                channel={channel}
            />
        );
    } else {
        return null;
    }
};

export type ProjectProfileProps = BaseProps<GetProjectProfile>;

export const ProjectProfileContactAgentForm = ({
    data,
    agentContactService = defaultAgentContactService,
    onSuccess,
    getUser,
    prefillData,
    savePrefillData,
}: ProjectProfileProps) => {
    const channel = useContext(ChannelContext);
    const adaptedData = adaptProjectProfileData(channel)(data);
    const { preEnquiryLogin, shouldRenderAgentContactForm } = adaptedData;

    if (shouldRenderAgentContactForm) {
        return preEnquiryLogin ? (
            <ViewWithLogin
                {...adaptedData}
                data={data}
                agentContactService={agentContactService}
                onSuccess={onSuccess}
                tracker={getProjectProfileTracker(data)}
                getUser={getUser}
                prefillData={prefillData}
                savePrefillData={savePrefillData}
                channel={channel}
            />
        ) : (
            <View
                {...adaptedData}
                data={data}
                agentContactService={agentContactService}
                onSuccess={onSuccess}
                tracker={getProjectProfileTracker(data)}
                getUser={getUser}
                prefillData={prefillData}
                savePrefillData={savePrefillData}
                channel={channel}
            />
        );
    } else {
        return null;
    }
};
