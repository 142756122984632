/* @flow */
import React from 'react';
import adaptResidentialListingData from './adaptResidentialListingData';
import adaptProjectProfileData from './adaptProjectProfileData';
import View from './View';
import type { GetResidentialListing, GetProjectProfile } from '../../../../models/lexa';

type ResidentialListingProps = {
    data: GetResidentialListing,
};

type ProjectProfileProps = {
    data: GetProjectProfile,
};

export const ViewWithResidentialFragment = ({ data }: ResidentialListingProps) => {
    const adaptedData = adaptResidentialListingData(data);
    return adaptedData ? <View {...adaptedData} /> : null;
};

export const ViewWithProjectFragment = ({ data }: ProjectProfileProps) => {
    const adaptedData = adaptProjectProfileData(data);
    return adaptedData ? <View {...adaptedData} /> : null;
};
