/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import dataAdapter, { type RequiredProps } from './dataAdapter';
import type { GetProjectProfile, ProjectProfile } from '../../../models/lexa';
import type { Geocode } from '../../../models/lexa';
import ProjectSchools from './ProjectSchools.graphql';

export default compose<GetProjectProfile, ProjectProfile, RequiredProps, ?Geocode>(
    dataAdapter,
    getFragmentData(ProjectSchools),
    (data) => data.getListing()
);
