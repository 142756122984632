/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { ProjectProfileLexaData, ProjectProfileDataType, AdaptedProps, GetEventTrackerType } from './types';
import ProjectPhoneNumber from './ProjectPhoneNumber.graphql';

const PAGE_SOURCE = 'ss sticky call button';
const LISTER_PLACEHOLDER_ID = '1';

const dataAdapter =
    (callAgentButtonClick: GetEventTrackerType) =>
    ({ phoneNumber: { display, showDisclaimer }, name }: ProjectProfileLexaData): AdaptedProps => {
        const adaptedLister = [
            {
                id: LISTER_PLACEHOLDER_ID,
                name: name,
                phoneNumber: display,
                callAgentButtonClickTracker: () => callAgentButtonClick(),
            },
        ];
        return {
            listers: adaptedLister,
            showDisclaimer,
            showLogo: true,
            pageSource: PAGE_SOURCE,
        };
    };

const getAdaptedListingData = (callAgentButtonClick: GetEventTrackerType) =>
    compose(dataAdapter(callAgentButtonClick), getFragmentData(ProjectPhoneNumber), (data: ProjectProfileDataType) =>
        data.getListing()
    );

export default (data: ProjectProfileDataType) => ({
    ...getAdaptedListingData(data.getEventTracker().callAgentButtonClick)(data),
});
