/* @flow */

export type Preloader = {
    promise: Promise<string>,
    destroy: () => void,
};

export default (url: string): Preloader => {
    const img: Image = new Image();
    const promise: Promise<string> = new Promise((resolve, reject) => {
        img.onload = () => {
            resolve(url);
        };
        img.onerror = () => {
            reject(new Error(`Image ${url} cannot be loaded`));
        };
        img.src = url;
    });
    const destroy = () => {
        img.onerror = img.onload = null;
        img.src = '';
    };
    return {
        promise,
        destroy,
    };
};
