/* @flow */
import React, { useContext } from 'react';
import View from './View';
import adaptResidentialListingData from './adaptResidentialListingData';
import adaptProjectProfileData from './adaptProjectProfileData';
import type { GetProjectProfile, GetResidentialListing, GetEventTracker, GetTrackingData } from '../../models/lexa';
import { adaptDetailsAdSlideData } from './addons/ad-slide';
import { adaptDetailsSidebarAdData } from './addons/sidebar-ad';
import ChannelContext from '../../util/ChannelContext';
import MediaViewerContext from './provider/MediaViewerContext';

type Props<D> = {|
    data: D & GetEventTracker & GetTrackingData,
    contactAgentEl?: () => ?Element,
    showSlideAddon?: boolean,
    fallback?: ComponentType<*>,
|};

export const MediaViewerForDetails = ({ data, contactAgentEl, ...rest }: Props<GetResidentialListing>) => {
    const channel = useContext(ChannelContext);
    const { isOpen } = useContext(MediaViewerContext);

    return isOpen ? (
        <View
            {...adaptResidentialListingData(data, channel, contactAgentEl ? contactAgentEl() : null)}
            addons={[adaptDetailsAdSlideData(data), adaptDetailsSidebarAdData(data)].filter((a) => !!a)}
            {...rest}
        />
    ) : null;
};

export const ProjectProfileMediaViewer = ({ data }: Props<GetProjectProfile>) => {
    const { isOpen } = useContext(MediaViewerContext);

    return isOpen ? <View {...adaptProjectProfileData(data)} addons={[]} /> : null;
};

export { default as SlideNames } from './slideNames';
export type { SlideName } from './slideNames';
