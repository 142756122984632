/* @flow */
import './styles.scss';
import React, { Suspense, useContext, type ComponentType } from 'react';
import { shouldDisplayModal } from '../../client/device-capability';
import type { PowerProfile } from './slides/powerProfile';
import type { AgencyBranding } from './slides/agencyBranding';
import type { ThreeDimensionalTourItem } from './slides/3d-tour';
import type { VideoItem } from './slides/video';
import type { PhotoOverlayGalleryItem } from './slides/photoOverlayWithGallery/index';
import ErrorBoundaryNewRelicReporter from '../error-boundary-new-relic-reporter';
import MediaViewerContext from './provider/MediaViewerContext';
import slideNames, { type SlideName } from './slideNames';

const MediaViewerLibrary = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "media-viewer" */
            'media-viewer'
        )
);
const shouldBeFullscreen = () => !shouldDisplayModal();

export type MediaType =
    | 'photo'
    | 'floorplan'
    | 'photo overlay'
    | 'power profile'
    | 'photo overlay with gallery'
    | 'agency branding';

export type MediaItem =
    | PhotoItem
    | FloorplanItem
    | PhotoOverlayItem
    | PowerProfile
    | ThreeDimensionalTourItem
    | VideoItem
    | PhotoOverlayGalleryItem
    | AgencyBranding;

export type PhotoItem = {
    mediaType: 'photo',
    src: {
        url: string,
    },
};

export type FloorplanItem = {
    mediaType: 'floorplan',
    src: {
        url: string,
    },
};

export type PhotoOverlayItem = {
    mediaType: 'photo overlay',
    src: {
        url: string,
    },
    heading: string,
    subHeading: string,
    link: {
        href: string,
        title: string,
    },
};

type Props = {
    mediaItems: Array<MediaItem>,
    eventTracker?: Function,
    addons: Array<?Object>,
    MediaViewer?: ComponentType<*>,
    fallback?: ComponentType<*>,
};

const getIndexFor = (mediaItems: Array<MediaItem>, openSlide: SlideName | number): number => {
    const { FLOORPLAN, VIRTUAL_TOUR, VIDEO, FIRST, SECOND, THIRD, FOURTH, LAST } = slideNames;
    if (typeof openSlide === 'number') {
        return openSlide;
    }
    switch (openSlide) {
        case FLOORPLAN:
        case VIRTUAL_TOUR:
        case VIDEO:
            return mediaItems.findIndex((item) => item.mediaType === openSlide);
        case FIRST:
            return 0;
        case SECOND:
            return mediaItems.length === 1 ? 0 : 1;
        case THIRD:
            return 2;
        case FOURTH:
            return 3;
        case LAST:
            return mediaItems.length - 1;
        default:
            return 0;
    }
};

const MediaViewerComponent = ({
    mediaItems,
    eventTracker,
    addons,
    MediaViewer = MediaViewerLibrary,
    fallback = null,
}: Props) => {
    const { slideToOpen, closeMediaViewer } = useContext(MediaViewerContext);
    const openAtIndex = getIndexFor(mediaItems, slideToOpen);

    return (
        <ErrorBoundaryNewRelicReporter component="MediaViewer">
            <Suspense fallback={fallback}>
                <MediaViewer
                    fullscreen={shouldBeFullscreen()}
                    closeHandler={closeMediaViewer}
                    items={mediaItems}
                    afterChangeHandler={eventTracker}
                    openAtItem={openAtIndex}
                    addons={addons}
                    showCaption={false}
                />
            </Suspense>
        </ErrorBoundaryNewRelicReporter>
    );
};

export default MediaViewerComponent;
