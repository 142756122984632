import styled from 'styled-components';
import { spacingHelper, Divider, Button } from '@rea-group/construct-kit-core';

export const FormContentContainer = styled.div`
    padding: ${spacingHelper('medium')};

    ${({ theme }) => theme.breakpoints.large} {
        padding: ${spacingHelper('extraLarge')};
    }
    gap: ${spacingHelper('large')};
    display: grid;
`;

export const FooterDivider = styled(Divider)`
    margin: 0;
`;

export const FooterContainer = styled.div`
    padding: ${spacingHelper('medium')};
    display: flex;
    justify-content: space-between;
`;

export const FooterButton = styled(Button)`
    flex: 1;
    ${({ theme }) => theme.breakpoints.large} {
        flex: unset;
    }

    // add space between CTAs when 2 are shown
    &:nth-child(2) {
        margin-left: ${spacingHelper('medium')};
        ${({ theme }) => theme.breakpoints.large} {
            margin-left: 0;
        }
    }
`;
