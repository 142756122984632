/* @flow */
import React, { useContext } from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { GetBuyResidentialListing, GetRentResidentialListing } from '../../../../../models/lexa';
import ChannelContext from '../../../../../util/ChannelContext';

type Props = {|
    data: GetBuyResidentialListing | GetRentResidentialListing,
|};

const NextInspection = ({ data }: Props) => {
    const channel = useContext(ChannelContext);
    const adaptedData = adaptLexaData(channel)(data);
    return adaptedData ? <View {...adaptedData} /> : null;
};

export default NextInspection;
