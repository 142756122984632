/* @flow */
import React from 'react';
import View from './View';
import adaptResidentialListingData from './adaptResidentialListingData';
import adaptProjectProfileData from './adaptProjectProfileData';
import type { ResidentialDetailsGetters, ProjectDetailsGetters } from '../../../models/lexa';
import { type SlideName } from '../../media-viewer';

type ResidentialListingProps = {
    data: ResidentialDetailsGetters,
    openMediaViewer: (name: SlideName) => void,
    className?: string,
};

type ProjectProfileProps = {
    data: ProjectDetailsGetters,
    openMediaViewer: (name: SlideName) => void,
    className?: string,
};

export const ResidentialMediaTypeBar = ({ data, openMediaViewer, className }: ResidentialListingProps) => {
    return <View {...adaptResidentialListingData(openMediaViewer)(data)} className={className} />;
};

export const ProjectMediaTypeBar = ({ data, openMediaViewer, className }: ProjectProfileProps) => {
    return <View {...adaptProjectProfileData(openMediaViewer)(data)} className={className} />;
};
