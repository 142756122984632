import styled, { css } from 'styled-components';
import { Button, Text } from '@rea-group/construct-kit-core';

const lineHeight = '1.5em';

type TellMeMoreContainerProps = {
    $isExpanded: boolean;
    $isShort: boolean;
};

export const TellMeMoreContainer = styled.div<TellMeMoreContainerProps>`
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: calc(-2 * ${lineHeight});
        right: 0;
        width: 100%;
        height: calc(4 * ${lineHeight});
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }

    ${(props) =>
        props.$isExpanded || props.$isShort
            ? css`
                  height: auto;
                  &:after {
                      content: none;
                  }
              `
            : `height: calc(5 * ${lineHeight});`}
`;

type TellMeMoreText = {
    $isExpanded: boolean;
};

export const TellMeMoreText = styled.span<TellMeMoreText>`
    ${(props) =>
        props.$isExpanded
            ? css`
                  padding-bottom: 20px;
              `
            : ''}
`;

export const StyledText = styled(Text)`
    margin: 0.5rem 0;
`;

export const TellMeMoreButton = styled(Button)`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: ${({ theme }) => theme.spacing.twoExtraSmall};
    }
`;
