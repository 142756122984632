/* @flow */
import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { GetEventTracker } from '../../../models/lexa';
import type { AgentPhoneRevealPageSource } from '../../../client/tracking/event/types';

type Props = {
    data: GetEventTracker,
    pageSource: AgentPhoneRevealPageSource,
};

const ViewWithTracker = (props: Props) => <View {...adaptLexaData(props.data)} {...props} />;

export default ViewWithTracker;
