// @flow
import { Link, PhoneMd, paletteHelper, spacingHelper, Text, Dialog } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { Branding } from '../../branding';
import CallLeadAttributionDisclaimer from '../call-lead-attribution-disclaimer';

export const CallAgentButton = styled(Link).attrs({
    variant: 'button-primary',
    sizeVariant: 'medium',
})`
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
`;

export const AgentName = styled(Text).attrs({
    variant: 'subtitle02',
})`
    margin: ${spacingHelper('twoExtraSmall 0 0 0')};
`;

export const AgentNumber = styled(Text).attrs({
    variant: 'caption',
})`
    margin: 0;
`;

export const AgentPhoneContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
`;

export const AgentPhoneIcon = styled(PhoneMd)`
    margin-right: ${spacingHelper('twoExtraSmall')};
`;

export const ModalHeading = styled(Text).attrs({
    noMargin: true,
    variant: 'subtitle01',
})`
    margin: ${spacingHelper('extraSmall 0 medium 0')};
`;

export const AgentDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
`;

export const CallAgentContainer = styled.div`
    display: flex;
    align-items: center;

    & + & {
        padding-top: ${spacingHelper('medium')};
        border-top: 1px solid ${paletteHelper('borderSecondary')};
        margin-top: ${spacingHelper('medium')};
    }
`;

export const AgentPhotoContainer = styled.div`
    width: 50px;
    min-width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid ${paletteHelper('borderSecondary')};
    margin-right: ${spacingHelper('medium')};
`;

export const CallLeadAttributionDisclaimerWithBorder = styled(CallLeadAttributionDisclaimer)`
    margin-top: ${({ theme }) => theme.spacing.large};
    padding-top: 20px;
    border-top: 1px solid ${({ theme }) => theme.palette.borderSecondary};
`;

export const StyledDialog = styled(Dialog)`
    width: 90vw;

    & .removeBorder {
        border: 0;
    }

    ${({ theme }) => theme.breakpoints.medium} {
        min-width: 28rem;
    }
`;

export const StyledBranding = styled(Branding)`
    max-width: 90%;
    margin-right: ${spacingHelper('small')};

    .branding__image {
        height: ${spacingHelper('extraLarge')};
    }
`;

const CallAgentModal = styled.div`
    padding: ${spacingHelper('0 medium medium')};
`;

export { CallAgentModal };
