/* @flow */
import React, { useCallback, useEffect } from 'react';
import { Button, H2, P } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { WIDTH_CONSTRAIN } from '../../../../../styles/application/layout';
import AuthToEnquire from './auth-to-enquire.svg';
import { eventTracker } from '../../../../client/tracking/event';
import { useLockeAuthentication } from '@realestate.com.au/user-react';
import { createBrowserUser } from '@realestate.com.au/user';

const PromptBackground = styled.div`
    background-color: var(--ck-backgroundPrimaryLow);
    padding-top: var(--ck-spacing-extraLarge);
`;

const PromptWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--ck-spacing-large);
    padding: var(--ck-spacing-large) var(--ck-spacing-large) var(--ck-spacing-medium);
    border: ${({ theme }) => `${theme.border.width.thin} solid var(--ck-borderSecondary)`};
    border-radius: var(--ck-radius-medium);
    background-color: var(--ck-backgroundPrimaryHigh);
    align-items: start;

    @media (min-width: ${WIDTH_CONSTRAIN}px) {
        flex-direction: row;
        padding-bottom: var(--ck-spacing-large);
    }
`;

const PromptContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const Heading = styled(H2)`
    margin-bottom: var(--ck-spacing-twoExtraSmall);
`;

const InfoText = styled(P)`
    color: var(--ck-textSecondary);
`;

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: var(--ck-spacing-medium);
    gap: var(--ck-spacing-extraSmall);
    width: 100%;

    @media (min-width: ${WIDTH_CONSTRAIN}px) {
        flex-direction: row;
    }

    & > button {
        flex: 1;
    }
`;

const PromptImage = styled.img`
    max-width: 7.5rem;
`;

type Props = {
    onJoinOrSignInSuccess: () => void,
};

const AuthToEnquirePrompt = ({ onJoinOrSignInSuccess }: Props) => {
    const { openAuthenticationForm, authenticationSuccess } = useLockeAuthentication();

    useEffect(() => {
        if (authenticationSuccess) {
            const user = createBrowserUser();
            const interaction = user.isNewUser ? 'successful_registration' : 'successful_sign_in';
            eventTracker.myREAInteraction('login_to_enquire', interaction);

            onJoinOrSignInSuccess();
        }
    }, [authenticationSuccess, onJoinOrSignInSuccess]);

    const handleAuthAction = useCallback(
        (interaction: string) => {
            eventTracker.myREAModalOpen('login_to_enquire');
            openAuthenticationForm(interaction);
        },
        [openAuthenticationForm]
    );

    return (
        <PromptBackground data-testid="auth-to-enquire-prompt">
            <PromptWrapper>
                <PromptImage src={AuthToEnquire} />
                <PromptContentWrapper>
                    <Heading>Sign in to enquire</Heading>
                    <InfoText>Sign in or join to speed up property enquiries and applications.</InfoText>
                    <ActionsWrapper>
                        <Button variant="outline" onClick={() => handleAuthAction('sign_in')}>
                            Sign in
                        </Button>
                        <Button variant="primary" onClick={() => handleAuthAction('join')}>
                            Join
                        </Button>
                    </ActionsWrapper>
                </PromptContentWrapper>
            </PromptWrapper>
        </PromptBackground>
    );
};

export default AuthToEnquirePrompt;
