/* @flow */

import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type {
    GetResidentialListing,
    GetBuyResidentialListing,
    GetRentResidentialListing,
} from '../../../../models/lexa';
import NextInspection from './next-inspection';

type Props = {|
    data: GetResidentialListing,
|};

type PropsWithInspections = {|
    data: GetBuyResidentialListing | GetRentResidentialListing,
|};

const PropertyContextWithInspection = ({ data }: PropsWithInspections) => {
    return (
        <View data={data} {...adaptLexaData(data)}>
            <NextInspection data={data} />
        </View>
    );
};

const PropertyContext = ({ data }: Props) => {
    return <View data={data} {...adaptLexaData(data)} />;
};

export { PropertyContext, PropertyContextWithInspection };
