import { compose3 as compose } from '../../../../util/functional/typescript/compose';
import getFragmentData from '../../../../util/typescript/getFragmentData';
import type {
    GetResidentialListing,
    ResidentialListing,
    ResidentialListingViewConfiguration,
} from '../../../../../types';
import ShouldRenderAgencyBrandingOnContactForm from './ShouldRenderAgencyBrandingOnContactForm.graphql';

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration;
};

const dataAdapter = (listing: RequiredProps): boolean => {
    return listing.viewConfiguration.details.agencyBrandingOnContactForm;
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, boolean>(
    dataAdapter,
    getFragmentData(ShouldRenderAgencyBrandingOnContactForm),
    (data) => data.getListing()
);
