import React, { FunctionComponent } from 'react';

import View from './View';
import adaptData from './adaptData';
import { GetListing } from '../../../../../types';

type ContactAgentFormProps = {
    data: GetListing;
    listingType: 'residential' | 'project-profile';
};

export const ContactAgentForm: FunctionComponent<ContactAgentFormProps> = ({ data, listingType }) => {
    return <View listingType={listingType} {...adaptData(listingType)(data)} />;
};
