/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import type { ListingCompany, GetResidentialListing, ResidentialListing } from '../../../../models/lexa';
import type { EnquiryTitleData } from './types';
import getFragmentData from '../../../../util/getFragmentData';
import AgencyName from './AgencyName.graphql';

type RequiredProps = {
    listingCompany: ?ListingCompany,
};

const dataAdapter = ({ listingCompany }: RequiredProps): ?EnquiryTitleData => {
    return listingCompany ? { title: `Email enquiry to ${listingCompany.name}` } : null;
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, ?EnquiryTitleData>(
    dataAdapter,
    getFragmentData(AgencyName),
    (data) => data.getListing()
);
