/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import type { Address, ListingCompany, GetProjectProfile, ProjectProfile } from '../../../../models/lexa';
import getFragmentData from '../../../../util/getFragmentData';
import type { ConfirmationModalData } from './types';
import ProjectProfileConfirmationModal from './ProjectProfileConfirmationModal.graphql';

export type Props = {
    listingCompany: ?ListingCompany,
    address: Address,
};

const dataAdapter = ({ listingCompany, address }: Props): ?ConfirmationModalData => {
    const { suburb, state, postcode, display } = address;
    const suburbInfo = `${suburb} ${state} ${postcode}`;
    return {
        agencyName: listingCompany && listingCompany.name,
        listingAddressInfo: display.shortAddress ? `${display.shortAddress} ${suburbInfo}` : suburbInfo,
    };
};

export default compose<GetProjectProfile, ProjectProfile, Props, ?ConfirmationModalData>(
    dataAdapter,
    getFragmentData(ProjectProfileConfirmationModal),
    (data) => data.getListing()
);
