// @flow
import React from 'react';
import { Modal } from '@rea-group/construct-kit-core';
import type { ViewData, AdaptedLister, ContactableAgentProps } from './types';
import type { AgentPhoneRevealPageSource } from '../../../client/tracking/event/types';
import { StyledDialog, StyledBranding } from './styles';
import {
    AgentDetailsContainer,
    AgentName,
    AgentNumber,
    AgentPhoneContainer,
    AgentPhoneIcon,
    AgentPhotoContainer,
    CallAgentButton,
    CallAgentContainer,
    CallAgentModal,
    CallLeadAttributionDisclaimerWithBorder,
    ModalHeading,
} from './styles';
import { TemplatedLazyImage } from '../../Image';
import AgentRatings from '../agent-ratings';
type ModalProps = {
    isOpen: boolean,
    close: () => void,
    data: ViewData,
    listers: Array<AdaptedLister>,
    showDisclaimer: boolean,
    showLogo: boolean,
    pageSource: AgentPhoneRevealPageSource,
};

export default function View({ isOpen, close, data, listers, showDisclaimer, showLogo, pageSource }: ModalProps) {
    return (
        <Modal opened={isOpen} onRequestClose={close} getAppElement={() => '#argonaut-wrapper'}>
            <StyledDialog
                maxWidth="35rem"
                onClose={close}
                header={showLogo && <StyledBranding data={data} />}
                dangerouslySetClassNames={{
                    header: 'removeBorder',
                }}
            >
                <CallAgentModal>
                    <ModalHeading>Get in touch</ModalHeading>
                    {listers.map((lister, index) => (
                        <ContactableAgent key={index} data={data} {...lister} />
                    ))}
                    {showDisclaimer && <CallLeadAttributionDisclaimerWithBorder data={data} pageSource={pageSource} />}
                </CallAgentModal>
            </StyledDialog>
        </Modal>
    );
}

const removeSpaces = (phoneNumber: ?string) => {
    return phoneNumber ? phoneNumber.replace(/\s/g, '') : '';
};

function ContactableAgent({
    id,
    data,
    name,
    templatedUrl,
    phoneNumber,
    callAgentButtonClickTracker,
}: ContactableAgentProps) {
    return (
        <CallAgentContainer>
            {templatedUrl && (
                <AgentPhotoContainer>
                    <TemplatedLazyImage
                        templatedUrl={templatedUrl}
                        size="150x200"
                        defaultJpeg={true}
                        avif={true}
                        alt={`Photo of ${name}`}
                    />
                </AgentPhotoContainer>
            )}

            <AgentDetailsContainer>
                <AgentName>{name}</AgentName>
                <AgentRatings listerId={id} data={data} />
                <AgentPhoneContainer>
                    <AgentPhoneIcon />
                    <AgentNumber>{phoneNumber}</AgentNumber>
                </AgentPhoneContainer>
            </AgentDetailsContainer>
            <CallAgentButton
                href={`tel:${removeSpaces(phoneNumber)}`}
                className="call-agent__call-button"
                onClick={callAgentButtonClickTracker}
            >
                Call
            </CallAgentButton>
        </CallAgentContainer>
    );
}
