// @flow
import { useEffect, useState, type Node } from 'react';
import { useDispatch } from 'react-redux';
import { isPartialDetails, type DetailsData } from './detailsData';
import appLogger from '../../util/logging/appLogger';
import { addFullDetailsData, clearFullDetailsData } from '../../components/advert/header-leader-board/actionCreator';

type Props = {
    children: (data: DetailsData) => Node,
    data: DetailsData,
    fetchDetailsFull: () => Promise<DetailsData | void>,
};

const UpdatePartialDataToFullData = ({ children, data, fetchDetailsFull }: Props) => {
    const [detailsData, setDetailsData] = useState(data);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearFullDetailsData());
        if (isPartialDetails(data)) {
            fetchDetailsFull()
                .then((fullDetailsData) => {
                    if (fullDetailsData) {
                        setDetailsData(fullDetailsData);
                        dispatch(addFullDetailsData(fullDetailsData));
                        return;
                    }
                    throw new Error('Unable to fetch full details data');
                })
                .catch((error) => {
                    appLogger.error(
                        'Unable to update partial data to full data, reloading to fallback to server-side render'
                    )(error);
                    window.location.reload();
                });
        }
    }, [fetchDetailsFull, data, dispatch]);

    return children(detailsData);
};

export default UpdatePartialDataToFullData;
