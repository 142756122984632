import React, { useReducer } from 'react';
import MediaViewerContext, { SlideName } from './MediaViewerContext';

type MediaViewerActions =
    | { type: 'OPEN_MEDIA_VIEWER'; slideToOpen: SlideName | number }
    | { type: 'CLOSE_MEDIA_VIEWER' };

type MediaViewerState = {
    isOpen: boolean;
    slideToOpen: SlideName | number;
};

const mediaViewerReducer = (state: MediaViewerState, action: MediaViewerActions): MediaViewerState => {
    switch (action.type) {
        case 'OPEN_MEDIA_VIEWER':
            return { isOpen: true, slideToOpen: action.slideToOpen };
        case 'CLOSE_MEDIA_VIEWER':
            return { ...state, isOpen: false };
    }
};

const MediaViewerProvider = ({ children }: { children: React.ReactNode }) => {
    const [{ isOpen, slideToOpen }, dispatch] = useReducer(mediaViewerReducer, { isOpen: false, slideToOpen: 'first' });

    const openMediaViewer = (slideToOpen: SlideName | number) => {
        dispatch({ type: 'OPEN_MEDIA_VIEWER', slideToOpen });
    };

    const closeMediaViewer = () => {
        dispatch({ type: 'CLOSE_MEDIA_VIEWER' });
    };

    return (
        <MediaViewerContext.Provider value={{ isOpen, slideToOpen, openMediaViewer, closeMediaViewer }}>
            {children}
        </MediaViewerContext.Provider>
    );
};

export default MediaViewerProvider;
