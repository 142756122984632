import { AgentAvatar, GetComponentEventTracker, GetComponentGetters } from '../../../types';
import compose from '../../util/functional/typescript/compose';
import { AdaptedProps, MediaOverview } from './types';

const dataAdapter = ({
    component,
    getClientTracker,
}: {
    component: MediaOverview | null;
    getClientTracker: GetComponentEventTracker['getClientTracker'];
}): AdaptedProps => {
    if (!component) return null;

    return {
        title: component.title,
        mediaOverviewGroups: component.mediaOverviewGroups,
        galleryEventTracker: (agents: AgentAvatar[]) =>
            getClientTracker('gallery')({
                schema: {
                    user_action: 'view',
                    source: {
                        page: 'rea:buy:property-details',
                        element: 'gallery',
                    },
                    media: {
                        index: 0, //hardcoded value as discussed in alignments session
                        type: 'power profile',
                        last_index: 0, //hardcoded value as discussed in alignments session
                    },
                    agents: agents.map((agent) => ({
                        agent_id: agent.agentId || '',
                        agent_profile_id: agent.agentProfileId,
                    })),
                },
                includeMetaData: false,
            }),
    };
};

const adaptLexaData = compose(dataAdapter, (data: GetComponentGetters) => ({
    component: data.getComponent('MediaOverviewComponent'),
    getClientTracker: data.getClientTracker,
}));

export default adaptLexaData;
