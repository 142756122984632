import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import { Props } from './types';

const ProductComponent = ({ data }: Props) => {
    const { badge } = adaptLexaData(data);

    if (!badge) {
        return null;
    }
    return <View badge={badge} />;
};

export default ProductComponent;
