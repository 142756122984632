/* @flow */
import type { ResidentialListing, ProjectProfile } from '../../../models/lexa';

export const getOpenGraphImage = (listing: ResidentialListing | ProjectProfile) => ({
    property: 'og:image',
    content: listing.media.mainImage.templatedUrl.replace('{size}', '800x600'),
    'data-argonaut-link-preview': 'true',
});

export const openGraphImageWidth = {
    property: 'og:image:width',
    content: '800',
};

export const openGraphImageHeight = {
    property: 'og:image:height',
    content: '600',
};
