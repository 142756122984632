/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type { AdaptedDataProps } from './mapTypes';
import type {
    BuyResidentialListingDetailsGetters,
    BuyResidentialListing,
    PersonalisedInspection,
} from '../../../../models/lexa';
import ChildListingMapAddress from './ChildListingMapAddress.graphql';
import { DEFAULT_DISPLAY_ADDRESS } from '../../../../models/Address';
import type { MapMarkers } from './mapTypes';
import { getDisplayLocationMarker, getPropertyMarker } from './mapHelper';

type FragmentDataType = {
    inspections: Array<PersonalisedInspection>,
    parentDisplaySuiteInspections: ?Array<PersonalisedInspection>,
    listing: BuyResidentialListing,
};

const dataAdapter = (data: FragmentDataType): ?AdaptedDataProps => {
    const {
        listing: {
            parent,
            viewConfiguration,
            address: {
                display: { geocode },
            },
        },
        inspections,
        parentDisplaySuiteInspections,
    } = data;
    if (parent) {
        const {
            name: projectName,
            displayLocation,
            address: {
                display: { fullAddress },
            },
        } = parent;
        const markers: MapMarkers = [getDisplayLocationMarker(parent), getPropertyMarker(geocode)].filter(Boolean);
        if (markers.length === 0) return null;
        const isTabbedInspections = !!(inspections.length > 0 && parentDisplaySuiteInspections);
        const displayLocationTitle = displayLocation?.title;
        return {
            markers,
            mapModalTitle:
                isTabbedInspections && displayLocationTitle
                    ? `${projectName} inspection & ${displayLocationTitle} times`
                    : `${projectName} locations`,
            ariaLabel: fullAddress ? `Map of ${fullAddress}` : DEFAULT_DISPLAY_ADDRESS,
            showMapSkyscraper: !!viewConfiguration.details.adverts.mapSkyscraper,
        };
    }
    return null;
};

export default compose<BuyResidentialListingDetailsGetters, FragmentDataType, FragmentDataType, ?AdaptedDataProps>(
    dataAdapter,
    getFragmentData(ChildListingMapAddress),
    (data) => ({
        listing: data.getListing(),
        inspections: data.getInspections(),
        parentDisplaySuiteInspections: data.getParentDisplaySuiteInspections(),
    })
);
