/* @flow */
import preloadFetchedMapUrl from './preloadFetchedMapUrl';
import fetchMapUrl from './fetchMapUrl';
import { compose } from '../../../../util/functional/compose';
import type { FetchMapUrl } from '../dependencies';
import type { MapProps } from './fetchMapUrl';

const createFetchMapUrl = compose<MapProps, FetchMapUrl, FetchMapUrl>(preloadFetchedMapUrl, fetchMapUrl);

export default createFetchMapUrl;
