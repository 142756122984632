import React from 'react';
import { Button, ButtonVariant } from '@rea-group/construct-kit-core';
import type { ViewProps } from './types';
import ScrollToElement from '../ScrollToElement';

export default function ScrollableGetInTouchButton({
    className = '',
    label = 'Get in touch',
    variant,
    contactAgentEl,
    onClick,
    viewEmailAgentTracker,
}: ViewProps) {
    const onButtonWithScrollClick = () => {
        if (onClick) {
            onClick();
        }
        viewEmailAgentTracker();
    };

    return (
        <div className={`get-in-touch ${className}`}>
            <ScrollToElement elementRef={contactAgentEl} afterScroll={onButtonWithScrollClick}>
                {(scrollOnClick) => <GetInTouchButton label={label} variant={variant} onClick={scrollOnClick} />}
            </ScrollToElement>
        </div>
    );
}

const GetInTouchButton = ({
    onClick,
    label,
    variant,
}: {
    onClick: React.MouseEventHandler;
    label: string;
    variant: ButtonVariant;
}) => (
    <Button variant={variant} fullWidth={true} onClick={onClick}>
        {label}
    </Button>
);
