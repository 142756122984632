/* @flow  */

import getFragmentData from '../../../../../util/getFragmentData';
import { compose3 as compose } from '../../../../../util/functional/compose';
import type {
    Inspection,
    BuyResidentialListing,
    RentResidentialListing,
    GetBuyResidentialListing,
    GetRentResidentialListing,
} from '../../../../../models/lexa';
import type { Channel as ChannelType } from '../../../../../models/Channel';
import BuyNextInspection from './BuyNextInspection.graphql';
import RentNextInspection from './RentNextInspection.graphql';

type BuyOrRentResidentialListing = BuyResidentialListing | RentResidentialListing;

type GetBuyOrRentResidentialListing = GetBuyResidentialListing | GetRentResidentialListing;

type RequiredProps = {
    inspections: Array<Inspection>,
};

type NextInspectionData = {
    nextInspection: string,
};

const dataAdapter = ({ inspections }: RequiredProps): ?NextInspectionData => {
    if (inspections.length > 0) {
        const nextInspection = inspections[0].display;
        return {
            nextInspection: `${nextInspection.shortDate}, ${nextInspection.time}`,
        };
    }
    return null;
};

const adaptLexaData = (channel: ChannelType) =>
    compose<GetBuyOrRentResidentialListing, BuyOrRentResidentialListing, RequiredProps, ?NextInspectionData>(
        dataAdapter,
        getFragmentData(channel === 'buy' ? BuyNextInspection : RentNextInspection),
        (data) => data.getListing()
    );

export default adaptLexaData;
