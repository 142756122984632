
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ResidentialSlides"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ResidentialListing"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PowerProfileSlide"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AgencyBrandingSlide"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaViewerEventTracking"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ThreeDimensionalTourSlide"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"VideoSlide"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"PhotoOverlayWithGallerySlide"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"media"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"images"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"templatedUrl"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"floorplans"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"templatedUrl"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":719}};
    doc.loc.source = {"body":"#import \"../../slide-gallery/slides/power-profile/PowerProfileSlide.graphql\"\n#import \"../../slide-gallery/slides/agency-branding/AgencyBrandingSlide.graphql\"\n#import \"../MediaViewerEventTracking.graphql\"\n#import \"./3d-tour/ThreeDimensionalTourSlide.graphql\"\n#import \"./video/VideoSlide.graphql\"\n#import \"./photoOverlayWithGallery/PhotoOverlayWithGallerySlide.graphql\"\n\nfragment ResidentialSlides on ResidentialListing {\n    ...PowerProfileSlide\n    ...AgencyBrandingSlide\n    ...MediaViewerEventTracking\n    ...ThreeDimensionalTourSlide\n    ...VideoSlide\n    ...PhotoOverlayWithGallerySlide\n    media {\n        images {\n            templatedUrl\n        }\n        floorplans {\n            templatedUrl\n        }\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../../slide-gallery/slides/power-profile/PowerProfileSlide.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../../slide-gallery/slides/agency-branding/AgencyBrandingSlide.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../MediaViewerEventTracking.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./3d-tour/ThreeDimensionalTourSlide.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./video/VideoSlide.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./photoOverlayWithGallery/PhotoOverlayWithGallerySlide.graphql").definitions));


    // Collect any fragment/type references from a node, adding them to the refs Set
    function collectFragmentReferences(node, refs) {
      if (node.kind === "FragmentSpread") {
        refs.add(node.name.value);
      } else if (node.kind === "VariableDefinition") {
        var type = node.type;
        if (type.kind === "NamedType") {
          refs.add(type.name.value);
        }
      }

      if (node.selectionSet) {
        node.selectionSet.selections.forEach(function(selection) {
          collectFragmentReferences(selection, refs);
        });
      }

      if (node.variableDefinitions) {
        node.variableDefinitions.forEach(function(def) {
          collectFragmentReferences(def, refs);
        });
      }

      if (node.definitions) {
        node.definitions.forEach(function(def) {
          collectFragmentReferences(def, refs);
        });
      }
    }

    var definitionRefs = {};
    (function extractReferences() {
      doc.definitions.forEach(function(def) {
        if (def.name) {
          var refs = new Set();
          collectFragmentReferences(def, refs);
          definitionRefs[def.name.value] = refs;
        }
      });
    })();

    function findOperation(doc, name) {
      for (var i = 0; i < doc.definitions.length; i++) {
        var element = doc.definitions[i];
        if (element.name && element.name.value == name) {
          return element;
        }
      }
    }

    function oneQuery(doc, operationName) {
      // Copy the DocumentNode, but clear out the definitions
      var newDoc = {
        kind: doc.kind,
        definitions: [findOperation(doc, operationName)]
      };
      if (doc.hasOwnProperty("loc")) {
        newDoc.loc = doc.loc;
      }

      // Now, for the operation we're running, find any fragments referenced by
      // it or the fragments it references
      var opRefs = definitionRefs[operationName] || new Set();
      var allRefs = new Set();
      var newRefs = new Set();

      // IE 11 doesn't support "new Set(iterable)", so we add the members of opRefs to newRefs one by one
      opRefs.forEach(function(refName) {
        newRefs.add(refName);
      });

      while (newRefs.size > 0) {
        var prevRefs = newRefs;
        newRefs = new Set();

        prevRefs.forEach(function(refName) {
          if (!allRefs.has(refName)) {
            allRefs.add(refName);
            var childRefs = definitionRefs[refName] || new Set();
            childRefs.forEach(function(childRef) {
              newRefs.add(childRef);
            });
          }
        });
      }

      allRefs.forEach(function(refName) {
        var op = findOperation(doc, refName);
        if (op) {
          newDoc.definitions.push(op);
        }
      });

      return newDoc;
    }
    
    module.exports = doc;
    
        module.exports["ResidentialSlides"] = oneQuery(doc, "ResidentialSlides");
        
