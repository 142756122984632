/* @flow */
import React, { useMemo } from 'react';
import { render } from 'react-dom';
import type { TrackingData } from '../../../../models/lexa';
import { adSlideAddon } from 'media-viewer/lib/addons/ad-slide';
import { renderAd } from '@media/ad-kit';
import { PhotoGalleryAd } from '../../../advert';
import { startPrebidWhenIframeLoaded } from '@media/ad-kit';
import { APP_NAME } from '../../../advert/adParams';
import { currentDefinition } from '../../../../client/device-capability';
import { createPhotogalleryConfig } from '@media/advert-configs-plw';

const getScriptSources = (adProperties) => {
    return adProperties.embeddedscripts ? adProperties.embeddedscripts.map((script) => script.url) : [];
};

const layout = currentDefinition();

export default (trackingData: TrackingData): Object => {
    const prebidConfig = useMemo(() => {
        if (!layout) {
            return null;
        }
        return createPhotogalleryConfig(layout);
    }, []);

    const createAdvertisement = ({ adTrackingData }) => {
        return new Promise((resolve, reject) => {
            const container = document.createElement('div');
            const onLoad = ({ adProperties, isAdLoaded }) => {
                if (!isAdLoaded || adProperties == null) {
                    reject(new Error('Failed to load ad'));
                    return;
                }

                // For HTML ad `adProperties`/`sponsorData` is an actual SAS response containing all ad HTML in a string
                if (typeof adProperties === 'string') {
                    const renderMedRecAd = (medRecAdContainerElement) =>
                        renderAd(
                            {
                                adType: 'html',
                                content: adProperties,
                                sz: '300x250',
                            },
                            medRecAdContainerElement
                        );

                    resolve({
                        type: 'medRecAd',
                        renderMedRecAd,
                    });

                    return;
                }

                // Prebid
                if (adProperties.adtype === 'prebid') {
                    const renderMedRecAd = (medRecAdContainerElement) =>
                        renderAd({ adType: 'prebid', content: adProperties }, medRecAdContainerElement);

                    resolve({
                        type: 'medRecAd',
                        renderMedRecAd,
                    });

                    return;
                }

                if (adProperties.adtype === 'photogallery') {
                    const staticAdContainer = document.createElement('div');
                    renderAd({ adType: 'native', content: adProperties }, staticAdContainer)
                        // eslint-disable-next-line promise/always-return
                        .then(() => {
                            const adInformation = {
                                type: 'nativeAd',
                                html: staticAdContainer.innerHTML,
                                scriptSources: getScriptSources(adProperties),
                            };

                            resolve(adInformation);
                        })
                        .catch((err) => {
                            reject(err);
                        });

                    return;
                }

                const adInformation = {
                    type: 'videoAd',
                    videoAdElementId: 'advert-photogallery-video',
                    adProperties,
                };

                resolve(adInformation);
            };

            startPrebidWhenIframeLoaded(prebidConfig, APP_NAME, trackingData);

            render(<PhotoGalleryAd onLoad={onLoad} trackingData={adTrackingData} />, container);
        });
    };

    const adSlideData = {
        loadAdvertisement: () =>
            createAdvertisement({
                adTrackingData: trackingData,
            }),
        createVideoSafeframe: (externalMediaContainer) => {
            const adElement = document.createElement('aside');
            adElement.id = 'advert-photogallery-video';
            externalMediaContainer.appendChild(adElement);

            return renderAd({ adType: 'native', content: { adtype: 'photogallery-video' } }, adElement);
        },
    };

    return {
        type: 'adSlideAddon',
        ...adSlideAddon(adSlideData),
    };
};
