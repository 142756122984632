import React from 'react';
import styled from 'styled-components';
import { BadgeWithStatus, spacingHelper } from '@rea-group/construct-kit-core';
import { Badge } from './types';

type Props = {
    badge: Badge;
};

const ResponsiveBadge = styled(BadgeWithStatus)`
    ${({ theme }) => theme.breakpoints.large} {
        .developer-badge-dot {
            width: ${spacingHelper('small')};
            height: ${spacingHelper('small')};
        }
        ${({ theme }) => theme.typography.subtitle01}
    }
`;

export default function View({ badge }: Props) {
    return (
        <ResponsiveBadge
            dotColor="textBrand"
            naked={true}
            sizeVariant={'medium'}
            dangerouslySetClassNames={{
                dot: 'developer-badge-dot',
                content: '',
            }}
        >
            {badge.title}
            {!!badge.description && ' · ' + badge.description}
        </ResponsiveBadge>
    );
}
