/* @flow */
import React from 'react';
import type { EnquiryTitleData } from './types';
import styled from 'styled-components';
import { H2, spacingHelper } from '@rea-group/construct-kit-core';

const EnquiryTitle = styled(H2).attrs({ variant: 'title03' })`
    margin-bottom: ${spacingHelper('small')};
    ${({ theme }) => theme.breakpoints.medium} {
        margin-bottom: ${spacingHelper('medium')};
    }
`;

const View = ({ title }: EnquiryTitleData) => <EnquiryTitle>{title}</EnquiryTitle>;
export default View;
