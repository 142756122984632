/* @flow */
import React from 'react';

type Props = {
    label: string,
};

export default (props: Props) => (
    <div className="map-placeholder">
        <div className="img-label map-placeholder__txt">{props.label}</div>
    </div>
);
