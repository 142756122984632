/* @flow */
import React from 'react';
import styled from 'styled-components';
import { Link, spacingHelper, Stack } from '@rea-group/construct-kit-core';
import { setClickThroughSourceToLocalStorage } from '@realestate.com.au/iglu-data';

type Props = {
    channel: string,
};

const LinkWrapper = styled(Link)`
    margin-top: ${spacingHelper('medium')};
`;

const DriveToEnquiredCollection = ({ channel }: Props) => {
    const enquiredCollectionPath = '/collections/enquired';

    const onLinkClick = () => {
        setClickThroughSourceToLocalStorage({
            clickThroughSourceElement: 'view-all-sent-enquiries',
            clickThroughSourcePage: `rea:${channel}:pdp`,
            clickThroughTargetPathname: enquiredCollectionPath,
        });
    };

    return (
        <Stack gap="medium">
            <LinkWrapper href={enquiredCollectionPath} onClick={onLinkClick} variant="button-primary" fullWidth={true}>
                View Enquired properties
            </LinkWrapper>
        </Stack>
    );
};

export default DriveToEnquiredCollection;
