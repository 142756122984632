/* @flow */

const FACEBOOK_DATA = [
    {
        property: 'fb:app_id',
        content: '190577597636538',
    },
    {
        property: 'fb:admins',
        content: '100002001951234',
    },
];

const TWITTER_DATA = [
    {
        name: 'twitter:card',
        content: 'summary_large_image',
    },
    {
        name: 'twitter:site',
        content: '@realestate_au',
    },
];

export const socialMediaData = [...FACEBOOK_DATA, ...TWITTER_DATA];
