/* @flow */
// flow:disable no typedefs for graphql
import { print } from 'graphql';
import getMapUrl from './getMapUrl.graphql';
import { getLexaHostClient } from '../../../../config';
import { right, left } from '../../../../util/functional/Either';
import type { Either } from '../../../../util/functional/Either';
import type { Option } from '../../../../util/functional/Option';
import { some, none } from '../../../../util/functional/Option';
import type { QueryResult } from '../../../../util/graphqlTypes';
import type { DetailsResponse } from '../../../../models/lexa';
import type { MapUrlParameters, FetchMapUrlOutput } from '../dependencies';

export type MapProps = {
    id: string,
};

const getGoogleMapsUrl = (data: DetailsResponse): ?string => {
    if (data && data.details.listing) {
        const { listing } = data.details;

        return listing.staticMap && listing.staticMap.url;
    }
};

const hasGraphQLErrors = (queryResult) => queryResult.errors && queryResult.errors.length > 0;

const checkErrors = (queryResult: QueryResult<DetailsResponse>): Either<Error, Option<string>> => {
    const url = getGoogleMapsUrl(queryResult.data);
    if (url) {
        return right(some(url));
    }

    if (hasGraphQLErrors(queryResult)) {
        const message = JSON.stringify({
            message: 'LEXA GraphQL error',
            errors: queryResult.errors,
        });

        return left(new Error(message));
    }

    return right(none);
};

export default ({ id }: MapProps) =>
    ({ width, height, highDPI }: MapUrlParameters): FetchMapUrlOutput => {
        return fetch(`${getLexaHostClient()}/graphql`, {
            body: JSON.stringify({
                query: print(getMapUrl),
                variables: { id, width, height, highDPI },
            }),
            method: 'POST',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`LEXA error: ${response.statusText}`);
                }
                return response.json();
            })
            .then(checkErrors)
            .catch(left);
    };
