import { compose3 as compose } from '../../../../util/functional/typescript/compose';
import { ProjectProfileLinks, ProjectProfile, GetProjectProfile } from '../../../../../types';
import getFragmentData from '../../../../util/typescript/getFragmentData';
import { AdaptedData } from './adaptData';
import ProjectProfileContactAgentFormV2 from './ProjectProfileContactAgentFormV2.graphql';

type ProjectProfileExtractedData = {
    _links: ProjectProfileLinks;
    title: string;
    features: string[];
};

const projectProfileDataAdapter = (data: ProjectProfileExtractedData): AdaptedData => {
    return {
        submitUrl: data._links.submitEnquiry.href,
        projectTitle: data.title,
        projectFeatures: data.features,
    };
};

const projectProfileAdaptData = compose<GetProjectProfile, ProjectProfile, ProjectProfileExtractedData, AdaptedData>(
    projectProfileDataAdapter,
    getFragmentData(ProjectProfileContactAgentFormV2),
    (data: GetProjectProfile) => data.getListing()
);

export default projectProfileAdaptData;
