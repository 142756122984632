// @flow
import React from 'react';
import View from './View';
import adaptResidentialListingData from './adaptResidentialListingData';
import adaptProjectProfileData from './adaptProjectProfileData';
import type { ResidentialModalProps, ProjectProfileModalProps } from './types';

export const ResidentialCallAgentModal = ({ data, close, isOpen }: ResidentialModalProps) => {
    const adaptedData = adaptResidentialListingData(data);

    return <View data={data} close={close} isOpen={isOpen} {...adaptedData} />;
};

export const ProjectProfileCallAgentModal = ({ data, close, isOpen }: ProjectProfileModalProps) => {
    const adaptedData = adaptProjectProfileData(data);

    return <View data={data} close={close} isOpen={isOpen} {...adaptedData} />;
};
