import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { Props } from './types';

const ProductComponent = ({ data, contactAgentEl, renderCallAgentModal, renderApplyOnlineButton }: Props) => (
    <View
        {...adaptLexaData(data)}
        contactAgentEl={contactAgentEl}
        renderCallAgentModal={renderCallAgentModal}
        renderApplyOnlineButton={renderApplyOnlineButton}
    />
);

export default ProductComponent;
