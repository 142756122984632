import styled from 'styled-components';
import { spacingHelper, paletteHelper, Card } from '@rea-group/construct-kit-core';

import { WIDTH, WIDTH_CONSTRAIN, WIDTH_SIDEBAR } from '../../../../styles/application/layout';

export const ContactAgentSection = styled.div`
    background: ${paletteHelper('backgroundSecondaryBase')};

    ${({ theme }) => theme.breakpoints.medium} {
        padding: ${spacingHelper('large')} 0;
    }
`;

export const Layout = styled.div`
    display: flex;
    max-width: ${WIDTH}px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.large} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const LayoutContent = styled.div`
    max-width: ${WIDTH_CONSTRAIN}px;
    width: 100%;
    align-self: center;

    ${({ theme }) => theme.breakpoints.large} {
        align-self: auto;
        width: ${WIDTH_CONSTRAIN}px;
    }
`;

export const LayoutSidebar = styled.div`
    display: none;

    ${({ theme }) => theme.breakpoints.large} {
        display: flex;
        flex-direction: column;
        width: ${WIDTH_SIDEBAR}px;
        align-self: auto;
    }
`;

export const ResponsiveCard = styled(Card)`
    border-radius: 0;
    overflow: hidden;
    @media (min-width: ${WIDTH_CONSTRAIN + 1}px) {
        border-radius: ${({ theme }) => theme.border.radius.large};
    }
`;
