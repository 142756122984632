/* @flow */
import React, { useContext } from 'react';
import ChannelContext from '../../../../util/ChannelContext';
import type { ConfirmationModalData } from './types';
import type {
    GetResidentialListing,
    GetProjectProfile,
    GetTrackingData,
    GetEventTracker,
} from '../../../../models/lexa';
import styled from 'styled-components';
import { SuccessLg, paletteHelper, spacingHelper, Modal, Dialog, Stack } from '@rea-group/construct-kit-core';
import SectionHeading from '../../section-heading';
import DriveToRegistration from './DriveToRegistration';
import { FinxEnquiryForm } from '../../../finx-enquiry-form';
import DriveToEnquiredCollection from './DriveToEnquiredCollection';

const MODAL_HEADING = 'Thank you for your enquiry';
type Props = ConfirmationModalData & {
    consumerName: string,
    onClose: () => void,
    data: (GetResidentialListing | GetProjectProfile) & GetTrackingData & GetEventTracker,
    isUserSignedIn: boolean,
    consumerEmail: string,
    consumerPhone: string,
    preApproval: string,
    onJoinOrSignInSuccess: () => void,
};

const IconWrapper = styled(SuccessLg)`
    color: ${paletteHelper('textSuccess')};
    width: 4rem;
    height: 4rem;
`;

const ContentWrapper = styled.div`
    color: ${paletteHelper('textSecondary')};
`;

const WarningWrapper = styled.p`
    font-size: ${({ theme }) => theme.typography.body02.fontSize};
    line-height: ${({ theme }) => theme.typography.body02.lineHeight};
    margin-top: ${spacingHelper('medium')};
`;

const StackWrapper = styled(Stack)`
    padding: ${spacingHelper('medium')};

    ${({ theme }) => theme.breakpoints.large} {
        padding: ${spacingHelper('extraLarge')};
    }
`;

const View = ({
    listingAddressInfo,
    agencyName,
    consumerName,
    consumerEmail,
    consumerPhone,
    preApproval,
    onClose,
    data,
    isUserSignedIn,
    onJoinOrSignInSuccess,
}: Props) => {
    const channel = useContext(ChannelContext);
    const isChannelBuy = channel === 'buy';

    const confirmationModel = () => {
        if (isUserSignedIn) {
            if (!isChannelBuy || preApproval === 'yes') {
                return <DriveToEnquiredCollection channel={channel} />;
            }

            return <FinxEnquiryForm name={consumerName} email={consumerEmail} phoneNumber={consumerPhone} />;
        }
        if (isChannelBuy && preApproval === 'no') {
            return <FinxEnquiryForm name={consumerName} email={consumerEmail} phoneNumber={consumerPhone} />;
        }
        return <DriveToRegistration onJoinOrSignInSuccess={onJoinOrSignInSuccess} data={data} />;
    };

    return (
        <Modal opened={true} onRequestClose={onClose} getAppElement={() => document.getElementById('argonaut-wrapper')}>
            <Dialog noMargins={true} maxWidth={'750px'} onClose={onClose} autoFullScreen={false}>
                <StackWrapper gap="medium">
                    <IconWrapper />
                    <SectionHeading>{`${MODAL_HEADING}, ${consumerName}!`}</SectionHeading>
                    <ContentWrapper>
                        <p>
                            <span>Your enquiry for {listingAddressInfo} has been sent</span>
                            {agencyName && (
                                <span>
                                    {' '}
                                    to <strong>{agencyName}</strong>
                                </span>
                            )}
                            <span>.</span>
                        </p>
                        <WarningWrapper>
                            If you don’t hear back within 48 hours, please contact them directly.
                        </WarningWrapper>
                        {confirmationModel()}
                    </ContentWrapper>
                </StackWrapper>
            </Dialog>
        </Modal>
    );
};

export default View;
