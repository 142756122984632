/* @flow */
export type MapMarker = {
    coordinates: {
        lat: number | string,
        lng: number | string,
    },
    icon: {
        url: string,
        width: number,
        height: number,
    },
};

export type MapMarkers = Array<MapMarker>;

export type MapViewTypes = 'MAP' | 'SATELLITE' | 'STREET_VIEW';

export const MAP = 'MAP';
export const SATELLITE = 'SATELLITE';
export const STREET_VIEW = 'STREET_VIEW';

export type AdaptedDataProps = {
    ariaLabel: string,
    mapModalTitle: string,
    markers: MapMarkers,
    showMapSkyscraper: boolean,
};
