/* @flow */
import React, { Component } from 'react';
import phoneIcon from './phone-icon.svg';
import './styles.scss';
import sanitizedTel from './sanitizedTel';
type Props = {
    number: string,
    revealNumberTracker: () => void,
};

type RevealablePhoneNumberProps = {
    number: string,
    className: string,
    numberRevealed: boolean,
    onExpand: (SyntheticEvent<>) => void,
};

type State = {|
    numberRevealed: boolean,
|};

const RevealablePhoneNumber = ({ number, className, numberRevealed, onExpand }: RevealablePhoneNumberProps) => {
    const slicedNumber = numberRevealed ? number.slice(0, 16) : number.slice(0, 8);
    const displayNumber = slicedNumber.length === number.length ? slicedNumber : `${slicedNumber}...`;
    if (numberRevealed) {
        return (
            <a href={`tel:${sanitizedTel(number)}`} className={className}>
                <span className={`${className}-text`}>{displayNumber}</span>
            </a>
        );
    }

    return (
        <a onClick={onExpand} className={className} title="Click to reveal phone number">
            <span className={`${className}-text`}>{displayNumber}</span>
        </a>
    );
};

export default class PhoneNumber extends Component<Props, State> {
    state = { numberRevealed: false };

    expand = (e: SyntheticEvent<>) => {
        e.preventDefault();
        this.props.revealNumberTracker();
        this.setState({ numberRevealed: true });
    };

    render() {
        const { number } = this.props;
        const { numberRevealed } = this.state;
        return (
            <div className="phone">
                <a href={`tel:${sanitizedTel(number)}`} onClick={this.props.revealNumberTracker}>
                    <img className="phone__icon" src={phoneIcon} />
                    <span className="phone__call">Call</span>
                </a>
                <RevealablePhoneNumber
                    number={number}
                    className="phone__number"
                    numberRevealed={numberRevealed}
                    onExpand={this.expand}
                />
            </div>
        );
    }
}
