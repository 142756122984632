/* @flow */

import React from 'react';
import { H2, Button, Link, Modal, Dialog, typographyHelper, spacingHelper, P } from '@rea-group/construct-kit-core';
import { useBoolean } from '../../../hooks';
import styled from 'styled-components';
import type { Props } from './type';

const PrivacyP = styled(P)`
    padding: ${spacingHelper('medium')};
`;

const PrivacyButton = styled(Button)`
    ${typographyHelper('body02')};
`;

const CallLeadAttributionModal = ({ isOpen, onClose }) => (
    <Modal opened={isOpen} onRequestClose={onClose} getAppElement={() => '#argonaut-wrapper'}>
        <Dialog maxWidth="35rem" onClose={onClose} header={<H2 vairant="title03">Privacy Statement</H2>}>
            <PrivacyP>
                When you contact the number in this listing, your number, enquiry, call duration and time may be
                collected on behalf of realestate.com.au and provided to the Agency to respond to your enquiry. For
                further details, see our{' '}
                <Link
                    variant="inline"
                    href="https://about.realestate.com.au/privacy/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Privacy policy.
                </Link>{' '}
            </PrivacyP>
        </Dialog>
    </Modal>
);

const CallLeadAttributionDisclaimerInner = styled.div`
    margin-top: ${({ theme }) => theme.spacing.medium};
`;

export function CallLeadAttributionDisclaimer(props: Props) {
    const [isOpen, open, close] = useBoolean(false);
    const { disclaimerClickTracker, pageSource, ...restProps } = props;

    const openModal = () => {
        disclaimerClickTracker(pageSource);
        open();
    };

    return (
        <CallLeadAttributionDisclaimerInner {...restProps}>
            <PrivacyButton variant="link-secondary" type="button" onClick={openModal}>
                Privacy Statement
            </PrivacyButton>
            {isOpen && <CallLeadAttributionModal isOpen={isOpen} onClose={close} />}
        </CallLeadAttributionDisclaimerInner>
    );
}

export default CallLeadAttributionDisclaimer;
