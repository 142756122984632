/* @flow */

import type { PhotoItem, FloorplanItem, PhotoOverlayItem } from './View';

type PhotoData = {
    templatedUrl: string,
};

type FloorplanData = {
    templatedUrl: string,
};

type PhotoOverlayData = {
    templatedUrl: string,
    heading: string,
    subHeading: string,
    href: string,
    title: string,
};

export const toPhotoItem = (photoData: PhotoData): PhotoItem => {
    return {
        mediaType: 'photo',
        src: {
            url: photoData.templatedUrl,
        },
    };
};

export const toFloorplanItem = (floorplanData: FloorplanData): FloorplanItem => {
    return {
        mediaType: 'floorplan',
        src: {
            url: floorplanData.templatedUrl,
        },
    };
};

export const toPhotoOverlayItem = (photoOverlayData: PhotoOverlayData): PhotoOverlayItem => {
    return {
        mediaType: 'photo overlay',
        src: {
            url: photoOverlayData.templatedUrl,
        },
        heading: photoOverlayData.heading,
        subHeading: photoOverlayData.subHeading,
        link: {
            href: photoOverlayData.href,
            title: photoOverlayData.title,
        },
    };
};
