/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import type {
    Address,
    ListingCompany,
    ListingLinks,
    GetResidentialListing,
    ResidentialListing,
    ResidentialListingViewConfiguration,
} from '../../../../models/lexa';
import getFragmentData from '../../../../util/getFragmentData';
import type { ConfirmationModalData } from './types';
import ResidentialListingConfirmationModal from './ResidentialListingConfirmationModal.graphql';

export type Props = {
    _links: ListingLinks,
    address: Address,
    listingCompany: ?ListingCompany,
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = ({ listingCompany, address, _links }: Props): ?ConfirmationModalData => {
    const { suburb, state, postcode, display } = address;
    const suburbInfo = `${suburb} ${state} ${postcode}`;
    return {
        agencyName: listingCompany && listingCompany.name,
        listingUrl: _links.canonical.href,
        listingAddressInfo: display.shortAddress ? `${display.shortAddress} ${suburbInfo}` : suburbInfo,
    };
};

export default compose<GetResidentialListing, ResidentialListing, Props, ?ConfirmationModalData>(
    dataAdapter,
    getFragmentData(ResidentialListingConfirmationModal),
    (data) => data.getListing()
);
