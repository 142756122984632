/* @flow */

import React from 'react';
import { DynamicMap, DynamicProjectMap, DynamicChildListingMap } from './dynamic';
import { useBoolean } from '../../../hooks';
import StaticMap from './static';
import type {
    GetResidentialListing,
    BuyResidentialListingDetailsGetters,
    GetProjectProfile,
    GetEventTracker,
    GetTrackingData,
} from '../../../models/lexa';
import './styles.scss';

type ResidentialMapProps = {
    data: GetResidentialListing & GetEventTracker & GetTrackingData,
};

type ResidentialChildMapProps = {
    data: BuyResidentialListingDetailsGetters & GetEventTracker & GetTrackingData,
};

type ProjectMapProps = {
    data: GetProjectProfile & GetEventTracker & GetTrackingData,
};

export const ResidentialMap = ({ data }: ResidentialMapProps) => {
    const [isOpen, open, close] = useBoolean(false);
    return (
        <div className="map">
            <StaticMap data={data} onClick={open} />
            {isOpen && <DynamicMap data={data} onClose={close} />}
        </div>
    );
};

export const ProjectMap = ({ data }: ProjectMapProps) => {
    const [isOpen, open, close] = useBoolean(false);
    return (
        <div className="project-map">
            <StaticMap data={data} onClick={open} />
            {isOpen && <DynamicProjectMap data={data} onClose={close} />}
        </div>
    );
};

export const ChildListingMap = ({ data }: ResidentialChildMapProps) => {
    const [isOpen, open, close] = useBoolean(false);
    return (
        <div className="project-map">
            <StaticMap data={data} onClick={open} />
            {isOpen && <DynamicChildListingMap data={data} onClose={close} />}
        </div>
    );
};
