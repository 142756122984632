import React from 'react';

export type SlideName = 'first' | 'second' | 'third' | 'fourth' | 'last' | 'floorplan' | 'virtualtour' | 'video';

type MediaViewerContext = {
    isOpen: boolean;
    slideToOpen: SlideName | number;
    openMediaViewer: (slideToOpen: SlideName | number) => void;
    closeMediaViewer: () => void;
};

const MediaViewerContext = React.createContext<MediaViewerContext>({
    isOpen: false,
    slideToOpen: 'first',
    openMediaViewer: () => {},
    closeMediaViewer: () => {},
});

export default MediaViewerContext;
