/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type { GetProjectProfile, ProjectProfile } from '../../../../models/lexa';
import { DEFAULT_DISPLAY_ADDRESS } from '../../../../models/Address';
import projectMarkerIcon from '../images/map-pin-project.png';
import type { AdaptedDataProps } from './mapTypes';
import ProjectMapAddress from './ProjectMapAddress.graphql';
import { createMarker, getDisplayLocationMarker, TEXT_PIN_HEIGHT } from './mapHelper';

export const projectProfileDataAdapter = (listing: ProjectProfile): ?AdaptedDataProps => {
    const PROJECT_PIN_WIDTH = 63;
    const { name: projectName, address } = listing;
    const { geocode, fullAddress } = address.display;
    if (!geocode) return null;

    const projectMarker = createMarker({
        geocode,
        markerIcon: projectMarkerIcon,
        width: PROJECT_PIN_WIDTH,
        height: TEXT_PIN_HEIGHT,
    });
    const displayLocationMarker = getDisplayLocationMarker(listing);

    return {
        markers: displayLocationMarker ? [displayLocationMarker, projectMarker] : [projectMarker],
        mapModalTitle: `${projectName} locations`,
        ariaLabel: fullAddress ? `Map of ${fullAddress}` : DEFAULT_DISPLAY_ADDRESS,
        showMapSkyscraper: false,
    };
};

export default compose<GetProjectProfile, ProjectProfile, ProjectProfile, ?AdaptedDataProps>(
    projectProfileDataAdapter,
    getFragmentData(ProjectMapAddress),
    (data) => data.getListing()
);
