/* @flow */
import React, { useContext, useState } from 'react';
import type { Element as ReactElement } from 'react';
import ChannelContext from '../../../../util/ChannelContext';
import ConsumerRequestsContext from './ConsumerRequestsContext';
import { BUY_INSPECTION_VALUE, RENT_INSPECTION_VALUE } from './enquiry-request';

export const useCheckInspection = () => {
    const channel = useContext(ChannelContext);
    const { addConsumerRequest } = useContext(ConsumerRequestsContext);
    return () => {
        if (channel === 'buy') {
            addConsumerRequest(BUY_INSPECTION_VALUE);
        } else if (channel === 'rent') {
            addConsumerRequest(RENT_INSPECTION_VALUE);
        }
    };
};

export const useConsumerRequestsContext = () => useContext(ConsumerRequestsContext);

const initialConsumerRequest: string[] = [];

const ConsumerRequestsProvider = ({ children }: { children: ReactElement<*> }) => {
    const [consumerRequests, updateConsumerRequests] = useState(initialConsumerRequest);

    const addConsumerRequest = (consumerRequest: string) =>
        updateConsumerRequests(
            consumerRequests.filter((request) => request !== consumerRequest).concat(consumerRequest)
        );

    const removeConsumerRequest = (consumerRequest: string) =>
        updateConsumerRequests(consumerRequests.filter((request) => request !== consumerRequest));

    const resetConsumerRequests = () => updateConsumerRequests(initialConsumerRequest);

    return (
        <ConsumerRequestsContext.Provider
            value={{
                consumerRequests,
                addConsumerRequest,
                removeConsumerRequest,
                resetConsumerRequests,
            }}
        >
            {children}
        </ConsumerRequestsContext.Provider>
    );
};

export default ConsumerRequestsProvider;
