/* @flow */
import React from 'react';
import './styles.scss';

const LoadingSection = () => (
    <div className="loading-section loading-section--gradient">
        <div className="loading-section__heading" />
        <div className="loading-section__paragraph">
            <div className="loading-section__line" />
            <div className="loading-section__line" />
            <div className="loading-section__line" />
            <div className="loading-section__line" />
        </div>
    </div>
);

const View = ({ className = '' }: { className?: string }) => (
    <div className={`loading-placeholder ${className}`}>
        <LoadingSection />
        <div className="loading-placeholder__divider" />
        <LoadingSection />
        <div className="loading-placeholder__divider" />
        <LoadingSection />
    </div>
);

export default View;
