/* @flow */
import { ADD_FULL_DETAILS_DATA, CLEAR_FULL_DETAILS_DATA } from './actions';
import { type DetailsData } from '../../../screens/details';

export type FullDetailsAction = {
    type: string,
    data?: DetailsData,
};

export function addFullDetailsData(data: DetailsData): FullDetailsAction {
    return {
        type: ADD_FULL_DETAILS_DATA,
        data,
    };
}

export function clearFullDetailsData(): FullDetailsAction {
    return {
        type: CLEAR_FULL_DETAILS_DATA,
    };
}
