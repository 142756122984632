export const getElementSize = (el: Element) => {
    return {
        width: el.clientWidth,
        height: el.clientHeight,
    };
};
export const scrollTo = (el: Element, elementOffset: number = 0) => {
    let offsetTop: number = 0;
    if (el instanceof HTMLElement) {
        offsetTop = el.offsetTop;
    }
    window.scrollTo({ top: offsetTop - elementOffset, behavior: 'smooth' });
};
