/* @flow */
import React from 'react';
import type { ChildrenArray, Element } from 'react';
import styled from 'styled-components';

export const TwoColumnContainer = styled.div`
    ${({ theme, $variant }) => theme.breakpoints[$variant]} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

export const TwoColumnItem = styled.div`
    width: 100%;
    ${({ theme, $variant }) => theme.breakpoints[$variant]} {
        width: calc(50% - 0.5rem);
    }
    ${({ theme, $gap }) =>
        $gap &&
        `
        &:not(:last-child) {
            margin-bottom: ${theme.spacing[$gap]};
        }
    `}
`;

type TwoColumnLayoutProps = {|
    variant: 'large' | 'medium',
    gap?: 'large' | 'medium',
    children: ChildrenArray<Element<*> | null>,
|};

const TwoColumnLayout = ({ variant, gap, children }: TwoColumnLayoutProps) => {
    return (
        <TwoColumnContainer $variant={variant}>
            {React.Children.map(children, (child) => {
                if (child) {
                    return (
                        <TwoColumnItem $variant={variant} $gap={gap}>
                            {child}
                        </TwoColumnItem>
                    );
                }
            })}
        </TwoColumnContainer>
    );
};

export default TwoColumnLayout;
