/* @flow */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { Modal, Dialog, H2, spacingHelper } from '@rea-group/construct-kit-core';
import type { GetTrackingData } from '../../../../models/lexa';
import DynamicMap from './DynamicMap';
import { MapSkyscraperAd } from '../../../advert';
import type { MapMarkers, MapViewTypes } from './mapTypes';
import { MAP, STREET_VIEW } from './mapTypes';
import MapViewControls from './map-view-controls/View';
import styled, { css } from 'styled-components';

type Props = {
    onClose: () => void,
    onStreetViewClick: () => void,
    ariaLabel: string,
    mapModalTitle: string,
    markers: MapMarkers,
    shouldShowAd: boolean,
    data: GetTrackingData,
};

type MapContentProps = {
    onStreetViewClick: () => void,
    markers: MapMarkers,
};

const fullSize = css`
    width: 100%;
    height: 100%;
`;

const ModalContent = styled.div`
    ${fullSize}
    pointer-events: none;
    * > * {
        pointer-events: initial;
    }

    ${({ theme }) => theme.layoutMediaQueries.tablet} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const StyledDialog = styled(Dialog)`
    margin: 0;
    flex: 1;
    height: 100%;
`;

const MapWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${fullSize}
`;

const ModalMap = styled.div`
    height: auto;
    flex: 1;
`;

const StyledModal = styled(Modal)`
    .ReactModal__Content {
        pointer-events: none;
        ${fullSize}
        ${({ theme }) => theme.layoutMediaQueries.tablet} {
            width: min(62rem, 90%);
            height: min(50rem, 90%);
        }
    }
`;

const AdWrapper = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: ${spacingHelper('extraLarge')};
`;

const DynamicMapWrapper = ({ onClose, onStreetViewClick, mapModalTitle, markers, shouldShowAd, data }: Props) => (
    <StyledModal opened={true} onRequestClose={onClose} getAppElement={() => '#argonaut-wrapper'}>
        <ModalContent>
            <StyledDialog
                header={<H2 variant="title03">{mapModalTitle}</H2>}
                headerAlignment="left"
                autoFullScreen={true}
                fullScreenMediaQuery="(max-width: 50rem)"
                maxWidth="50rem"
                onClose={onClose}
            >
                <MapContent onStreetViewClick={onStreetViewClick} markers={markers} />
            </StyledDialog>
            {shouldShowAd && (
                <AdWrapper data-testid="dynamic-map-advert">
                    <MapSkyscraperAd data={data} />
                </AdWrapper>
            )}
        </ModalContent>
    </StyledModal>
);

const MapContent = ({ onStreetViewClick, markers }: MapContentProps) => {
    const [mapViewType, setMapViewType] = useState<MapViewTypes>(MAP);

    const mapControlButtonOnClick = (newMapViewType: MapViewTypes) => () => {
        if (newMapViewType === STREET_VIEW) {
            onStreetViewClick();
        }
        setMapViewType(newMapViewType);
    };

    return (
        <MapWrapper>
            <ModalMap>
                <DynamicMap markers={markers} mapViewType={mapViewType} />
            </ModalMap>
            <MapViewControls onClick={mapControlButtonOnClick} />
        </MapWrapper>
    );
};

export default DynamicMapWrapper;
