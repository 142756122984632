/* @flow */
import { useState, useEffect } from 'react';
import type { Node } from 'react';
import type { Geocode } from '../../../../models/lexa';
import { schoolsDataAdapter, childcareDataAdapter } from './dataAdapter';
import type { SchoolsData } from '../types';

type FetchedDataState = ({ data: ?SchoolsData, progress: string }) => Node;
type Props = {
    count: number,
    geoLocation: Geocode,
    children: FetchedDataState,
};

const LOADING = 'LOADING';
const LOAD_DONE = 'LOAD_DONE';
const LOAD_ERROR = 'LOAD_ERROR';
const HTTP_TIMEOUT_IN_MS = 2000;

const Service = (props: Props) => {
    const [data, setData] = useState(null);
    const [progress, setProgress] = useState(LOADING);

    useEffect(() => {
        const schoolServiceUrl = `https://school-service.realestate.com.au/closest_by_type?lat=${props.geoLocation.latitude}&lon=${props.geoLocation.longitude}&count=${props.count}`;

        const childcareServiceUrl = `https://rea.careforkids.com.au/api/realestate/closest?lat=${props.geoLocation.latitude}&lon=${props.geoLocation.longitude}&count=${props.count}`;

        let schoolsData = [];
        let childcareData = [];

        setProgress(LOADING);

        const abortController = new AbortController();
        const timeoutId = setTimeout(() => abortController.abort(), HTTP_TIMEOUT_IN_MS);

        fetch(schoolServiceUrl)
            .then((response) => response.json())
            .then((data) => {
                schoolsData = schoolsDataAdapter(data);

                return fetch(childcareServiceUrl, { timeout: HTTP_TIMEOUT_IN_MS, signal: abortController.signal });
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw Error(response.statusText);
            })
            .then((data) => {
                childcareData = childcareDataAdapter(data);
                setProgress(LOAD_DONE);
                return childcareData;
            })
            .catch(() => {
                setProgress(LOAD_ERROR);
            })
            .finally(() => {
                clearTimeout(timeoutId);
                setData([...schoolsData, ...childcareData]);
            });
    }, [props.count, props.geoLocation.latitude, props.geoLocation.longitude]);

    return props.children({ data, progress });
};

export default Service;
