import React, { useState, useEffect, useRef, MouseEventHandler } from 'react';
import ScrollToElement from '../details/ScrollToElement';
import { ArrowDownMd, ArrowUpMd } from '@rea-group/construct-kit-core';
import { TellMeMoreContainer, TellMeMoreText, TellMeMoreButton, StyledText } from './styles';

type Props = {
    children?: React.ReactNode;
    scrollToElement: Element | null;
    trackShowMoreClick: () => void;
};

export const ReadMoreOrLessButton = ({
    onClick,
    text,
    isExpanded,
}: {
    onClick: MouseEventHandler;
    text: string;
    isExpanded: boolean;
}) => (
    <TellMeMoreButton variant="link-primary" onClick={onClick} aria-hidden="true">
        <StyledText variant="subtitle01">{text}</StyledText>
        {isExpanded ? <ArrowUpMd /> : <ArrowDownMd />}
    </TellMeMoreButton>
);

const TellMeMore = ({ children, scrollToElement, trackShowMoreClick }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isShort, setIsShort] = useState(false);
    const [showMoreClicked, setShowMoreClicked] = useState(false);
    const [overflow, setOverflow] = useState(true);
    const contentRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (overflow) {
            if (!showMoreClicked) {
                setShowMoreClicked(true);
                if (!isExpanded && trackShowMoreClick) {
                    trackShowMoreClick();
                }
            }
            setIsExpanded(!isExpanded);
        }
    };

    useEffect(() => {
        const content = contentRef;
        const container = containerRef;
        if (content.current && container.current) {
            setOverflow(
                content.current.getBoundingClientRect().height > container.current.getBoundingClientRect().height
            );
            if (!overflow) {
                setIsShort(true);
            }
        }
    }, [overflow, containerRef, contentRef]);

    return (
        <div>
            <TellMeMoreContainer ref={containerRef} $isExpanded={isExpanded} $isShort={isShort}>
                <TellMeMoreText ref={contentRef} onClick={isExpanded ? () => {} : handleClick} $isExpanded={isExpanded}>
                    {children}
                </TellMeMoreText>
            </TellMeMoreContainer>
            {isExpanded && !isShort && (
                <ScrollToElement elementRef={() => scrollToElement} afterScroll={handleClick}>
                    {(scrollOnClick) => (
                        <ReadMoreOrLessButton onClick={scrollOnClick} text="Read less" isExpanded={true} />
                    )}
                </ScrollToElement>
            )}
            {!isExpanded && !isShort && (
                <ReadMoreOrLessButton
                    onClick={() => {
                        handleClick();
                    }}
                    text="Read more"
                    isExpanded={false}
                />
            )}
        </div>
    );
};

export default TellMeMore;
