/* @flow */
import React from 'react';
import { stateProvider as navigationStateProvider } from '../../navigation';
import { ProjectProfileBreadcrumb, ResidentialListingBreadcrumb } from '../breadcrumb';
import View from './View';
import type { Props } from './types';
import type { GetProjectProfile, GetResidentialListing } from '../../../models/lexa';

const ProjectProfileView = (props: {| ...Props, data: GetProjectProfile |}) => (
    <View {...props} Breadcrumb={ProjectProfileBreadcrumb} />
);

const ResidentialListingView = (props: {| ...Props, data: GetResidentialListing |}) => (
    <View {...props} Breadcrumb={ResidentialListingBreadcrumb} />
);

export const ProjectProfileNavSecondary = navigationStateProvider(ProjectProfileView);
export const ResidentialListingNavSecondary = navigationStateProvider(ResidentialListingView);
