// @flow

import type { PhotoOverlayGalleryData, PhotoOverlayGalleryItem } from './index';

export const toPhotoOverlayWithGalleryItem = ({
    templatedUrl,
    heading,
    subHeading,
    href,
    title,
    gallery,
}: PhotoOverlayGalleryData): PhotoOverlayGalleryItem => {
    return {
        mediaType: 'photo overlay with gallery',
        src: {
            url: templatedUrl,
        },
        heading,
        subHeading,
        link: {
            href,
            title,
        },
        gallery,
    };
};
