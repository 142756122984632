import styled from 'styled-components';
import { paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';

import { GUTTER, WIDTH_CONSTRAIN } from '../../../styles/application/layout';

const stickyCtaHeight = '4.875rem';
export const StickyCTA = styled.div<{ $isHidden: boolean }>`
    position: fixed;
    padding: ${GUTTER}px;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 61;
    background-color: ${paletteHelper('backgroundPrimary')};
    box-shadow: 0 -0.1em 0.5em 0 rgba(0, 0, 0, 0.3);
    display: flex;

    transform: ${(props) => (props.$isHidden ? `translate3d(0, ${stickyCtaHeight}, 0)` : 'translate3d(0, 0, 0)')};
    transition: ${(props) =>
        props.$isHidden
            ? 'transform 0.4s ease-out, opacity 0.6s ease-in;'
            : 'transform 0.4s ease-out, opacity 0.2s ease-in'};
    opacity: ${(props) => (props.$isHidden ? 0 : 1)};

    ${({ theme }) => theme.breakpoints.large} {
        display: none;
    }
`;

export const buttonContainerClass = 'StickyCTA__button-container';
const StickyCTAConstrainer = styled.div`
    margin: 0 auto;
    max-width: ${WIDTH_CONSTRAIN}px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .${buttonContainerClass} {
        width: calc(50% - ${GUTTER}px / 2);
        &:only-child {
            width: 100%;
        }
        & button,
        a {
            height: 100%;
            padding-left: ${spacingHelper('extraSmall')};
            padding-right: ${spacingHelper('extraSmall')};
        }
        & div {
            height: 100%;
        }
    }
`;

export { StickyCTAConstrainer };
