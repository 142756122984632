import { paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

export const StyledAvatarWrapper = styled.div`
    margin-bottom: 10px;
`;

export const StyledAvatar = styled.div`
    height: ${spacingHelper('threeExtraLarge')};
    width: ${spacingHelper('threeExtraLarge')};
    background-color: ${paletteHelper('backgroundPrimaryInverted')};
    color: ${paletteHelper('textInverted')};
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`;
