/* @flow */
import React from 'react';
import View, { AgentRatingPlaceholder } from './View';
import RenderBelowTheFold from '../../RenderBelowTheFold';
import adaptData from './adaptLexaData';
import type { AgentRatingsProps } from './types';
import RenderAgentRatingsIfRequired from '../render-agent-ratings-if-required';

const AgentRatings = ({ data, listerId }: AgentRatingsProps) => {
    return (
        <RenderAgentRatingsIfRequired data={data}>
            <RenderBelowTheFold data={data} placeholder={<AgentRatingPlaceholder />}>
                <View {...adaptData(listerId)(data)} />
            </RenderBelowTheFold>
        </RenderAgentRatingsIfRequired>
    );
};

export default AgentRatings;
