/* @flow */
import React, { Component } from 'react';
import PrivacyStatement from '../../../privacy-statement';
import { Button, Text, paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

const StyledStatement = styled(Text).attrs({ forwardedAs: 'div', variant: 'body02' })`
    color: ${paletteHelper('textSecondary')};
    margin: ${spacingHelper('small')} 0 0 0;
`;
const StyledText = styled(Text)`
    margin: 1rem 0;
`;

type State = {|
    showCollectionStatement: boolean,
|};

export default class CollectionStatement extends Component<{}, State> {
    state = {
        showCollectionStatement: false,
    };

    collectionReveal = () => {
        this.setState({ showCollectionStatement: !this.state.showCollectionStatement });
    };

    render() {
        const { showCollectionStatement } = this.state;
        return (
            <StyledText forwardedAs="div" variant="body02">
                <Button
                    variant="link-secondary"
                    type="button"
                    className="collection-statement__privacy"
                    aria-pressed={showCollectionStatement}
                    onClick={this.collectionReveal}
                >
                    Personal Information Collection Statement
                </Button>
                {showCollectionStatement && (
                    <StyledStatement>
                        <PrivacyStatement />
                    </StyledStatement>
                )}
                <StyledStatement>
                    This form is only to be used for sending genuine email enquiries to the Agent. realestate.com.au Pty
                    Ltd reserves its right to take any legal or other appropriate action in relation to misuse of this
                    service.
                </StyledStatement>
            </StyledText>
        );
    }
}
