/* @flow */

import type { GetEventTracker } from '../../../models/lexa';

const getEventTrackerData = (data: GetEventTracker) => ({
    disclaimerClickTracker: data.getEventTracker().callLeadAttributionDisclaimerModalOpen,
});

export default (data: GetEventTracker) => ({
    ...getEventTrackerData(data),
});
