/* @flow */
import { left, right } from '../../../../util/functional/Either';
import { some, none } from '../../../../util/functional/Option';
import preloadImage from '../../../../util/preloadImage';
import type { Option } from '../../../../util/functional/Option';
import type { Either } from '../../../../util/functional/Either';
import type { MapUrlParameters, FetchMapUrl, FetchMapUrlOutput } from '../dependencies';

type FetchMapUrlFoldType = Either<Error, Option<string>> | Promise<Either<Error, Option<string>>>;

const preloadFetchedMapUrl =
    (fetchMapUrl: FetchMapUrl) =>
    (urlParameters: MapUrlParameters): FetchMapUrlOutput => {
        return fetchMapUrl(urlParameters).then((result) =>
            result.fold<FetchMapUrlFoldType>(left, (optionData) =>
                optionData.fold<FetchMapUrlFoldType>(
                    () => right<Error, Option<string>>(none),
                    (data) =>
                        preloadImage(data)
                            // eslint-disable-next-line promise/no-nesting
                            .promise.then((url) => right<Error, Option<string>>(some<string>(url)))
                            // eslint-disable-next-line promise/no-nesting
                            .catch(left)
                )
            )
        );
    };

export default preloadFetchedMapUrl;
