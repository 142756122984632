/* @flow */
import React, { Component, createRef, useContext } from 'react';
import { useConsumerRequestsContext } from './ConsumerRequestsProvider';
import UserContext from '../../../../util/UserContext';
import EnquiryRequest from './enquiry-request';
import type { ConsumerType } from './enquiry-consumer';
import EnquiryConsumer, { consumerTypeInputOverPrefill } from './enquiry-consumer';
import EnquiryPreapproval, { preapprovalInputOverPrefill, preapprovalMessage } from './enquiry-preapproval';
import CollectionStatement from './CollectionStatementView';
import TwoColumnLayout from './Layout';
import type { AdapterProps, AgentContactService, ConsumerRequestsContext, OnSuccess, Tracker } from './types';
import appLogger from '../../../../util/logging/appLogger';
import {
    Alert,
    Button,
    paletteHelper,
    spacingHelper,
    Stack,
    TextArea,
    TextInput,
    typographyHelper,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import type { GetBuyResidentialListing, GetProjectProfile } from '../../../../models/lexa';
import type { PrefillData } from './enquiry-prefill';
import {
    containsBadUrl,
    errorMessagesTemplate,
    isBlank,
    validateEmail,
    validateName,
    validatePhoneNumber,
    validatePostcode,
} from './validateContactAgentForm';
import { passEmailToLiveRamp } from '@media/ad-kit';
import { type Channel } from '../../../../models/Channel';
import { createBrowserUser, type User } from '@realestate.com.au/user';
import AuthToEnquire from '../auth-to-enquire';
import HttpClientError from '../../../../util/http-client/HttpClientError';
import { HttpStatus } from '../../../../util/http-client/HttpStatus';

const AlertStyled = styled(Alert)`
    && {
        margin-bottom: ${spacingHelper('medium')};
    }
`;

const Form = styled.form`
    line-height: ${spacingHelper('large')};
`;

const RequiredLabel = styled.span`
    ${typographyHelper('body02')}
    color: ${paletteHelper('textSecondary')};
`;

export const FakeEnquiryForm = (
    <div data-testid="fake-enquiry-form" aria-hidden="true">
        <Stack inset="medium 0" gap="medium">
            <EnquiryRequest checkedValues={[]} handleChange={() => {}} disabledKeyboard={true} />
        </Stack>
    </div>
);

export const FakeMessageBox = (
    <Stack inset="medium 0" gap="medium">
        <TextArea
            label="Message"
            id="contactAgentConsumerMessage"
            value=""
            disabled={true}
            data-testid="fake-message-box"
        />
    </Stack>
);

type ViewProps = {|
    data: GetBuyResidentialListing | GetProjectProfile,
    agentContactService: AgentContactService,
    onSuccess: OnSuccess,
    tracker: Tracker,
    getUser: () => User,
    prefillData: PrefillData,
    savePrefillData: (data: PrefillData) => void,
    channel: Channel,
|};

type Props = {| ...AdapterProps, ...ViewProps |};

type PropsWithConsumerRequestsContext = {| ...Props, ...ConsumerRequestsContext, user: User |};

type State = {
    consumerRequests: string[],
    consumerType: ?ConsumerType,
    consumerMessage: string,
    validationErrorMessages: ValidationErrorMessages,
    shouldDisableSubmitButton: boolean,
    showNetworkErrorMessage: boolean,
    showBadWordErrorMessage: boolean,
    preapproval: string,
    user: ?User,
};

type ValidationErrorMessages = {
    consumerMessageError: string,
    nameError: string,
    emailError: string,
    phoneError: string,
    postcodeError: string,
};

const getValueOrEmptyString = (ref: { current: null | HTMLInputElement }): string => {
    const fieldValue = ref.current?.value ?? '';
    return fieldValue.trim();
};

class ContactAgentForm extends Component<PropsWithConsumerRequestsContext, State> {
    formRef: { current: null | HTMLFormElement };
    nameRef: { current: null | HTMLInputElement };
    emailRef: { current: null | HTMLInputElement };
    phoneRef: { current: null | HTMLInputElement };
    postcodeRef: { current: null | HTMLInputElement };

    constructor(props: PropsWithConsumerRequestsContext) {
        super(props);

        this.state = {
            consumerRequests: [],
            consumerType: null,
            consumerMessage: '',
            shouldDisableSubmitButton: false,
            validationErrorMessages: {
                consumerMessageError: '',
                nameError: '',
                emailError: '',
                phoneError: '',
                postcodeError: '',
            },
            showNetworkErrorMessage: false,
            showBadWordErrorMessage: false,
            preapproval: '',
            user: this.props.user,
        };

        this.formRef = createRef();
        this.nameRef = createRef();
        this.emailRef = createRef();
        this.phoneRef = createRef();
        this.postcodeRef = createRef();
    }

    handleConsumerRequestChange = (e: SyntheticInputEvent<>) => {
        if (e.target.checked) {
            this.props.addConsumerRequest(e.target.value);
        } else {
            this.props.removeConsumerRequest(e.target.value);
        }
    };

    handleConsumerTypeChange = (selected: Object) => {
        this.setState({
            consumerType: selected,
        });
    };

    handleConsumerMessageChange = (e: SyntheticInputEvent<>) => {
        this.setState({
            consumerMessage: e.target.value,
        });
    };

    handleNameBlur = () => {
        const name = getValueOrEmptyString(this.nameRef);
        const validationErrorMessages = {
            ...this.state.validationErrorMessages,
            nameError: validateName(name),
        };
        this.setState({ validationErrorMessages });
    };

    handleEmailBlur = () => {
        const email = getValueOrEmptyString(this.emailRef);
        const validationErrorMessages = {
            ...this.state.validationErrorMessages,
            emailError: validateEmail(email),
        };
        this.setState({ validationErrorMessages });
    };

    handleMobileBlur = () => {
        const mobile = getValueOrEmptyString(this.phoneRef);
        const validationErrorMessages = {
            ...this.state.validationErrorMessages,
            phoneError: validatePhoneNumber(mobile),
        };
        this.setState({ validationErrorMessages });
    };

    handlePostcodeBlur = () => {
        const postcode = getValueOrEmptyString(this.postcodeRef);
        const validationErrorMessages = {
            ...this.state.validationErrorMessages,
            postcodeError: validatePostcode(postcode),
        };
        this.setState({ validationErrorMessages });
    };

    handlePreapprovalChange = (e: SyntheticInputEvent<>) => {
        this.setState({
            preapproval: e.target.value,
        });
    };

    handleSubmissionFailure = (error: HttpClientError) => {
        appLogger.error('Error submitting contact agent form')(error);

        if (error.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.setState({ showBadWordErrorMessage: true });
        } else {
            this.setState({ showNetworkErrorMessage: true });
        }
    };

    onFormSubmitting = () => {
        this.setState({ shouldDisableSubmitButton: true });
    };

    onFormSubmitted = () => {
        this.setState({ shouldDisableSubmitButton: false });
    };

    resetAllFields = () => {
        this.setState({
            consumerType: null,
            consumerMessage: '',
            preapproval: '',
        });
        this.formRef.current && this.formRef.current.reset();
        this.props.resetConsumerRequests();
    };

    resetNonPrefilledFields = () => {
        this.setState({
            consumerMessage: '',
        });
        this.props.resetConsumerRequests();
    };

    isFormValid = () => {
        const validationError = this.state.validationErrorMessages;
        const values = Object.keys(validationError).map((e) => validationError[e]);
        return values.every((error) => error === '');
    };

    handleSubmit = (e: SyntheticEvent<>) => {
        e.preventDefault();
        const { consumerMessage, consumerType, preapproval, user } = this.state;
        const { listingId, url, consumerRequests, agentContactService, onSuccess, tracker, channel } = this.props;
        const name = getValueOrEmptyString(this.nameRef);
        const email = getValueOrEmptyString(this.emailRef);
        const mobile = getValueOrEmptyString(this.phoneRef);
        const postcode = getValueOrEmptyString(this.postcodeRef);

        const { prefillData, savePrefillData } = this.props;

        const preapprovalValue = preapprovalInputOverPrefill(channel, preapproval, prefillData?.preapproval);
        const consumerTypeValue = consumerTypeInputOverPrefill(channel, consumerType, prefillData?.consumerType);

        const message = `${consumerMessage}${preapprovalMessage(preapprovalValue)}`;

        const data = {
            likeTo: consumerRequests,
            lookingTo: consumerTypeValue ? consumerTypeValue.label : '',
            name: name,
            fromAddress: email,
            fromPhone: mobile,
            postcode: postcode,
            message,
            myReaId: user?.consumerId,
        };

        const trackData = {
            enquiry_type: consumerRequests,
            about_me: consumerTypeValue ? [consumerTypeValue.value] : [],
            message_provided: !isBlank(consumerMessage),
            email_provided: !isBlank(email),
            mobile_provided: !isBlank(mobile),
            postcode_provided: !isBlank(postcode),
            pre_approval: preapprovalValue === 'ratherNotSay' ? 'rather_not_say' : preapprovalValue,
        };
        const prefillDataToSave = {
            name: name,
            email: email,
            phoneNumber: mobile,
            postcode: postcode,
            consumerType: consumerTypeValue ? consumerTypeValue.value : '',
            preapproval: preapprovalValue,
            updatedAt: Date.now(),
        };

        this.setState(
            {
                validationErrorMessages: {
                    consumerMessageError: containsBadUrl(consumerMessage) ? errorMessagesTemplate.containsDots : '',
                    nameError: validateName(name),
                    emailError: validateEmail(email),
                    phoneError: validatePhoneNumber(mobile),
                    postcodeError: validatePostcode(postcode),
                },
                showNetworkErrorMessage: false,
                showBadWordErrorMessage: false,
            },
            () => {
                if (this.isFormValid()) {
                    this.onFormSubmitting();
                    tracker(trackData);
                    savePrefillData(prefillDataToSave);
                    passEmailToLiveRamp(email);
                    return agentContactService(url, data)
                        .then((value) => {
                            this.resetNonPrefilledFields();
                            onSuccess({
                                listingId,
                                consumerName: name,
                                isUserSignedIn: true,
                                email,
                                mobile,
                                preapproval: preapprovalValue,
                                savePrefillDataAfterLogin: () => {
                                    savePrefillData?.(prefillDataToSave);
                                },
                                resetFormData: this.resetAllFields,
                            });
                            return value;
                        })
                        .catch(this.handleSubmissionFailure)
                        .finally(this.onFormSubmitted);
                }
            }
        );
    };

    onJoinOrSignInSuccess = () => {
        const { getUser = createBrowserUser } = this.props;
        const user = getUser();
        this.setState({ user });
    };

    renderErrorMessage = () => {
        const { showNetworkErrorMessage, showBadWordErrorMessage } = this.state;

        if (showBadWordErrorMessage) {
            return (
                <AlertStyled variant="error">
                    Your message contains inappropriate language and couldn&apos;t be sent. Please revise your message
                    and try again.
                </AlertStyled>
            );
        }

        if (showNetworkErrorMessage) {
            return (
                <AlertStyled variant="warning">There was an error sending your message. Please try again.</AlertStyled>
            );
        }

        return null;
    };

    render() {
        const { consumerType, consumerMessage, preapproval, user } = this.state;
        const { consumerRequests, prefillData, channel } = this.props;
        const { consumerMessageError, nameError, emailError, phoneError, postcodeError } =
            this.state.validationErrorMessages;

        const preapprovalValue = preapprovalInputOverPrefill(channel, preapproval, prefillData?.preapproval);
        const consumerTypeValue =
            consumerTypeInputOverPrefill(channel, consumerType, this.props.prefillData?.consumerType) || null;
        if (user && user.isSignedIn) {
            return (
                <Form
                    id="contactAgentWithLogin"
                    data-testid="contactAgentWithLogin"
                    onSubmit={this.handleSubmit}
                    ref={this.formRef}
                    autoComplete="on"
                    noValidate={true}
                >
                    <Stack inset="medium 0" gap="medium">
                        <EnquiryRequest
                            checkedValues={consumerRequests}
                            handleChange={this.handleConsumerRequestChange}
                        />
                        <TextArea
                            label="Message"
                            id="contactAgentConsumerMessage"
                            value={consumerMessage}
                            onChange={this.handleConsumerMessageChange}
                            errorMessage={consumerMessageError}
                        />
                        <TwoColumnLayout variant="large" gap="medium">
                            <TextInput
                                id="contactAgentEnquiryName"
                                label={
                                    <React.Fragment>
                                        Name
                                        <RequiredLabel> (required)</RequiredLabel>
                                    </React.Fragment>
                                }
                                onBlur={this.handleNameBlur}
                                name="name"
                                autoComplete="name"
                                errorMessage={nameError}
                                iconLeft={false}
                                ref={this.nameRef}
                                defaultValue={prefillData?.name}
                            />
                            <TextInput
                                id="contactAgentEnquiryEmail"
                                label={
                                    <React.Fragment>
                                        Email address
                                        <RequiredLabel> (required)</RequiredLabel>
                                    </React.Fragment>
                                }
                                type="email"
                                onBlur={this.handleEmailBlur}
                                name="email"
                                autoComplete="email"
                                errorMessage={emailError}
                                iconLeft={false}
                                ref={this.emailRef}
                                defaultValue={prefillData?.email}
                            />
                            <TextInput
                                id="contactAgentEnquiryMobile"
                                label="Phone number"
                                type="tel"
                                onBlur={this.handleMobileBlur}
                                name="mobile"
                                autoComplete="tel"
                                errorMessage={phoneError}
                                iconLeft={false}
                                ref={this.phoneRef}
                                defaultValue={prefillData?.phoneNumber}
                            />
                            {channel === 'buy' || channel === 'sold' ? (
                                <TextInput
                                    id="contactAgentEnquiryPostcode"
                                    label="Postcode"
                                    onBlur={this.handlePostcodeBlur}
                                    name="postcode"
                                    autoComplete="postcode"
                                    errorMessage={postcodeError}
                                    iconLeft={false}
                                    ref={this.postcodeRef}
                                    defaultValue={prefillData?.postcode}
                                />
                            ) : null}
                            <EnquiryConsumer
                                selectedOption={consumerTypeValue}
                                handleChange={this.handleConsumerTypeChange}
                            />
                        </TwoColumnLayout>
                        <EnquiryPreapproval
                            selectedOption={preapprovalValue}
                            handleChange={this.handlePreapprovalChange}
                        />
                    </Stack>
                    {this.renderErrorMessage()}
                    <Button
                        id="contactAgentEnquirySubmit"
                        fullWidth={true}
                        type="submit"
                        disabled={this.state.shouldDisableSubmitButton}
                    >
                        Send enquiry
                    </Button>
                    <CollectionStatement />
                </Form>
            );
        }

        return (
            <AuthToEnquire
                enquiryForm={channel === 'sold' ? FakeMessageBox : FakeEnquiryForm}
                onJoinOrSignInSuccess={this.onJoinOrSignInSuccess}
            />
        );
    }
}

export default (props: Props) => {
    const consumerRequestsContext = useConsumerRequestsContext();
    const user: User = useContext(UserContext);
    return <ContactAgentForm {...props} {...consumerRequestsContext} user={user} />;
};
