/* @flow */
import type { Node } from 'react';
import adaptLexaData from './adaptLexaData';

type Props = {
    data: Object,
    children: Node,
};

const RenderAgentRatingsIfRequired = ({ data, children }: Props) => {
    const shouldRenderAgentRatings = adaptLexaData(data);

    return shouldRenderAgentRatings ? children : null;
};

export default RenderAgentRatingsIfRequired;
