/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { ResidentialDetailsGetters, GetResidentialListing, ResidentialListingMedia } from '../../../models/lexa';
import type { MediaIconClickType } from '../../../client/tracking/event/types';
import { SlideNames, type SlideName } from '../../media-viewer';
import { option, none, some } from '../../../util/functional/Option';
import ResidentialMediaTypeBar from './ResidentialMediaTypeBar.graphql';

const mediaTypeBarAdapter =
    (openMediaViewer: (name: SlideName) => void) =>
    ({ media }: RequiredData) =>
    (mediaViewerIconClick: (type: MediaIconClickType) => void): AdaptedProps => {
        const { images, floorplans, threeDimensionalTours, videos } = media;
        const maybeFirstVideo = option(videos[0]);
        const maybeYouTubeVideo = maybeFirstVideo.filter((video) => video.__typename === 'YouTubeVideo');
        const maybeExternalVideoLink = maybeFirstVideo.flatMap((video) =>
            video.__typename === 'ExternalVideo'
                ? some({
                      href: video.href,
                      action: (e: SyntheticEvent<>) => {
                          e.stopPropagation();
                          mediaViewerIconClick('video');
                      },
                  })
                : none
        );

        return {
            photoCount: images.length,
            openPhoto: images.length
                ? (e: SyntheticEvent<>) => {
                      e.stopPropagation();
                      openMediaViewer(SlideNames.FIRST);
                  }
                : null,
            openFloorplan: floorplans.length
                ? (e: SyntheticEvent<>) => {
                      e.stopPropagation();
                      openMediaViewer(SlideNames.FLOORPLAN);
                      mediaViewerIconClick('floorplan');
                  }
                : null,
            openThreeDimensionalTour:
                threeDimensionalTours.length > 0
                    ? (e: SyntheticEvent<>) => {
                          e.stopPropagation();
                          openMediaViewer(SlideNames.VIRTUAL_TOUR);
                          mediaViewerIconClick('3DTour');
                      }
                    : null,
            openYouTubeVideo: maybeYouTubeVideo
                .map(() => (e: SyntheticEvent<>) => {
                    e.stopPropagation();
                    openMediaViewer(SlideNames.VIDEO);
                    mediaViewerIconClick('video');
                })
                .getOrElse(null),
            externalVideoLink: maybeExternalVideoLink.getOrElse(null),
        };
    };

type RequiredData = {
    media: ResidentialListingMedia,
};

type AdaptedProps = {
    photoCount: number,
    openPhoto: ?(e: SyntheticEvent<>) => void,
    openFloorplan: ?(e: SyntheticEvent<>) => void,
    openThreeDimensionalTour: ?(e: SyntheticEvent<>) => void,
    openYouTubeVideo: ?(e: SyntheticEvent<>) => void,
    externalVideoLink?: ?{
        href: string,
        action: (e: SyntheticEvent<>) => void,
    },
};

const getListingData = (openMediaViewer: (name: SlideName) => void) =>
    compose(
        mediaTypeBarAdapter(openMediaViewer),
        getFragmentData(ResidentialMediaTypeBar),
        (data: GetResidentialListing) => data.getListing()
    );

export default (openMediaViewer: (name: SlideName) => void) => (data: ResidentialDetailsGetters) => {
    const listingData = getListingData(openMediaViewer)(data);
    const mediaViewerIconClick = data.getEventTracker().mediaViewerIconClick;
    return listingData(mediaViewerIconClick);
};
