// @flow

export type TestParams = {|
    nullifyOptionals: boolean,
|};

export const getTestParams = (url: URL): TestParams => {
    const nullifyOptionals = !!url.searchParams.get('nullifyOptionals');
    return { nullifyOptionals };
};
