import { compose3 as compose } from '../../../../util/functional/typescript/compose';
import {
    ResidentialDetailsGetters,
    ResidentialListing,
    ResidentialListingLinks,
    Lister,
    Maybe,
} from '../../../../../types';
import getFragmentData from '../../../../util/typescript/getFragmentData';
import ResidentialListingContactAgentFormV2 from './ResidentialListingContactAgentFormV2.graphql';
import { AdaptedData } from './adaptData';

type ResidentialExtractedData = {
    listers?: Maybe<Array<Lister>>;
    _links: ResidentialListingLinks;
};

const residentialDataAdapater = (data: ResidentialExtractedData): AdaptedData => {
    return {
        agentNames: data.listers?.map((lister) => lister.name) || [],
        submitUrl: data._links.submitEnquiry.href,
    };
};

const residentialAdaptData = compose<
    ResidentialDetailsGetters,
    ResidentialListing,
    ResidentialExtractedData,
    AdaptedData
>(residentialDataAdapater, getFragmentData(ResidentialListingContactAgentFormV2), (data: ResidentialDetailsGetters) =>
    data.getListing()
);

export default residentialAdaptData;
