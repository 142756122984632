import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import { Props } from './types';

const ProductComponent = ({ data, onClose }: Props) => {
    const adaptedProps = adaptLexaData(data);
    if (!adaptedProps) return null;

    return <View onClose={onClose} {...adaptedProps} />;
};

export default ProductComponent;
