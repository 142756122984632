// @flow
import { useEffect, useState } from 'react';
import { GOOGLE_MAPS_DEFAULT_CONFIG } from './config';
import useGoogleMapsApi from './useGoogleMapsApi';

type GoogleMapsConfig = {};

export default function useAsyncGoogleMap(el: ?HTMLElement, config: GoogleMapsConfig = GOOGLE_MAPS_DEFAULT_CONFIG) {
    const [mapsApi, error] = useGoogleMapsApi();
    const [map, setMap] = useState(null);
    const [hostElement, setHostElement] = useState<?HTMLDivElement>(null);
    useEffect(() => {
        if (mapsApi && !map && el) {
            const mapContainer = document.createElement('div');
            const instance = new mapsApi.Map(mapContainer, config);
            setMap(instance);
            el.appendChild(mapContainer);
            setHostElement(mapContainer);
        }
        return () => {
            if (map) {
                hostElement && el && el.removeChild(hostElement);
                setMap(null);
            }
        };
    }, [config, el, map, mapsApi, setMap, hostElement, setHostElement]);

    return [map, error];
}
