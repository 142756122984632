/* @flow */
import React, { useContext } from 'react';
import View from '../../breadcrumb';
import adaptResidentialListingData from './adaptResidentialListingData';
import adaptProjectProfileData from './adaptProjectProfileData';
import type { GetProjectProfile, GetBuyResidentialListing } from '../../../models/lexa';
import type { PageType } from '../../navigation/reducer';
import ChannelContext from '../../../util/ChannelContext';

type BuyResidentialListingDataProps = {|
    className?: string,
    data: GetBuyResidentialListing,
|};

type ProjectProfileDataProps = {
    className?: string,
    fromPage: PageType,
    data: GetProjectProfile,
};

export const ResidentialListingBreadcrumb = ({ className = '', data }: BuyResidentialListingDataProps) => {
    const channel = useContext(ChannelContext);
    const { showBreadcrumbs, ...adaptedData } = adaptResidentialListingData(channel, data);
    return showBreadcrumbs ? <View className={className} {...adaptedData} /> : null;
};

export const ProjectProfileBreadcrumb = ({ className = '', fromPage, data }: ProjectProfileDataProps) => {
    return <View className={className} {...adaptProjectProfileData(fromPage, data)} />;
};
