import type { GetComponentGetters } from '../../../../types';
import compose from '../../../util/functional/typescript/compose';
import { PropertySummary, AdaptedProps } from './types';

const dataAdapter = ({ component }: { component: PropertySummary | null }): AdaptedProps => {
    if (!component?.summary) {
        return {
            badge: null,
        };
    }

    return {
        badge: component.summary.badge,
    };
};

const adaptLexaData = compose(dataAdapter, (data: GetComponentGetters) => ({
    component: data.getComponent('PropertySummaryComponent'),
}));

export default adaptLexaData;
