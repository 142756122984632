/* @flow */

export type SlideName = 'first' | 'second' | 'third' | 'fourth' | 'last' | 'floorplan' | 'virtualtour' | 'video';

export default {
    FIRST: 'first',
    SECOND: 'second',
    THIRD: 'third',
    FOURTH: 'fourth',
    LAST: 'last',
    FLOORPLAN: 'floorplan',
    VIRTUAL_TOUR: 'virtualtour',
    VIDEO: 'video',
};
