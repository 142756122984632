/* @flow */
import type { Node, Element } from 'react';

type Props = {
    data: Object,
    children: Node,
    placeholder?: Element<*>,
};

const RenderBelowTheFold = ({ data, children, placeholder }: Props) => {
    if (data.shouldRenderBelowTheFold()) {
        return children;
    }

    if (placeholder) return placeholder;
    return null;
};

export default RenderBelowTheFold;
