import { useCallback, useEffect, useState } from 'react';
import { GOOGLE_MAPS_API_URL } from './config';

export default function useGoogleMapsApi() {
    const src = GOOGLE_MAPS_API_URL;
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const onLoad = useCallback(() => {
        if (error) {
            setError(null);
        }
        if (!loaded) {
            setLoaded(true);
        }
    }, [error, loaded, setError, setLoaded]);
    const onError = useCallback(
        (err) => {
            if (error !== err) {
                setError(err);
            }
            if (loaded) {
                setLoaded(false);
            }
        },
        [loaded, error, setError, setLoaded]
    );

    useEffect(() => {
        if (window.google && window.google.maps) {
            onLoad();
            return;
        }
        let el = document.querySelector(`script[src="${src}"]`);
        if (!el) {
            el = document.createElement('script');
            el.setAttribute('type', 'text/javascript');
            el.setAttribute('async', '');
            el.setAttribute('src', src);
            document.body.appendChild(el);
        }
        el.addEventListener('load', onLoad);
        el.addEventListener('error', onError);

        return () => {
            el.removeEventListener('load', onLoad);
            el.removeEventListener('error', onError);
            // we should be cleaning up the existing script element, but gmaps will warn
            // about including the library twice if we remove and then append the script.
            el = null;
        };
    }, [onError, onLoad, src]);

    return [loaded ? window.google.maps : null, error];
}
