/* @flow */

import React from 'react';
import adaptLexaData from './adaptResidentialListingData';
import adaptChildListingData from './adaptChildListingData';
import adaptLexaProjectData from './adaptProjectProfileData';
import adaptEventTracker from './adaptEventTracker';
import DynamicMapWrapper from './DynamicMapWrapper';
import { LAYOUT_DEFINITION, matchesLayout } from '../../../../client/device-capability';
import type {
    BuyResidentialListingDetailsGetters,
    GetResidentialListing,
    GetTrackingData,
    GetProjectProfile,
    GetEventTracker,
} from '../../../../models/lexa';

type DynamicMapProps<D: GetResidentialListing | GetProjectProfile | BuyResidentialListingDetailsGetters> = {
    data: D & GetEventTracker & GetTrackingData,
    onClose: () => void,
    isLargerScreen?: () => boolean,
};

const shouldDisplayModal = () => matchesLayout(LAYOUT_DEFINITION.L, LAYOUT_DEFINITION.XL);

export const DynamicMap = ({
    data,
    onClose,
    isLargerScreen = shouldDisplayModal,
}: DynamicMapProps<GetResidentialListing>) => {
    const adaptedData = adaptLexaData(data);
    if (adaptedData) {
        const shouldShowAd = adaptedData.showMapSkyscraper && isLargerScreen();
        return (
            <DynamicMapWrapper
                {...adaptedData}
                {...adaptEventTracker(data)}
                onClose={onClose}
                data={data}
                shouldShowAd={shouldShowAd}
            />
        );
    }
    return null;
};

export const DynamicChildListingMap = ({
    data,
    onClose,
    isLargerScreen = shouldDisplayModal,
}: DynamicMapProps<BuyResidentialListingDetailsGetters>) => {
    const adaptedData = adaptChildListingData(data);
    if (adaptedData) {
        const shouldShowAd = adaptedData.showMapSkyscraper && isLargerScreen();
        return (
            <DynamicMapWrapper
                {...adaptedData}
                {...adaptEventTracker(data)}
                onClose={onClose}
                data={data}
                shouldShowAd={shouldShowAd}
            />
        );
    }
    return null;
};

export const DynamicProjectMap = ({
    data,
    onClose,
    isLargerScreen = shouldDisplayModal,
}: DynamicMapProps<GetProjectProfile>) => {
    const adaptedData = adaptLexaProjectData(data);
    if (adaptedData) {
        const shouldShowAd = adaptedData.showMapSkyscraper && isLargerScreen();
        return (
            <DynamicMapWrapper
                {...adaptedData}
                {...adaptEventTracker(data)}
                onClose={onClose}
                data={data}
                shouldShowAd={shouldShowAd}
            />
        );
    }
    return null;
};
