/* @flow */
import type { GetEventTracker, GetProjectProfile, GetTrackingData } from '../../models/lexa';
import type { MediaItem } from './View';
import { getProjectProfileData as getListingData } from './slides';

type AdaptedProps = {
    mediaItems: Array<MediaItem>,
};

export type DataType = GetEventTracker & GetProjectProfile & GetTrackingData;

const getTrackingData = (data) => ({ trackingData: data.getTrackingData() });

const getEventTracker = (data) => ({
    eventTracker: data.getEventTracker().mediaChange,
});

export default (data: DataType): AdaptedProps => ({
    ...getListingData(data.getEventTracker().videoPlayed, data.getEventTracker().threeDimensionalTourPlayed)(data),
    ...getEventTracker(data),
    ...getTrackingData(data),
});
