/* @flow */
import React from 'react';
import styled from 'styled-components';
import BackToResultsButton from './BackToResultsButton';
import { OTHERS } from '../../navigation/reducer';
import type { ViewProps } from './types';
import { paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';

const SubNav = styled.div`
    margin: 0 auto;
    background-color: ${paletteHelper('backgroundPrimary')};
    border-bottom: 1px solid ${paletteHelper('borderSecondary')};

    & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        max-width: ${({ theme }) => theme.maxWidth};
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        @media (${({ theme }) => theme.breakpoints.medium}) {
            padding-left: ${spacingHelper('medium')};
            padding-right: ${spacingHelper('medium')};
        }
    }

    & .subnav__breadcrumb {
        flex: 1;
        overflow: hidden;
    }
`;

export default ({ fromPage, isFromNewHomes, data, Breadcrumb }: ViewProps) => {
    const renderBackLink = isFromNewHomes || fromPage !== OTHERS;

    return (
        <SubNav>
            <div>
                {renderBackLink && <BackToResultsButton fromPage={fromPage} />}
                <Breadcrumb className="subnav__breadcrumb" fromPage={fromPage} data={data} />
            </div>
        </SubNav>
    );
};
