/* @flow */
import React from 'react';
import './styles.scss';
import { Button, ExpandMd, paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

type Props = {|
    imageUrl: string,
    onClick: () => void,
|};

const ExpandButton = styled(Button).attrs({ variant: 'naked', sizeVariant: 'small' })`
    position: absolute;
    box-shadow: 0 1px 1px 0 rgba(51, 63, 72, 0.2);
    background-color: ${paletteHelper('backgroundPrimaryLow')};
    top: ${spacingHelper('extraSmall')};
    right: ${spacingHelper('extraSmall')};
`;

const View = ({ imageUrl, onClick }: Props) => {
    const styles = { backgroundImage: `url(${imageUrl})` };
    return (
        <div className="static-map" onClick={onClick}>
            <div className="static-map__img" style={styles} />
            <ExpandButton icon={<ExpandMd />} hideLabel={true} compact={true}>
                Expand to an interactive map
            </ExpandButton>
        </div>
    );
};

export default View;
