import React from 'react';
import styled from 'styled-components';

import BrowserOnly from '../browser-only';
import { trackEvent } from '../../client/tracking/tealium';
import loader from '../../client/tracking/tealium/loader';

function getTealiumUtagData() {
    return loader.then((utag) => utag.data);
}

const Container = styled.div`
    margin-top: ${({ theme }) => theme.spacing.extraLarge};
`;

const EnquiryFormLazy = React.lazy(() => import('./EnquiryFormLazy.js'));

const FinxEnquiryForm = ({ name, phoneNumber, email }) => {
    return (
        <BrowserOnly name="EnquiryForm">
            <Container role="presentation">
                <EnquiryFormLazy
                    fullNameDefault={name}
                    phoneNumberDefault={phoneNumber}
                    emailDefault={email}
                    onLoadTrackingEvent={trackEvent}
                    onSubmitTrackingEvent={trackEvent}
                    getTealiumUtagData={getTealiumUtagData}
                />
            </Container>
        </BrowserOnly>
    );
};

export { FinxEnquiryForm };
