import React from 'react';

import { AgencyTileProps } from './types';
import {
    AgencyImg,
    AgencyLink,
    AgencyName,
    ArrowContainer,
    BrandingStrap,
    RatingReviews,
    StarRating,
    TileContainer,
} from './styles';
import { ArrowRightMd, Inline } from '@rea-group/construct-kit-core';

export default function View({
    __typename,
    agencyName,
    templatedLogoUrl,
    profileUrl,
    rating,
    reviews,
    brandingPrimaryColour,
}: AgencyTileProps) {
    return (
        <AgencyLink href={profileUrl} className={`overview-${__typename}`} aria-label={`${agencyName} profile link`}>
            <TileContainer>
                <BrandingStrap colour={brandingPrimaryColour}>
                    {templatedLogoUrl && (
                        <AgencyImg templatedUrl={templatedLogoUrl} size="182x32" alt={`${agencyName} agency logo`} />
                    )}
                </BrandingStrap>
                <Inline inset="medium" grow={false} justifyContent="space-between">
                    <div>
                        <AgencyName>{agencyName}</AgencyName>
                        {rating !== null && reviews !== null && (
                            <RatingReviews>
                                <StarRating />
                                <span>
                                    {`${rating}`} {`(${reviews})`}
                                </span>
                            </RatingReviews>
                        )}
                    </div>
                    <ArrowContainer>
                        <ArrowRightMd />
                    </ArrowContainer>
                </Inline>
            </TileContainer>
        </AgencyLink>
    );
}
