/* @flow */
import { Checkbox, Text, spacingHelper } from '@rea-group/construct-kit-core';
import React, { useContext } from 'react';
import { type Channel } from '../../../../../models/Channel';
import styled from 'styled-components';
import ChannelContext from '../../../../../util/ChannelContext';
import TwoColumnLayout from '../Layout';

const Legend = styled(Text).attrs({ forwardedAs: 'legend', variant: 'subtitle01' })`
    margin: 0 0 ${spacingHelper('twoExtraSmall')} 0;
`;
const StyledCheckbox = styled(Checkbox)`
    padding: 0.5rem 2rem 0.5rem 0;
`;

type EnquiryRequestProps = {|
    checkedValues: string[],
    handleChange: Function,
    disabledKeyboard?: boolean,
|};

type Answer = { id: string, value: string, label: string };

export const BUY_INSPECTION_VALUE = 'Scheduling an inspection';
export const RENT_INSPECTION_VALUE = 'inspect the property';

const answersForChannel: { [Channel]: Answer[] } = {
    buy: [
        { id: 'contactAgentEnquirySchedule', value: 'Scheduling an inspection', label: 'Scheduling an inspection' },
        { id: 'contactAgentEnquiryPrice', value: 'Price information', label: 'Price information' },
        { id: 'contactAgentEnquiryRates', value: 'Rates & Fees', label: 'Rates & Fees' },
        { id: 'contactAgentEnquirySimilar', value: 'Similar properties', label: 'Similar properties' },
    ],
    rent: [
        { id: 'contactAgentEnquiryDate', value: 'know when the property is available', label: 'Available date' },
        { id: 'contactAgentEnquiryLength', value: 'find out the length of the lease', label: 'Length of lease' },
        { id: 'contactAgentEnquiryInspect', value: 'inspect the property', label: 'Inspection' },
        { id: 'contactAgentEnquiryApply', value: 'obtain a rental application', label: 'Rental application' },
    ],
    sold: [],
};

const EnquiryRequest = ({ checkedValues, handleChange, disabledKeyboard }: EnquiryRequestProps) => {
    const channel = useContext(ChannelContext);
    const answers = answersForChannel[channel];

    return (
        answers.length > 0 && (
            <fieldset>
                <Legend>What&#39;s your enquiry about?</Legend>
                <TwoColumnLayout variant="medium">
                    {answers.map(({ id, value, label }) => {
                        const checked = checkedValues.filter((checkedValue) => checkedValue === value).length === 1;
                        return (
                            <StyledCheckbox
                                id={id}
                                key={id}
                                label={label}
                                value={value}
                                onChange={handleChange}
                                checked={checked}
                                tabIndex={disabledKeyboard ? -1 : 0}
                            />
                        );
                    })}
                </TwoColumnLayout>
            </fieldset>
        )
    );
};

export default EnquiryRequest;
