import { GetListing, GetProjectProfile, ResidentialDetailsGetters } from '../../../../../types';
import projectProfileAdaptData from './projectProfileAdaptData';
import residentialAdaptData from './residentialListingAdaptData';

export type AdaptedData = {
    agentNames?: string[];
    submitUrl: string;
    projectTitle?: string;
    projectFeatures?: string[];
};

const isResidentialData = (
    data: GetListing,
    listingType: 'residential' | 'project-profile'
): data is ResidentialDetailsGetters => {
    if (listingType === 'residential') {
        return true;
    }

    return false;
};

const isProjectProfileData = (
    data: GetListing,
    listingType: 'residential' | 'project-profile'
): data is GetProjectProfile => {
    if (listingType === 'project-profile') {
        return true;
    }

    return false;
};

const adaptData =
    (listingType: 'residential' | 'project-profile') =>
    (data: GetListing): AdaptedData | null => {
        if (isResidentialData(data, listingType)) {
            return residentialAdaptData(data);
        }

        if (isProjectProfileData(data, listingType)) {
            return projectProfileAdaptData(data);
        }

        throw new Error(`ContactAgentForm: Unsupported listing type encountered: ${listingType}`);
    };

export default adaptData;
