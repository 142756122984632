import React, { FunctionComponent } from 'react';
import { H2, ArrowLeftMd } from '@rea-group/construct-kit-core';
import { FormContentContainer, FooterDivider, FooterButton, FooterContainer } from '../styles';
import ContactDetailsCard from './components/contact-details-card';

type ViewProps = {
    listingType: 'residential' | 'project-profile';
    onSubmit: () => void;
    onBack: () => void;
};

const View: FunctionComponent<ViewProps> = ({ onSubmit, onBack }) => {
    return (
        <React.Fragment>
            <FormContentContainer>
                <H2>Review your contact details</H2>
                <ContactDetailsCard firstName="Will" lastName="Sikora" email="will@will.com" postcode="" phone="" />
            </FormContentContainer>
            <FooterDivider />
            <FooterContainer>
                <FooterButton variant="outline" icon={<ArrowLeftMd />} iconPlacement="left" onClick={onBack}>
                    Back
                </FooterButton>
                <FooterButton variant="primary" onClick={onSubmit}>
                    Submit
                </FooterButton>
            </FooterContainer>
        </React.Fragment>
    );
};

export default View;
