import React, { useEffect } from 'react';
import { Button, Text, ArrowDownMd, ArrowUpMd } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { useBoolean } from '../../hooks';

type Props = {
    className?: string;
    children: React.ReactNode;
    buttonText: (isOpen: boolean) => string;
    overrideOpen?: boolean;
    onButtonClick?: (isOpen: boolean) => void;
};

const ExpandoButton = styled(Button)`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.small};
    color: var(--ck-linkPrimary);
    border: none;
    padding: 0.75rem 0 0;
    background-color: var(--ck-backgroundPrimary);

    & > *:last-child {
        margin-left: ${({ theme }) => theme.spacing.twoExtraSmall};
    }

    &:hover {
        text-decoration: underline;
    }
`;

type ExpandoCollapsoGroupProps = {
    $isOpen: boolean;
};

export const ExpandoCollapsoGroup = styled.div<ExpandoCollapsoGroupProps>`
    visibility: visible;
    opacity: 1;
    max-height: 2000px;
    transition: all 0.5s ease-in;
    ${({ $isOpen }) =>
        !$isOpen &&
        `
            visibility: hidden;
            opacity: 0;
            max-height: 0;
            transition: all 0.5s cubic-bezier(0, 1, 0, 1);
        `}
`;

const IconUp = styled(ArrowUpMd)`
    pointer-events: none;
`;

const IconDown = styled(ArrowDownMd)`
    pointer-events: none;
`;

const ExpandoCollapso = ({ className, children, buttonText, overrideOpen = false, onButtonClick }: Props) => {
    const [isOpen, open, close] = useBoolean(overrideOpen);

    useEffect(() => {
        overrideOpen && open();
    }, [overrideOpen, open]);

    return (
        <div>
            <ExpandoCollapsoGroup className={className} $isOpen={isOpen}>
                {children}
            </ExpandoCollapsoGroup>
            <ExpandCollapseButton
                isOpen={isOpen}
                open={open}
                close={close}
                buttonText={buttonText}
                onClick={onButtonClick}
            />
        </div>
    );
};

type ExpandCollapseButtonProps = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    buttonText: (isOpen: boolean) => string;
    onClick?: (isOpen: boolean) => void;
};

const ExpandCollapseButton = ({ isOpen, open, close, buttonText, onClick }: ExpandCollapseButtonProps) => {
    const toggleFunction = () => {
        onClick && onClick(isOpen);
        isOpen ? close() : open();
    };

    return (
        <ExpandoButton type="button" variant="link-primary" onClick={toggleFunction}>
            <Text variant="subtitle01">{buttonText(isOpen)}</Text>
            {isOpen ? <IconUp /> : <IconDown />}
        </ExpandoButton>
    );
};

export default ExpandoCollapso;
