import React, { useEffect, useRef, useState } from 'react';
import {
    AgentInfo,
    AgentName,
    JobTitle,
    RatingReviews,
    StarRating,
    ArrowContainer,
    Arrow,
    StyledCard,
    AgentTileLink,
    AgentImg,
} from './styles';
import { AgentTilesProps } from './type';
import { AgentAvatar } from '../../../../types';
import { useIntersection } from './useIntersection';

export default function View({ __typename, agents, galleryEventTracker }: AgentTilesProps) {
    const [hasTrackingSent, setHasTrackingSent] = useState(false);
    const trackingReference = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersection(trackingReference, { threshold: 1 });

    useEffect(() => {
        if (!hasTrackingSent && isIntersecting) {
            galleryEventTracker(agents);
            setHasTrackingSent(true);
        }
    }, [agents, galleryEventTracker, hasTrackingSent, isIntersecting]);

    const card = (agent: AgentAvatar) => (
        <StyledCard key={agent.agentProfileId}>
            {agent.templatedUrl && (
                <AgentImg templatedUrl={agent.templatedUrl} size="64x64" alt={`${agent.name}'s avatar`} />
            )}
            <AgentInfo>
                <AgentName>{agent.name}</AgentName>
                <JobTitle>{agent.jobTitle}</JobTitle>
                {agent.rating && agent.reviews && (
                    <RatingReviews>
                        <StarRating />
                        {`${agent.rating} (${agent.reviews})`}
                    </RatingReviews>
                )}
            </AgentInfo>
            <ArrowContainer>
                <Arrow />
            </ArrowContainer>
        </StyledCard>
    );

    return agents.map((agent, agentIndex) => (
        <div
            ref={agentIndex === 0 ? trackingReference : null}
            key={`${__typename}-${agentIndex}`}
            className={`overview-${__typename}`}
        >
            {agent.profileUrl ? (
                <AgentTileLink href={agent.profileUrl} aria-label={`${agent.name} profile link`}>
                    {card(agent)}
                </AgentTileLink>
            ) : (
                card(agent)
            )}
        </div>
    ));
}
