/* @flow */
import React, { useMemo } from 'react';
import View from './View';
import FetchAndRenderMap from '../FetchAndRenderMap';
import { default as defaultCreateFetchMapUrl } from '../fetch-data';
import type { GetMapUrlParameters, FetchMapUrl } from '../dependencies';
import getParameters from '../parameters';
import type { GetResidentialListing, GetProjectProfile, GetEventTracker } from '../../../../models/lexa';

type StaticMapProps = {
    data: (GetResidentialListing | GetProjectProfile) & GetEventTracker,
    createFetchMapUrl?: ({ id: string }) => FetchMapUrl,
    getMapUrlParameters?: GetMapUrlParameters,
    onClick: () => void,
};

const StaticMap = ({
    data,
    createFetchMapUrl = defaultCreateFetchMapUrl,
    getMapUrlParameters = getParameters,
    onClick,
}: StaticMapProps) => {
    const id = data.getListing().id;
    const fetchMapUrl = useMemo(() => createFetchMapUrl({ id }), [id, createFetchMapUrl]);
    const onClickWithEventTracker = () => {
        onClick();
        data.getEventTracker().staticMap();
    };
    return (
        <FetchAndRenderMap getMapUrlParameters={getMapUrlParameters} fetchMapUrl={fetchMapUrl}>
            {(googleMapsUrl) =>
                googleMapsUrl ? <View imageUrl={googleMapsUrl} onClick={onClickWithEventTracker} /> : null
            }
        </FetchAndRenderMap>
    );
};

export default StaticMap;
