/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    ResidentialListingDataType,
    ResidentialListingLexaData,
    AdaptedProps,
    GetEventTrackerType,
} from './types';
import ResidentialPhoneNumber from './ResidentialPhoneNumber.graphql';

const AVATAR_PLACEHOLDER = 'https://assets.reastatic.net/realestate.com.au/profile-image-placeholder.svg';
const LISTER_PLACEHOLDER_ID = '1';

const PAGE_SOURCE = 'ss sticky call button';

const dataAdapter =
    (callAgentButtonClick: GetEventTrackerType) =>
    ({ listers, listingCompany, viewConfiguration }: ResidentialListingLexaData): AdaptedProps => {
        const realListers = listers ? listers : [];
        let adaptedListers = realListers.map((lister) => {
            const url = lister.photo ? lister.photo.templatedUrl : AVATAR_PLACEHOLDER;
            return {
                id: lister.id,
                name: lister.name,
                templatedUrl: url,
                phoneNumber: lister.phoneNumber ? lister.phoneNumber.display : null,
                callAgentButtonClickTracker: () => callAgentButtonClick(lister.id),
            };
        });
        if (!realListers.length && listingCompany) {
            const defaultLister = {
                id: LISTER_PLACEHOLDER_ID,
                templatedUrl: AVATAR_PLACEHOLDER,
                name: listingCompany && listingCompany.name,
                phoneNumber: listingCompany && listingCompany.businessPhone ? listingCompany.businessPhone : null,
                callAgentButtonClickTracker: () => callAgentButtonClick(),
            };
            adaptedListers = [defaultLister];
        }
        const showDisclaimer = realListers.some((lister) => lister.phoneNumber && lister.phoneNumber.showDisclaimer);
        const showLogo = viewConfiguration.details.agencyBrandingOnCallModal;
        return { listers: adaptedListers, showDisclaimer, showLogo, pageSource: PAGE_SOURCE };
    };

const getAdaptedListingData = (callAgentButtonClick: GetEventTrackerType) =>
    compose(
        dataAdapter(callAgentButtonClick),
        getFragmentData(ResidentialPhoneNumber),
        (data: ResidentialListingDataType) => data.getListing()
    );

export default (data: ResidentialListingDataType) => ({
    ...getAdaptedListingData(data.getEventTracker().callAgentButtonClick)(data),
});
