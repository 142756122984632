/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { ProjectProfileMedia } from '../../../models/lexa';
import type { ProjectDetailsGetters } from '../../../models/lexa/detailsType';
import { type SlideName, SlideNames } from '../../media-viewer';
import { none, option, some } from '../../../util/functional/Option';
import type { MediaIconClickType } from '../../../client/tracking/event/types';
import ProjectMediaTypeBar from './ProjectMediaTypeBar.graphql';

const mediaTypeBarAdapter =
    (openMediaViewer: (name: SlideName) => void) =>
    ({ media }: RequiredData) =>
    (mediaViewerIconClick: (type: MediaIconClickType) => void): AdaptedProps => {
        const { images, threeDimensionalTours, videos } = media;
        const maybeFirstVideo = option(videos[0]);
        const maybeYouTubeVideo = maybeFirstVideo.filter((video) => video.__typename === 'YouTubeVideo');
        const maybeExternalVideoLink = maybeFirstVideo.flatMap((video) =>
            video.__typename === 'ExternalVideo'
                ? some({ href: video.href, action: () => mediaViewerIconClick('video') })
                : none
        );

        return {
            photoCount: images.length,
            openPhoto: images.length > 0 ? () => openMediaViewer(SlideNames.FIRST) : null,
            openYouTubeVideo: maybeYouTubeVideo
                .map(() => () => {
                    openMediaViewer(SlideNames.VIDEO);
                    mediaViewerIconClick('video');
                })
                .getOrElse(null),
            externalVideoLink: maybeExternalVideoLink.getOrElse(null),
            openThreeDimensionalTour:
                threeDimensionalTours.length > 0
                    ? (e: SyntheticEvent<>) => {
                          e.stopPropagation();
                          openMediaViewer(SlideNames.VIRTUAL_TOUR);
                          mediaViewerIconClick('3DTour');
                      }
                    : null,
        };
    };

type RequiredData = {
    media: ProjectProfileMedia,
};

type AdaptedProps = {
    photoCount: number,
    openPhoto: ?() => void,
    openYouTubeVideo: ?() => void,
    externalVideoLink?: ?{
        href: string,
        action: () => void,
    },
    openThreeDimensionalTour: ?(e: SyntheticEvent<>) => void,
};

const getListingData = (openMediaViewer: (name: SlideName) => void) =>
    compose(mediaTypeBarAdapter(openMediaViewer), getFragmentData(ProjectMediaTypeBar), (data: ProjectDetailsGetters) =>
        data.getListing()
    );

export default (openMediaViewer: (name: SlideName) => void) => (data: ProjectDetailsGetters) => {
    const listingData = getListingData(openMediaViewer)(data);
    const mediaViewerIconClick = data.getEventTracker().mediaViewerIconClick;
    return listingData(mediaViewerIconClick);
};
