// @flow

import { fullDetails, partialDetails, type DetailsData } from './detailsData';
import { type DetailsResponse } from '../../models/lexa';
import fetchDataDefault, { type FetchData } from '../../fetchData';
import { hasListing } from './hasListing';
import { type TestParams } from './getTestParams';
import { type UrqlClient, type RequestPolicy, type FetchOptions } from '../../urql/types';

export type FetchDetailsFullArgs = {|
    client: UrqlClient,
    queryVariables: {| ...TestParams, id: string |},
    requestPolicy?: RequestPolicy,
|};

type FetchDetailsFull = (args: FetchDetailsFullArgs) => Promise<DetailsData | void>;

export type FetchDetailsDataArgs = {|
    ...FetchDetailsFullArgs,
    isInitialRender: boolean,
|};

type FetchDetailsData = (args: FetchDetailsDataArgs) => Promise<DetailsData | void>;

type CreateFetchDetailsData = (args: {
    channelQueryVariables: {|
        testId: string,
        queryCacheKey:
            | 'BuyResidentialListingDetails'
            | 'RentResidentialListingDetails'
            | 'SoldResidentialListingDetails'
            | 'ProjectProfileDetails',
    |},
    getListingAboveTheFoldByIdQuery: {},
    getListingByIdQuery: {},
    fetchData?: FetchData,
    fetchOptions?: FetchOptions,
}) => {
    fetchDetailsFull: FetchDetailsFull,
    fetchDetailsData: FetchDetailsData,
};

export const createFetchDetailsData: CreateFetchDetailsData = ({
    channelQueryVariables,
    getListingAboveTheFoldByIdQuery,
    getListingByIdQuery,
    fetchData = fetchDataDefault,
    fetchOptions,
}) => {
    const fetchDetailsFull: FetchDetailsFull = async ({ client, queryVariables, requestPolicy = 'cache-first' }) => {
        const detailsData: DetailsResponse | void = await fetchData({
            client,
            query: getListingByIdQuery,
            queryVariables: {
                ...queryVariables,
                ...channelQueryVariables,
            },
            hasData: hasListing,
            requestPolicy,
            fetchOptions,
        });

        if (detailsData) {
            return fullDetails(detailsData);
        }
    };

    const fetchDetailsData: FetchDetailsData = async ({ client, queryVariables, isInitialRender }) => {
        const requestPolicy = isInitialRender ? 'cache-first' : 'cache-only';
        const fullDetailsData: DetailsData | void = await fetchDetailsFull({
            client,
            queryVariables,
            requestPolicy,
        });

        if (fullDetailsData) {
            return fullDetailsData;
        }

        if (process.env.WEBPACK_TARGET === 'web') {
            const partialDetailsData: DetailsResponse | void = await fetchData({
                client,
                query: getListingAboveTheFoldByIdQuery,
                queryVariables: {
                    ...queryVariables,
                    ...channelQueryVariables,
                },
                hasData: hasListing,
                requestPolicy: 'cache-only',
                fetchOptions,
            });

            if (partialDetailsData) {
                return partialDetails(partialDetailsData);
            }

            const detailsData: DetailsData | void = await fetchDetailsFull({
                client,
                queryVariables,
            });

            if (detailsData) {
                return detailsData;
            }
        }
    };
    return { fetchDetailsFull, fetchDetailsData };
};
