/* @flow */
import React from 'react';
import type { MapMarkers, MapViewTypes } from './mapTypes';
import useGoogleMap from './useGoogleMap';
import './styles.scss';

type Props = {
    markers: MapMarkers,
    mapViewType: MapViewTypes,
};

export default function DynamicMap(props: Props) {
    const { mapViewType, markers } = props;
    const [mapsError, streetViewError, setRef] = useGoogleMap(markers, mapViewType);

    return (
        <div className="dynamic-map__container" ref={setRef}>
            {!!mapsError && (
                <div className="dynamic-map__error" data-testid="DynamicMap__mapsApiError">
                    Sorry, there was a problem displaying this map
                </div>
            )}
            {!!streetViewError && (
                <div className="dynamic-map__error" data-testid="DynamicMap__streetViewError">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Sorry, Street View isn't available for this property
                </div>
            )}
        </div>
    );
}
