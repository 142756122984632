/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import type {
    GetProjectProfile,
    GetEventTracker,
    ProjectProfileListingLinks,
    ProjectProfile,
} from '../../../../models/lexa';
import type { ListingCompany } from '../../../../models/lexa/listingCompanyType';
import type { ProjectProfileViewConfiguration } from '../../../../models/lexa/viewConfigurationType';
import type { AdapterProps } from './types';
import type { Channel } from '../../../../util/ChannelContext';
import getFragmentData from '../../../../util/getFragmentData';
import ProjectProfileContactAgentForm from './ProjectProfileContactAgentForm.graphql';

export type Props = {
    id: string,
    _links: ProjectProfileListingLinks,
    listingCompany: ?ListingCompany,
    viewConfiguration: ProjectProfileViewConfiguration,
};

const dataAdapter =
    (channel: Channel) =>
    ({ id, _links, listingCompany, viewConfiguration }: Props): AdapterProps => ({
        listingId: id,
        url: _links.submitEnquiry.href,
        shouldRenderAgentContactForm: !(listingCompany === null && channel === 'sold'),
        preEnquiryLogin: viewConfiguration.details.enquiryForm.preEnquiryLogin,
    });

export const getTracker = (data: GetEventTracker) => data.getEventTracker().emailAgent;

export default (channel: Channel) =>
    compose<GetProjectProfile, ProjectProfile, Props, AdapterProps>(
        dataAdapter(channel),
        getFragmentData(ProjectProfileContactAgentForm),
        (data) => data.getListing()
    );
