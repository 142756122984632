export const productComponentEventSchemas = {
    gallery: 'iglu:au.com.realestate/gallery/jsonschema/2-0-0',
    full_screen_carousel_view: 'iglu:au.com.realestate/full_screen_carousel_view/jsonschema/2-0-4',
    media_icon_click: 'iglu:au.com.realestate/media_icon_click/jsonschema/1-0-6',
};
type MediaType =
    | 'floorplan'
    | 'photo'
    | 'power profile'
    | 'video'
    | '3dtour'
    | 'photo overlay'
    | 'advertisement'
    | 'photo overlay with gallery'
    | 'agency branding';

type Media = {
    url?: string;
    index: number;
    type: MediaType;
};

type GalleryEvent = {
    event_name: 'gallery';
    user_action?: 'entry' | 'exit' | 'view' | 'jump-anchor';
    source?: {
        page?: string;
        element?: string;
        jump_anchor?: string;
    };
    media?: Media & {
        last_index?: number;
    };
    agents?: {
        agent_id: string;
        agent_profile_id: string;
    }[];
};

type FullScreenCarouselViewEvent = {
    event_name: 'full_screen_carousel_view';
    user_action?: string;
    agency_id?: string | null;
    secondary_agency_ids?: (string | null)[] | null;
    listing_id?: string;
    property_id?: string;
    variation_name?: string;
    media: Media & {
        tags?: string[];
    };
    agents?: {
        agent_id: string;
        agent_profile_id: string;
    }[];
};

type MediaIconClickEvent = {
    event_name: 'media_icon_click';
    listing_id?: string;
    secondary_agency_ids?: (string | null)[] | null;
    agency_id?: string | null;
    type?: 'floorplan' | 'video' | '3DTour' | 'external_link';
    in_page_source?:
        | 'media_viewer_icon'
        | 'page_icon'
        | 'digital_inspections'
        | 'project_profile_page_video_player'
        | 'image_carousel'
        | 'light_box';
    platform?: string;
};

export type ProductComponentEventSchemas = GalleryEvent | FullScreenCarouselViewEvent | MediaIconClickEvent;
