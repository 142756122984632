/* @flow */

import { isRetina } from '../../../client/device-capability';
import { getElementSize } from '../../../util/DOMUtils';

export default (el: any) => {
    const size = getElementSize(el.current);

    const highDPI = isRetina();
    return { ...size, highDPI };
};
