/* @flow */

import {
    transformToExternalBreadcrumbItem,
    transformToInternalBreadcrumbItem,
    getResidentialBreadcrumbItems,
    matchesBuySoldLandingPageURL,
} from '../../../models/breadcrumb';
import { compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import Breadcrumb_ResidentialListing from './Breadcrumb_ResidentialListing.graphql';
import Breadcrumb_BuyResidentialListing from './Breadcrumb_BuyResidentialListing.graphql';
import type { Channel } from '../../../models/Channel';
import type { ExternalBreadcrumbItem, InternalBreadcrumbItem } from '../../breadcrumb/types';
import type { GetBuyResidentialListing } from '../../../models/lexa';

type AdaptedProps = {
    items: Array<InternalBreadcrumbItem | ExternalBreadcrumbItem>,
    showBreadcrumbs: boolean,
};

const getListing = (channel: Channel) =>
    compose(
        getFragmentData(channel === 'buy' ? Breadcrumb_BuyResidentialListing : Breadcrumb_ResidentialListing),
        (data) => data.getListing()
    );

export default (channel: Channel, data: GetBuyResidentialListing): AdaptedProps => {
    const listing = getListing(channel)(data);
    const breadcrumbItems = getResidentialBreadcrumbItems(channel)(listing);
    const items = breadcrumbItems.map((item) =>
        matchesBuySoldLandingPageURL(item.to)
            ? transformToExternalBreadcrumbItem(item)
            : transformToInternalBreadcrumbItem(item)
    );
    const showBreadcrumbs = listing.viewConfiguration.details.showBreadcrumbs;
    return { items, showBreadcrumbs };
};
