import React, { FunctionComponent, useState } from 'react';
import { Step1EnquiryForm } from './step-1-enquiry-form';
import { Step2ContactForm } from './step-2-contact-form';
import { CriteriaForm } from './step-3-criteria-form';
import { Step4Success } from './step-4-success';

type ViewProps = {
    agentNames?: string[];
    projectTitle?: string;
    projectFeatures?: string[];
    listingType: 'residential' | 'project-profile';
};

const STEP_1_ENQUIRY_FORM = 1;
const STEP_2_CONTACT_FORM = 2;
const STEP_3_CRITERIA_FORM = 3;
const STEP_4_SUCCESS = 4;

const View: FunctionComponent<ViewProps> = ({ agentNames, projectTitle, projectFeatures, listingType }) => {
    const [currentStep, setCurrentStep] = useState(STEP_1_ENQUIRY_FORM);

    const getHandleStepNavigation = (currentStep: number, action: 'submit' | 'back') => () => {
        if (action === 'submit') {
            switch (currentStep) {
                case STEP_1_ENQUIRY_FORM:
                    setCurrentStep(STEP_2_CONTACT_FORM);
                    break;
                case STEP_2_CONTACT_FORM:
                    setCurrentStep(STEP_3_CRITERIA_FORM);
                    break;
                case STEP_3_CRITERIA_FORM:
                    setCurrentStep(STEP_4_SUCCESS);
                    break;
                default:
                    break;
            }
        }

        if (action === 'back') {
            switch (currentStep) {
                case STEP_2_CONTACT_FORM:
                    setCurrentStep(STEP_1_ENQUIRY_FORM);
                    break;
                case STEP_3_CRITERIA_FORM:
                    setCurrentStep(STEP_2_CONTACT_FORM);
                    break;
                default:
                    break;
            }
        }
    };

    if (currentStep === STEP_1_ENQUIRY_FORM) {
        return (
            <Step1EnquiryForm
                agentNames={agentNames}
                projectTitle={projectTitle}
                projectFeatures={projectFeatures}
                listingType={listingType}
                onSubmit={getHandleStepNavigation(STEP_1_ENQUIRY_FORM, 'submit')}
            />
        );
    }

    if (currentStep === STEP_2_CONTACT_FORM) {
        return (
            <Step2ContactForm
                listingType={listingType}
                onSubmit={getHandleStepNavigation(STEP_2_CONTACT_FORM, 'submit')}
                onBack={getHandleStepNavigation(STEP_2_CONTACT_FORM, 'back')}
            />
        );
    }

    if (currentStep === STEP_3_CRITERIA_FORM) {
        return (
            <CriteriaForm
                listingType={listingType}
                onSubmit={getHandleStepNavigation(STEP_3_CRITERIA_FORM, 'submit')}
                onBack={getHandleStepNavigation(STEP_3_CRITERIA_FORM, 'back')}
            />
        );
    }

    if (currentStep === STEP_4_SUCCESS) {
        return <Step4Success listingType={listingType} />;
    }

    return null;
};

export default View;
