/* @flow */
import React from 'react';
import styled from 'styled-components';
import type { PageType } from '../../navigation/reducer';
import { PROJECT } from '../../navigation/reducer';
import { back } from '@rea-argonaut/link';
import { ArrowLeftTwoMd, Button, typographyHelper, spacingHelper } from '@rea-group/construct-kit-core';

type Props = {
    fromPage: PageType,
};

const BackButton = styled(Button)`
    ${typographyHelper('caption')};
    margin-right: ${spacingHelper('large')};
    text-wrap: nowrap;

    & svg {
        margin-top: -0.0625rem;
    }
`;

export default ({ fromPage }: Props) => {
    const text = fromPage === PROJECT ? 'View Project' : 'Back to results';

    return (
        <BackButton variant="link-primary" icon={<ArrowLeftTwoMd />} onClick={() => back()}>
            {text}
        </BackButton>
    );
};
