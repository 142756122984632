import React, { FunctionComponent } from 'react';
import { P, H3, ArrowLeftMd, ArrowRightMd } from '@rea-group/construct-kit-core';

import { FormContentContainer, FooterDivider, FooterContainer, FooterButton } from '../styles';

type ViewProps = {
    listingType: 'residential' | 'project-profile';
    onSubmit: () => void;
    onBack: () => void;
};

const View: FunctionComponent<ViewProps> = ({ listingType, onSubmit, onBack }) => {
    return (
        <React.Fragment>
            <FormContentContainer>
                <H3>Title Lorem Ipsum</H3>
                <P>Step: 2</P>
                <P>Listing type: {listingType}</P>
            </FormContentContainer>
            <FooterDivider />
            <FooterContainer>
                <FooterButton variant="outline" icon={<ArrowLeftMd />} iconPlacement="left" onClick={onBack}>
                    Back
                </FooterButton>
                <FooterButton variant="secondary" icon={<ArrowRightMd />} iconPlacement="right" onClick={onSubmit}>
                    Next
                </FooterButton>
            </FooterContainer>
        </React.Fragment>
    );
};

export default View;
