/* @flow */
import HttpClient from '../../../../util/http-client/httpClient';
import { type AgentContactService } from './types';
import { IS_PRODUCTION } from '../../../../config';

const agentContactService: AgentContactService = (url: string, data: Object) => {
    if (!IS_PRODUCTION) {
        return Promise.resolve();
    }

    return HttpClient.post(url).withJsonBody(data).withAuthentication().asPromise();
};

export default agentContactService;
