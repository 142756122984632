/* @flow */
import type { EventMediaData } from '../../client/tracking/event/types.js';
import type { MediaItem } from './View';
import type { GetResidentialListing, GetEventTracker, GetTrackingData, TrackingData } from '../../models/lexa';
import type { Channel } from '../../models/Channel';

import { getEventTrackerData } from './adaptTrackingData';
import { getResidentialListingData as getListingData } from './slides';

export type AdaptedProps = {
    mediaItems: Array<MediaItem>,
};

type ViewProps = {
    eventTracker: (mediaData: EventMediaData) => void,
    trackingData: ?TrackingData,
} & AdaptedProps;

const getTrackingData = (data) => ({ trackingData: data.getTrackingData() });

export type DataType = GetEventTracker & GetResidentialListing & GetTrackingData;

export default (data: DataType, channel: Channel, contactAgentEl: ?Element): ViewProps => ({
    ...getListingData(
        channel,
        contactAgentEl,
        /* eslint-disable no-unused-vars */
        data.getEventTracker().viewEmailAgentForm,
        data.getEventTracker().videoPlayed,
        data.getEventTracker().threeDimensionalTourPlayed
        /* eslint-enable no-unused-vars */
    )(data),
    ...getEventTrackerData(data),
    ...getTrackingData(data),
});
