/* @flow */

import React from 'react';
import type { Node } from 'react';
import Price from '../../../price';
import type { GetResidentialListing } from '../../../../models/lexa';
import styled from 'styled-components';
import { spacingHelper, Text } from '@rea-group/construct-kit-core';
import { TemplatedLazyImage } from '../../../Image';
import placeholderSrc from './placeholder.svg';

type Props = {
    data: GetResidentialListing,
    fullAddress: ?string,
    templatedUrl: string,
    listingAltText: string,
    children?: Node,
};

const PropertyContextWrapper = styled.div`
    padding-bottom: ${spacingHelper('medium')};
    padding-top: ${spacingHelper('medium')};
    display: flex;

    & img {
        width: 90px;
        margin-top: ${spacingHelper('extraSmall')};
        border-radius: var(--ck-radius-medium);

        ${({ theme }) => theme.breakpoints.medium} {
            width: 100px;
        }
    }
`;

const StyledText = styled(Text)`
    margin: 0.5rem 0;
`;

const PropertyInfoWrapper = styled.div`
    display: block;
    padding-left: ${spacingHelper('medium')};
`;

const View = ({ data, fullAddress, templatedUrl, listingAltText = '', children }: Props) => {
    return (
        <PropertyContextWrapper>
            <TemplatedLazyImage
                templatedUrl={templatedUrl}
                size="200x150"
                avif={true}
                alt={listingAltText}
                lazy={true}
                placeholder={placeholderSrc}
            />
            <PropertyInfoWrapper>
                {fullAddress && (
                    <StyledText variant="subtitle01" data-testid="address">
                        {fullAddress}
                    </StyledText>
                )}
                <StyledText variant="subtitle01" data-testid="price">
                    <Price data={data} />
                </StyledText>
                {children}
            </PropertyInfoWrapper>
        </PropertyContextWrapper>
    );
};

export default View;
