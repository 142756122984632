import { trackEvent } from '@rea-argonaut/analytics';
import { GetComponentGetters } from '../../types';
import type { ComponentsFragment } from '../../types/generated/product-components';
import { logger as argonautLogger } from '@rea-argonaut/observability';
import { productComponentEventSchemas } from './client-tracking/productComponentSchemaTypes';

export type ComponentsDetailsData = {
    data: {
        value: ComponentsFragment;
    };
};

export const getComponentGetters = ({ data }: ComponentsDetailsData): GetComponentGetters => {
    const components = data.value.components?.details?.components || null;
    const metadata = data.value.components?.details?.metadata || null;
    return {
        getComponent: (name) => {
            return components ? components[name].component : null;
        },
        getComponentTracker: (jsonSchemaData: string) => {
            return () => {
                try {
                    const schemaData = JSON.parse(jsonSchemaData);
                    trackEvent(() => schemaData);
                } catch (error) {
                    argonautLogger.error({ error, description: `Error parsing jsonSchemaData ${jsonSchemaData}` });
                }
            };
        },
        getClientTracker: (eventName) => {
            return ({ includeMetaData = false, schema }) => {
                const schemaData = { event_name: eventName, ...schema };
                const data = includeMetaData
                    ? {
                          agency_id: metadata?.agencyId,
                          listing_id: metadata?.listingId,
                          ...schemaData,
                      }
                    : schemaData;
                trackEvent(() => ({
                    schema: productComponentEventSchemas[eventName],
                    data,
                }));
            };
        },
    };
};

export default getComponentGetters;
