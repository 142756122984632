/* @flow */
import React, { useEffect, useState, useReducer } from 'react';
import type { Node, Element as ReactElement } from 'react';
import { matchesLayout, isPositionStickySupported, LAYOUT_DEFINITION } from '../../../client/device-capability';
import StickyBrandingContext from '../StickyBrandingContext';
import MediaViewerProvider from '../../media-viewer/provider/MediaViewerProvider';

const STICKY_GAP = 20;
const LARGEST_STICKY_AD = 370;

const initialState = {
    brandingStyles: {
        top: 0,
        className: '',
    },
    sidebarStyles: {
        className: 'layout__sidebar--collapsed',
        primary: {
            top: 0,
            className: '',
        },
        secondary: {
            top: 0,
            className: '',
        },
    },
};

const isLargerScreen = () => matchesLayout(LAYOUT_DEFINITION.L, LAYOUT_DEFINITION.XL);
const shouldEnableStickyBehavior = (stickyContentHeight) =>
    isPositionStickySupported() && isLargerScreen() && stickyContentHeight < window.innerHeight;

export type SharedProps = {|
    subnav: Node,
    hero: Node,
    branding: ReactElement<*>,
    content: Node,
    sidebarPrimary: Node,
    sidebarSecondary?: Array<ReactElement<*>>,
    footer?: Node,
|};

type Props = {|
    ...SharedProps,
    showSidebarMedRec: boolean,
|};

type UseElementOffsetHeightResult = { height: number, storeOffsetHeight: (?HTMLElement) => void };
const useElementOffsetHeight = (): UseElementOffsetHeightResult => {
    const [height, setHeight] = useState(0);
    const storeOffsetHeight = (node) => {
        if (node) {
            setHeight(node.offsetHeight);
        }
    };
    return { height, storeOffsetHeight };
};

const STICKY_ACTION = 'STICKY';
const RESET_ACTION = 'RESET';

const reducer = (state, action) => {
    switch (action.type) {
        case RESET_ACTION:
            return initialState;
        case STICKY_ACTION:
            return {
                brandingStyles: {
                    top: 0,
                    className: 'layout__sticky-item layout__sticky-item--banner',
                },
                sidebarStyles: {
                    className: '',
                    primary: {
                        top: action.offsetHeights.sidebarPrimary,
                        className: 'layout__sticky-item layout__sticky-item--primary',
                    },
                    secondary: {
                        top: action.offsetHeights.sidebarSecondary,
                        className: 'layout__sticky-item',
                    },
                },
            };
        default:
            return state;
    }
};

const DetailsLayout = ({
    subnav,
    hero,
    branding,
    content,
    sidebarPrimary,
    sidebarSecondary,
    footer,
    showSidebarMedRec,
}: Props) => {
    const { height: brandingHeight, storeOffsetHeight: storeBrandingOffsetHeight } = useElementOffsetHeight();
    const { height: sidebarPrimaryHeight, storeOffsetHeight: storeSidebarPrimaryOffsetHeight } =
        useElementOffsetHeight();

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const adsHeight = showSidebarMedRec ? STICKY_GAP + LARGEST_STICKY_AD : 0;
        const stickyContentHeight = brandingHeight + sidebarPrimaryHeight + adsHeight;

        if (shouldEnableStickyBehavior(stickyContentHeight)) {
            dispatch({
                type: STICKY_ACTION,
                offsetHeights: {
                    sidebarPrimary: brandingHeight,
                    sidebarSecondary: brandingHeight + sidebarPrimaryHeight + STICKY_GAP,
                },
            });
        } else {
            dispatch({
                type: RESET_ACTION,
            });
        }
    }, [brandingHeight, showSidebarMedRec, sidebarPrimaryHeight]);

    const { brandingStyles, sidebarStyles } = state;

    return (
        <StickyBrandingContext.Provider value={{ stickyBrandingOffset: state.sidebarStyles.primary.top }}>
            <MediaViewerProvider>
                <div className="details">
                    {subnav}
                    {branding ? (
                        <div
                            ref={storeBrandingOffsetHeight}
                            className={brandingStyles.className}
                            style={{ top: brandingStyles.top }}
                        >
                            {branding}
                        </div>
                    ) : null}
                    <div className="details__wrapper">
                        <div className="details__hero">{hero}</div>
                        <div className="details__main layout">
                            <div className="details__content layout__content">{content}</div>
                            <div
                                className={`layout__sidebar layout__sidebar--large-and-bigger ${sidebarStyles.className}`}
                            >
                                {sidebarPrimary ? (
                                    <div
                                        ref={storeSidebarPrimaryOffsetHeight}
                                        className={`layout__sidebar-primary ${sidebarStyles.primary.className}`}
                                        style={{ top: sidebarStyles.primary.top }}
                                    >
                                        {sidebarPrimary}
                                    </div>
                                ) : null}
                                {React.Children.map(sidebarSecondary, (item) => (
                                    <div className="layout__sidebar-fraction">
                                        <div
                                            className={sidebarStyles.secondary.className}
                                            style={{ top: sidebarStyles.secondary.top }}
                                        >
                                            {item}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="details__footer">{footer}</div>
                </div>
            </MediaViewerProvider>
        </StickyBrandingContext.Provider>
    );
};

export default DetailsLayout;
