/* @flow */

import React from 'react';
import { ReviewsText, AgentRatingContainer, StarRating, Placeholder, AvgRatingText } from './styles';
import type { ViewProps } from './types';

const View = ({ avgRatingStr, reviewsStr }: ViewProps) => {
    if (!avgRatingStr && !reviewsStr) {
        return null;
    }

    return (
        <AgentRatingContainer>
            {avgRatingStr && (
                <React.Fragment>
                    <StarRating />
                    <AvgRatingText>{avgRatingStr}</AvgRatingText>
                </React.Fragment>
            )}
            <ReviewsText>{reviewsStr}</ReviewsText>
        </AgentRatingContainer>
    );
};

export const AgentRatingPlaceholder = () => {
    return (
        <AgentRatingContainer>
            <Placeholder />
        </AgentRatingContainer>
    );
};

export default View;
