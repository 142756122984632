import {
    Card,
    Inline,
    paletteHelper,
    spacingHelper,
    StarFilledMd,
    Text,
    typographyHelper,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { TemplatedLazyImage } from '../../../components/Image';

export const TileContainer = styled(Card)`
    width: 100%;
    border: 1px solid ${paletteHelper('borderSecondary')};
    box-shadow: none;
`;

export const AgencyLink = styled.a`
    text-decoration: none;
`;

export const BrandingStrap = styled(Inline).attrs({
    justifyContent: 'center',
    grow: false,
})<{ colour?: string }>`
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background-color: ${({ colour }) => colour || 'transparent'};
    height: 2rem;

    picture {
        height: 100%;
        padding: ${spacingHelper('extraSmall')};
    }
`;

export const AgencyImg = styled(TemplatedLazyImage)`
    vertical-align: inherit;
    height: 100%;
`;

export const AgencyName = styled(Text).attrs({
    variant: 'subtitle01',
})`
    color: ${paletteHelper('textPrimary')};
`;

export const RatingReviews = styled(Inline).attrs({
    grow: false,
    gap: 'twoExtraSmall',
})`
    ${typographyHelper('body02')}
    color: ${paletteHelper('textSecondary')};
`;

export const StarRating = styled(StarFilledMd)`
    color: ${paletteHelper('graphicYellow')};
`;

export const ArrowContainer = styled.div`
    margin-left: auto;
    width: 1rem;
    height: 1rem;
    color: ${paletteHelper('textPrimary')};
`;
