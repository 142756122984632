import { ReactNode } from 'react';
import adaptLexaData from './adaptLexaData';
import type { ResidentialDetailsGetters } from '../../../../../types';

type Props = {
    data: ResidentialDetailsGetters;
    render: () => ReactNode;
};

export const RenderAgencyBrandingIfRequired = ({ data, render }: Props) => {
    const shouldRender = adaptLexaData(data);
    return shouldRender ? render() : null;
};
