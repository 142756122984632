/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ResidentialListing,
    ResidentialListingViewConfiguration,
} from '../../../models/lexa';
import ShouldRenderAgentRatings from './ShouldRenderAgentRatings.graphql';

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = (listing: RequiredProps): boolean => {
    if (!listing || !listing.viewConfiguration || !listing.viewConfiguration.details) {
        return false;
    }

    return listing.viewConfiguration.details.showAgentRatings;
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, boolean>(
    dataAdapter,
    getFragmentData(ShouldRenderAgentRatings),
    (data) => data.getListing()
);
