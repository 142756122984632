// @flow
import type { Geocode } from '../../../../models/lexa';
import type { MapMarker } from './mapTypes';
import type { ProjectProfile } from '../../../../models/lexa';
import displaySuiteMarkerIcon from '../images/map-pin-display-suite.png';
import salesOfficeMarkerIcon from '../images/map-pin-sales-office.png';
import propertyMarkerIcon from '../images/map-pin-property.png';

export const TEXT_PIN_HEIGHT = 32;

export const createMarker = ({
    geocode,
    markerIcon,
    width,
    height,
}: {
    geocode: Geocode,
    markerIcon: string,
    width: number,
    height: number,
}): MapMarker => {
    const { latitude, longitude } = geocode;
    return {
        coordinates: {
            lat: latitude,
            lng: longitude,
        },
        icon: {
            url: markerIcon,
            width,
            height,
        },
    };
};

export const getDisplayLocationMarker = (projectProfile: ProjectProfile): ?MapMarker => {
    const DISPLAY_LOCATION_PIN_WIDTH = 100;
    const maybeGeocode = projectProfile.displayLocation?.address.display.geocode;
    if (maybeGeocode) {
        const displayLocationMarkerIcon =
            projectProfile.propertyType.id === 'project-apartment' ? displaySuiteMarkerIcon : salesOfficeMarkerIcon;
        return createMarker({
            geocode: maybeGeocode,
            markerIcon: displayLocationMarkerIcon,
            width: DISPLAY_LOCATION_PIN_WIDTH,
            height: TEXT_PIN_HEIGHT,
        });
    }

    return null;
};

export const getPropertyMarker = (maybeGeocode: ?Geocode): ?MapMarker => {
    const PROPERTY_PIN_WIDTH = 72;
    if (maybeGeocode)
        return createMarker({
            geocode: maybeGeocode,
            markerIcon: propertyMarkerIcon,
            width: PROPERTY_PIN_WIDTH,
            height: TEXT_PIN_HEIGHT,
        });

    return null;
};
