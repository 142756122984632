import { GOOGLE_MAPS_CLIENT_ID } from '../../../../config';

const GOOGLE_MAPS_API_VERSION = 3;
const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?v=${GOOGLE_MAPS_API_VERSION}&client=${GOOGLE_MAPS_CLIENT_ID}&libraries=geometry`;
const GOOGLE_MAPS_DEFAULT_CONFIG = {
    zoom: 16,
    mapTypeControl: false,
    streetViewControl: false,
    gestureHandling: 'greedy',
    styles: [
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi.school',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }],
        },
    ],
};

const GOOGLE_STREETVIEW_DEFAULT_CONFIG = { enableCloseButton: false };

export { GOOGLE_MAPS_API_URL, GOOGLE_MAPS_DEFAULT_CONFIG, GOOGLE_STREETVIEW_DEFAULT_CONFIG };
