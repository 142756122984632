/* @flow */

import React from 'react';

type NextInspectionProps = {|
    nextInspection: string,
|};

const View = ({ nextInspection }: NextInspectionProps) => {
    return (
        <div>
            <span>Next open: </span>
            <span>{nextInspection}</span>
        </div>
    );
};

export default View;
