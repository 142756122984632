import { MouseEventHandler, useContext } from 'react';
import { scrollTo } from '../../util/DOMUtils';
import StickyBrandingContext from './StickyBrandingContext';

type Props = {
    afterScroll?: () => void;
    elementRef: () => Element | null;
    children: (scrollOnClick: MouseEventHandler) => React.ReactNode;
};

const ScrollToElement = ({ afterScroll = () => {}, elementRef, children }: Props) => {
    const { stickyBrandingOffset } = useContext(StickyBrandingContext);
    const scrollOnClick: MouseEventHandler = (e) => {
        e.preventDefault();
        const scrollToElement = elementRef();
        if (scrollToElement) scrollTo(scrollToElement, stickyBrandingOffset);
        afterScroll();
    };
    return children(scrollOnClick);
};

export default ScrollToElement;
