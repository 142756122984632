/* @flow */

export const errorMessagesTemplate = {
    required: 'Please fill in this field.',
    containsDots: 'Please remove any URLs from this message.',
    emailInvalid: 'Please provide a valid email address.',
    phoneInvalid: {
        insufficent: 'Phone number should be a minimum of 8 digits.',
        format: 'Please provide a valid phone number.',
    },
    postcodeInvalid: 'That doesn’t look right. Must be 4 digits.',
};

export const isBlank = (s: string) => s.trim() === '';

export const isSufficientNumber = (s: string) => s.replace(/\D/g, '').length >= 8;

export const isValidPhone = (s: string) => /^[+]?[\s\d]*[(]?\d+[)]?[-\s\d]+$/.test(s);

export const isValidPostcode = (postcode: string) => /^\d{4}$/.test(postcode);

export const containsBadUrl = (s: string) => {
    const sWithoutReaUrls = s.replace(/([\w.]*\.)?(realestate|realcommercial)\.com\.au/, '');
    const urlPattern = /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\\w .-]+)*\/?/;
    return urlPattern.test(sWithoutReaUrls);
};

export const isValidEmail = (s: string) => /@.*\./.test(s);

export const validateName = (name: string) =>
    isBlank(name) ? errorMessagesTemplate.required : containsBadUrl(name) ? errorMessagesTemplate.containsDots : '';
export const validatePhoneNumber = (s: string) =>
    isBlank(s)
        ? ''
        : !isValidPhone(s)
          ? errorMessagesTemplate.phoneInvalid.format
          : !isSufficientNumber(s)
            ? errorMessagesTemplate.phoneInvalid.insufficent
            : '';

export const validateEmail = (email: string) =>
    isBlank(email) ? errorMessagesTemplate.required : !isValidEmail(email) ? errorMessagesTemplate.emailInvalid : '';

export const validatePostcode = (postcode: string) =>
    isBlank(postcode) ? '' : !isValidPostcode(postcode.trim()) ? errorMessagesTemplate.postcodeInvalid : '';
