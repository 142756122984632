/* @flow */

import getFragmentData from '../../../util/getFragmentData';
import { compose3 as compose } from '../../../util/functional/compose';
import AgentRatings from './AgentRatings.graphql';
import type { ViewProps, AgentRatingsData, DataType } from './types';

const getReviewsStr = (totalReviews) => {
    if (totalReviews === 0) {
        return '(No reviews yet)';
    } else if (totalReviews === 1) {
        return '(1 review)';
    } else if (totalReviews < 500) {
        return `(${totalReviews} reviews)`;
    } else {
        return '(500+ reviews)';
    }
};
const dataAdapter =
    (listerId: string) =>
    ({ listers }: AgentRatingsData): ViewProps => {
        const lister = (listers || []).find((l) => l.id === listerId);

        if (!lister || !lister.listerRatingsReviews) {
            return { reviewsStr: null, avgRatingStr: null };
        }
        const { totalReviews, avgRating } = lister.listerRatingsReviews;

        const avgRatingStr = avgRating === null ? null : avgRating.toFixed(1);

        const reviewsStr = getReviewsStr(totalReviews);

        return { reviewsStr, avgRatingStr };
    };

const getAdaptedAgentRatingsData = (listerId: string) =>
    compose(dataAdapter(listerId), getFragmentData(AgentRatings), (data: DataType) => data.getListing());

export default (listerId: string) => (data: DataType) => ({
    ...getAdaptedAgentRatingsData(listerId)(data),
});
