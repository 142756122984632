/* @flow */
import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import createAdSlideAddon from './createAdSlideAddon';
import type {
    TrackingData,
    ResidentialListingViewConfiguration,
    GetResidentialListing,
    GetTrackingData,
} from '../../../../models/lexa';
import DetailsAdConfiguration from './DetailsAdConfiguration.graphql';

type RequireData = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

type DataType = GetResidentialListing & GetTrackingData;

const dataAdapter =
    (trackingData: ?TrackingData) =>
    ({ viewConfiguration }: RequireData): ?Object => {
        const showSlideAddon =
            viewConfiguration &&
            viewConfiguration.details &&
            viewConfiguration.details.adverts &&
            viewConfiguration.details.adverts.photoGallery;
        if (!showSlideAddon || !trackingData) {
            return;
        }
        return createAdSlideAddon(trackingData);
    };

const getAddOnData = (trackingData) =>
    compose(dataAdapter(trackingData), getFragmentData(DetailsAdConfiguration), (data) => data.getListing());

export default (data: DataType) => getAddOnData(data.getTrackingData())(data);
