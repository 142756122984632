import React, { useContext, type ReactNode, FunctionComponent } from 'react';
import { useFeatureRollout } from '@rea-argonaut/experiment';
// TODO: Will remove once legacy component can be dropped
import './styles.scss';

import ChannelContext, { Channel } from '../../../util/ChannelContext';
import { GetListing } from '../../../../types';

import { ContactAgentForm } from './contact-agent-form';
import { Layout, LayoutContent, ContactAgentSection, LayoutSidebar, ResponsiveCard } from './styles';

type LayoutProps = {
    data: GetListing;
    setContactAgentEl: (e?: Element | null) => void;
    renderBranding: (args: { className: string; cid: string }) => ReactNode;
    contactAgentForm: ReactNode;
    sidebar?: ReactNode;
    listingMetrics?: ReactNode;
    listingType: 'residential' | 'project-profile';
};

const getBrandingCid = (channel: Channel) =>
    `agency-profile-page|${channel}:pdp:contact-form&sourcePage=${channel}:property-details&sourceElement=agency-branding`;

export const View: FunctionComponent<LayoutProps> = ({
    data,
    setContactAgentEl,
    renderBranding,
    contactAgentForm,
    listingMetrics,
    sidebar,
    listingType,
}) => {
    const channel = useContext(ChannelContext);
    const { isEnabled } = useFeatureRollout('all-web-pdp-enquiry-form-re-imagined');
    if (isEnabled()) {
        // IMPORTANT: contact-agent-section class name is what ad kit relies on to mount ad, do not remove
        return (
            <ContactAgentSection className="contact-agent-section" ref={setContactAgentEl}>
                <Layout>
                    <LayoutContent>
                        <ResponsiveCard data-testid="contact-agent-form">
                            {renderBranding({
                                className: 'contact-agent-section__branding',
                                cid: getBrandingCid(channel),
                            })}
                            <ContactAgentForm data={data} listingType={listingType} />
                        </ResponsiveCard>
                        {listingMetrics}
                    </LayoutContent>
                    <LayoutSidebar>{sidebar}</LayoutSidebar>
                </Layout>
            </ContactAgentSection>
        );
    }
    return (
        <div className="contact-agent-section" ref={setContactAgentEl}>
            <div className="layout layout--no-mobile-gutters">
                <div className="layout__content">
                    <ResponsiveCard data-testid="contact-agent-form-old">
                        {renderBranding({
                            className: 'contact-agent-section__branding',
                            cid: getBrandingCid(channel),
                        })}
                        <div className="contact-agent-section__form">{contactAgentForm}</div>
                    </ResponsiveCard>
                    {listingMetrics}
                </div>
                <div className="layout__sidebar layout__sidebar--large-and-bigger">{sidebar}</div>
            </div>
        </div>
    );
};
