import { Button, Card, paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

export const ProfileCard = styled(Card)`
    background-color: ${paletteHelper('backgroundSecondaryBase')};
    box-shadow: none;
    padding: ${spacingHelper('large large extraLarge')};
    display: flex;
    position: relative;
    border-radius: ${spacingHelper('large')};
`;

export const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const EditButton = styled(Button)`
    border-radius: 100%;
    border: none;
    background-color: ${paletteHelper('backgroundPrimary')};
    height: ${spacingHelper('extraLarge')};
    width: ${spacingHelper('extraLarge')};
    position: absolute;
    right: ${spacingHelper('large')};
`;
