/* @flow */
import React from 'react';
import { Link } from '@rea-group/construct-kit-core';

export default () => (
    <p>
        Your personal information and associated behavioural data related to search activities will be passed to the
        Agency and/or its authorised service provider to assist the Agency to contact you about your property enquiry.
        They are required not to use your information for any other purpose. Our{' '}
        <Link
            variant="inline"
            href="https://about.realestate.com.au/privacy/"
            rel="noopener noreferrer"
            target="_blank"
        >
            Privacy policy
        </Link>{' '}
        explains how we store personal information and how you may access, correct or complain about the handling of
        personal information.
    </p>
);
