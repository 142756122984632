/* @flow */
import { adapter as adaptVideos } from './video';
import type { GetProjectProfile, ProjectProfileMedia, ProjectProfile } from '../../../models/lexa';
import type { AdaptedProps } from '../adaptResidentialListingData';
import { toPhotoItem } from '../toMediaViewerFormat';
import getFragmentData from '../../../util/getFragmentData';
import { compose3 as compose } from '../../../util/functional/compose';
import type { VideoPlayedPageSource } from '../../../client/tracking/event/types';
import ProjectMediaViewer from './ProjectMediaViewer.graphql';
import { adapter as adaptThreeDimensionalTours } from './3d-tour';

type RequiredData = {
    media: ProjectProfileMedia,
};

const dataAdapter =
    (trackVideoPlayed: (inPageSource: VideoPlayedPageSource) => void, trackThreeDimensionalTourPlayed: () => void) =>
    ({ media }: RequiredData): AdaptedProps => {
        const [mainImage, ...otherImages] = media.images.map(toPhotoItem);
        const threeDimensionalTourItems = adaptThreeDimensionalTours({ media, trackThreeDimensionalTourPlayed });
        const mediaItems = [
            mainImage,
            ...adaptVideos({ media, trackVideoPlayed }),
            ...threeDimensionalTourItems,
            ...otherImages,
        ];

        return { mediaItems };
    };

export default (
    trackVideoPlayed: (inPageSource: VideoPlayedPageSource) => void,
    trackThreeDimensionalTourPlayed: () => void
) =>
    compose<GetProjectProfile, ProjectProfile, RequiredData, AdaptedProps>(
        dataAdapter(trackVideoPlayed, trackThreeDimensionalTourPlayed),
        getFragmentData(ProjectMediaViewer),
        (data: GetProjectProfile) => data.getListing()
    );
