/* @flow */
import React from 'react';
import { default as adaptResidentialListingData } from './adaptResidentialListingData';
import { default as adaptProjectProfileData } from './adaptProjectProfileData';
import { default as View } from './View';
import type {
    GetResidentialListing,
    GetProjectProfile,
    GetTrackingData,
    GetEventTracker,
} from '../../../../models/lexa';

type ResidentialListingProps = {
    data: GetResidentialListing & GetTrackingData & GetEventTracker,
    consumerName: string,
    onClose: () => void,
    isUserSignedIn?: boolean,
    consumerEmail: string,
    consumerPhone: string,
    preApproval: string,
    onJoinOrSignInSuccess: () => void,
    inspectionTimesEl?: () => ?Element,
};

type ProjectProfileProps = {
    data: GetProjectProfile & GetTrackingData & GetEventTracker,
    consumerName: string,
    onClose: () => void,
    isUserSignedIn?: boolean,
    consumerEmail: string,
    consumerPhone: string,
    preApproval: string,
    onJoinOrSignInSuccess: () => void,
};

export const ResidentialListingConfirmationModal = ({
    data,
    consumerName,
    onClose,
    isUserSignedIn,
    consumerEmail,
    consumerPhone,
    preApproval,
    onJoinOrSignInSuccess,
}: ResidentialListingProps) => {
    const residentialData = adaptResidentialListingData(data);
    return residentialData ? (
        <View
            {...residentialData}
            data={data}
            consumerName={consumerName}
            consumerEmail={consumerEmail}
            consumerPhone={consumerPhone}
            preApproval={preApproval}
            onClose={onClose}
            isUserSignedIn={isUserSignedIn}
            onJoinOrSignInSuccess={onJoinOrSignInSuccess}
        />
    ) : null;
};

export const ProjectProfileConfirmationModal = ({
    data,
    consumerName,
    onClose,
    isUserSignedIn,
    consumerEmail,
    consumerPhone,
    preApproval,
    onJoinOrSignInSuccess,
}: ProjectProfileProps) => {
    const adaptedData = adaptProjectProfileData(data);
    return adaptedData ? (
        <View
            {...adaptedData}
            data={data}
            consumerName={consumerName}
            consumerEmail={consumerEmail}
            consumerPhone={consumerPhone}
            preApproval={preApproval}
            onClose={onClose}
            isUserSignedIn={isUserSignedIn}
            onJoinOrSignInSuccess={onJoinOrSignInSuccess}
        />
    ) : null;
};
