import React, { FunctionComponent } from 'react';
import { P, H3 } from '@rea-group/construct-kit-core';

import { FormContentContainer, FooterDivider, FooterContainer, FooterButton } from '../styles';

type ViewProps = {
    agentNames?: string[];
    projectTitle?: string;
    projectFeatures?: string[];
    listingType: 'residential' | 'project-profile';
    onSubmit: () => void;
};

const View: FunctionComponent<ViewProps> = ({ agentNames, projectTitle, projectFeatures, listingType, onSubmit }) => {
    return (
        <React.Fragment>
            <FormContentContainer>
                <H3>Title Lorem Ipsum</H3>
                <P>Step: 1</P>
                <P>Listing type: {listingType}</P>
                {listingType === 'residential' && <P>Agent name(s): {(agentNames || []).slice(0, 2).join(' & ')}</P>}
                {listingType === 'project-profile' && <P>Project title: {projectTitle || ''}</P>}
                {listingType === 'project-profile' && <P>Project features: {projectFeatures?.join(', ')}</P>}
            </FormContentContainer>
            <FooterDivider />
            <FooterContainer>
                <FooterButton fullWidth={true} variant="primary" onClick={onSubmit}>
                    Continue
                </FooterButton>
            </FooterContainer>
        </React.Fragment>
    );
};

export default View;
