/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import type { GetProjectProfile, ProjectProfile } from '../../../../models/lexa';
import getFragmentData from '../../../../util/getFragmentData';
import ProjectName from './ProjectName.graphql';
import type { EnquiryTitleData } from './types';

type RequiredProps = {
    name: string,
};

const dataAdapter = ({ name }: RequiredProps): EnquiryTitleData => {
    return { title: `Get in touch with a consultant to discuss ${name} today.` };
};

export default compose<GetProjectProfile, ProjectProfile, RequiredProps, EnquiryTitleData>(
    dataAdapter,
    getFragmentData(ProjectName),
    (data) => data.getListing()
);
