import { useEffect, useState } from 'react';

export const useIntersection = (element: React.RefObject<HTMLDivElement>, options?: IntersectionObserverInit) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const current = element?.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { ...options }
        );
        if (current) {
            observer.observe(current);
        }

        return () => {
            if (current) {
                observer.unobserve(current);
            }
        };
    }, [element, options]);

    return isVisible;
};
