/* @flow */
import React, { Component, type Node } from 'react';
import { enquiredService } from '../../../services/my-items';
import type { ComponentType } from 'react';
import type { GetResidentialListing, GetProjectProfile, GetTrackingData, GetEventTracker } from '../../../models/lexa';
import type { PrefillData } from './contact-agent-form/enquiry-prefill';
import { save as savePrefillData } from './contact-agent-form/enquiry-prefill';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import { createBrowserUser } from '@realestate.com.au/user';
import { connect } from 'react-redux';
import type { OnSuccess } from './contact-agent-form/types';

type OwnProps = {|
    data: (GetResidentialListing | GetProjectProfile) & GetTrackingData & GetEventTracker,
    contactAgentForm: ComponentType<*>,
    renderConfirmationModal: ({
        onClose: () => void,
        consumerName: string,
        consumerEmail: string,
        consumerPhone: string,
        preApproval: string,
        onJoinOrSignInSuccess: () => void,
    }) => Node,
|};

type Props = {
    ...OwnProps,
    prefillData: PrefillData,
    savePrefillData: (data: PrefillData) => void,
};

type State = {
    showConfirmationModal: boolean,
    consumerName: string,
    isUserSignedIn: boolean,
    consumerEmail: string,
    consumerPhone: string,
    preApproval: string,
    savePrefillDataAfterLogin: () => void,
    resetFormData: () => void,
};

class ContactAgentFormContainer extends Component<Props, State> {
    state = {
        showConfirmationModal: false,
        consumerName: '',
        isUserSignedIn: false,
        consumerEmail: '',
        consumerPhone: '',
        preApproval: '',
        savePrefillDataAfterLogin: () => {},
        resetFormData: () => {},
    };

    showModal = (
        consumerName: string,
        isUserSignedIn: boolean,
        consumerEmail?: string,
        consumerPhone?: string,
        preApproval?: string
    ) => {
        this.setState({
            showConfirmationModal: true,
            consumerName: consumerName,
            isUserSignedIn: isUserSignedIn,
            consumerEmail: consumerEmail,
            consumerPhone: consumerPhone,
            preApproval: preApproval,
        });
    };

    closeModal = () => {
        !this.state.isUserSignedIn && this.state.resetFormData();
        this.setState({ showConfirmationModal: false });
    };

    onSuccess: OnSuccess = ({
        listingId,
        consumerName,
        isUserSignedIn,
        email,
        mobile,
        preapproval,
        resetFormData,
        savePrefillDataAfterLogin,
    }) => {
        this.showModal(consumerName, isUserSignedIn, email, mobile, preapproval);
        this.setState({ savePrefillDataAfterLogin, resetFormData });
        if (createBrowserUser().isSignedIn) {
            const service = enquiredService();
            service.add(listingId);
        }
    };

    render() {
        const { data, renderConfirmationModal, prefillData, savePrefillData } = this.props;
        const {
            showConfirmationModal,
            consumerName,
            isUserSignedIn,
            consumerEmail,
            consumerPhone,
            preApproval,
            savePrefillDataAfterLogin,
        } = this.state;
        const ContactAgentForm = this.props.contactAgentForm;
        const onJoinOrSignInSuccess = () => {
            savePrefillDataAfterLogin();
            this.setState({ showConfirmationModal: false });
        };

        return (
            <ErrorBoundaryNewRelicReporter component="ContactAgentFormContainer">
                <div>
                    <ContactAgentForm
                        data={data}
                        onSuccess={this.onSuccess}
                        prefillData={prefillData}
                        savePrefillData={savePrefillData}
                    />
                    {showConfirmationModal && (
                        <ErrorBoundaryNewRelicReporter component="ContactAgentFormConfirmationModal">
                            {renderConfirmationModal({
                                onClose: this.closeModal,
                                consumerName: consumerName,
                                consumerEmail: consumerEmail,
                                consumerPhone: consumerPhone,
                                isUserSignedIn: isUserSignedIn,
                                preApproval: preApproval,
                                onJoinOrSignInSuccess,
                            })}
                        </ErrorBoundaryNewRelicReporter>
                    )}
                </div>
            </ErrorBoundaryNewRelicReporter>
        );
    }
}

const mapStateToProps = (state) => ({
    prefillData: state.prefillData,
});

const mapDispatchToProps = (dispatch) => ({
    savePrefillData: (data) => dispatch(savePrefillData(data)),
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ContactAgentFormContainer);
