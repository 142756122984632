// @flow
import { useCallback, useEffect, useState } from 'react';
import { GOOGLE_MAPS_DEFAULT_CONFIG, GOOGLE_STREETVIEW_DEFAULT_CONFIG } from './config';
import type { MapMarkers, MapViewTypes } from './mapTypes';
import { MAP, SATELLITE, STREET_VIEW } from './mapTypes';
import useAsyncGoogleMap from './useAsyncGoogleMap';

export default function useGoogleMap(
    markers: MapMarkers,
    mapType: MapViewTypes,
    config: Object = GOOGLE_MAPS_DEFAULT_CONFIG
) {
    const [ref, setRef] = useState<?HTMLElement>(null);
    const [map, error] = useAsyncGoogleMap(ref, config);
    const [streetViewErr, setStreetViewErr] = useState<boolean>(false);
    const refHandler = useCallback(
        (el: ?HTMLElement) => {
            setRef(el);
        },
        [setRef]
    );

    useEffect(() => {
        if (map) {
            return showMarkersOnMap(markers, map, config.zoom);
        }
    }, [config.zoom, map, markers]);

    useEffect(() => {
        if (map && map.getMapTypeId() !== mapType) {
            const { MapTypeId } = window.google.maps;
            if (streetViewErr && mapType !== STREET_VIEW) {
                setStreetViewErr(false);
            }
            switch (mapType) {
                case STREET_VIEW: {
                    const location = markers[0].coordinates;
                    showLocationInStreetView(location, map, streetViewErr, setStreetViewErr);
                    map.setMapTypeId(MapTypeId.STREET_VIEW);
                    return () => {
                        const streetView = map.getStreetView();
                        streetView && streetView.setVisible(false);
                    };
                }
                case SATELLITE: {
                    map.setMapTypeId(MapTypeId.SATELLITE);
                    return;
                }
                case MAP:
                default: {
                    map.setMapTypeId(MapTypeId.ROADMAP);
                }
            }
        }
    }, [map, mapType, markers, setStreetViewErr, streetViewErr]);

    return [error, streetViewErr, refHandler];
}

function findMaxDelta(markers = []): number {
    let distances = [];

    markers.forEach((a) => {
        markers.forEach((b) =>
            distances.push(window.google.maps.geometry.spherical.computeDistanceBetween(a.position, b.position))
        );
    });

    return distances.reduce((max, n) => (n > max ? n : max));
}

function showMarkersOnMap(markers, map, maxZoom) {
    const bounds = new window.google.maps.LatLngBounds();
    const markerInstances = markers.map((marker) => {
        const instance = new window.google.maps.Marker({
            map,
            position: marker.coordinates,
            icon: {
                url: marker.icon.url,
                scaledSize: new window.google.maps.Size(marker.icon.width, marker.icon.height),
            },
        });
        bounds.extend(instance.getPosition());
        return instance;
    });
    map.setCenter(bounds.getCenter());

    const delta = findMaxDelta(markerInstances);
    // 700 is a magic number denoting the max acceptable marker distance
    if (delta > 700) {
        map.fitBounds(bounds);
    } else {
        map.setZoom(maxZoom);
    }

    return () => {
        markerInstances.forEach((marker) => marker.setMap(null));
    };
}

function showLocationInStreetView(location, map, streetViewErr, setStreetViewErr) {
    const { event, geometry, LatLng, StreetViewService } = window.google.maps;
    const streetViewService = new StreetViewService();

    // load the images for the panorama...
    streetViewService.getPanorama({ location, source: 'outdoor' }, (data, status) => {
        if (status !== 'OK' && !streetViewErr) {
            setStreetViewErr(true);
        } else {
            // get the streetview instance.
            const streetView = map.getStreetView();
            streetView.setOptions(GOOGLE_STREETVIEW_DEFAULT_CONFIG);
            const setPov = () => {
                // set the heading to look at the location,
                // instead of looking down the street.
                streetView.setPov({
                    heading: geometry.spherical.computeHeading(streetView.getPosition(), new LatLng(location)),
                    pitch: 0,
                });
            };

            const panoSet = streetView.getPano() === data.location.pano;
            if (!panoSet) {
                event.addListenerOnce(streetView, 'position_changed', setPov);
                streetView.setPano(data.location.pano);
            } else {
                setPov();
            }
            streetView.setVisible(true);
        }
    });
}
