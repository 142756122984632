/* @flow */
import { option } from '../../../../util/functional/Option';
import { toPhotoOverlayWithGalleryItem } from './toPhotoOverlayWithGalleryItem';
import type {
    BuyResidentialListing,
    ProjectProfile,
    ResidentialListingMedia,
    GeneralFeatures,
} from '../../../../models/lexa';
import type { PhotoOverlayGalleryItem } from './index';
import createPicture from '../../../../models/Picture';
import { default as mapPropertyType } from '../../../property-size/mapType';

type Props = {
    id: string,
    parent: ProjectProfile,
    media: ResidentialListingMedia,
};

type GeneralFeature = {
    value: number,
};

const convertPhotoOverlayWithGalleryData = (id: string, backgroundImage: string, project: ProjectProfile) => {
    const { totalCount, results } = project.childListings;
    const numberOfProperties = totalCount > 1 ? `${totalCount} properties` : 'properties';
    const cid = 'pppdp|buy:pdp:mediaviewer-overview';
    return {
        heading: project.name,
        templatedUrl: backgroundImage,
        subHeading: `Explore ${numberOfProperties} in ${project.name}`,
        href: `${project._links.canonical.path}?cid=${cid}`,
        title: 'View more properties',
        gallery: results ? convertGalleryData(id, results) : [],
    };
};

const convertGalleryData = (id: string, galleryItems: Array<BuyResidentialListing>) => {
    const cid = 'pdp|buy:pdp:large-mediaviewer';
    return galleryItems
        .filter((child) => child.id !== id)
        .slice(0, 3)
        .map((child) => {
            return {
                url: `${child._links.canonical.path}?cid=${cid}`,
                image: createPicture(child.media.mainImage.templatedUrl).ofSize('310x175'),
                heading: child.price.display,
                subheading: child.title,
                description: child.propertyType.display,
                generalFeatures: convertGeneralFeatures(child.generalFeatures),
                propertySize: convertPropertySize(child),
            };
        });
};

const convertGeneralFeatures = (generalFeatures: ?GeneralFeatures) => {
    if (!generalFeatures) return null;

    const { bedrooms, bathrooms, parkingSpaces } = generalFeatures;

    return {
        bedrooms: convertFeature(bedrooms, 'bedroom'),
        bathrooms: convertFeature(bathrooms, 'bathroom'),
        parkingSpaces: convertFeature(parkingSpaces, 'parking space'),
    };
};

const convertFeature = (feature: GeneralFeature, label: string) => {
    if (feature.value <= 0) return null;

    const generateAccessibilityLabel = (value, label) => `${value} ${label}${value !== 1 ? 's' : ''}`;

    return {
        display: feature.value.toString(),
        label: generateAccessibilityLabel(feature.value, label),
    };
};

const convertPropertySize = ({ propertySizes }: BuyResidentialListing) => {
    return option(propertySizes.preferred)
        .map((preferredSize) => ({
            display: `${preferredSize.size.displayValue}${preferredSize.size.sizeUnit.displayValue}`,
            type: mapPropertyType(preferredSize.sizeType),
        }))
        .getOrElse(null);
};

export default ({ id, media, parent }: Props): Array<PhotoOverlayGalleryItem> => {
    const backgroundImage = media.mainImage.templatedUrl;
    const photoOverlayGalleryData = convertPhotoOverlayWithGalleryData(id, backgroundImage, parent);
    return [toPhotoOverlayWithGalleryItem(photoOverlayGalleryData)];
};
