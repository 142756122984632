import React, { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { Button, Link } from '@rea-group/construct-kit-core';

import ScrollableGetInTouchButton from '../../components/details/scrollable-get-in-touch-button';
import { useBoolean } from '../../hooks';

import type { RenderApplyOnlineButton, RenderCallAgentModal, AdaptedProps } from './types';
import { StickyCTA, StickyCTAConstrainer, buttonContainerClass } from './styles';

type Props = AdaptedProps & {
    contactAgentEl: () => Element | null;
    renderCallAgentModal: RenderCallAgentModal;
    renderApplyOnlineButton: RenderApplyOnlineButton;
};

const MIN_WINDOW_HEIGHT = 475;

export default function View({ actions, contactAgentEl, renderCallAgentModal, renderApplyOnlineButton }: Props) {
    const [isHidden, setIsHidden] = useState(true);
    const [isOpen, open, close] = useBoolean(false);

    useEffect(() => {
        const onScroll = () => {
            const el = contactAgentEl();
            if (el) {
                const rect = el.getBoundingClientRect();
                setIsHidden(rect.top < window.innerHeight);
            }
        };
        const isViewportTallEnough = () => window.innerHeight > MIN_WINDOW_HEIGHT;
        if (isViewportTallEnough()) {
            const throttledOnScroll = throttle(onScroll, 200);
            window.addEventListener('scroll', throttledOnScroll, { passive: true });
            setIsHidden(false);
            return () => window.removeEventListener('scroll', throttledOnScroll);
        }
        return () => {};
    }, [contactAgentEl]);

    if (!actions.length || actions.length > 2) {
        return null;
    }

    return (
        <StickyCTA $isHidden={isHidden} data-testid="sticky-cta">
            <StickyCTAConstrainer>
                {actions.map((action) => {
                    if (action.__typename === 'CallActionButton') {
                        return (
                            <div className={buttonContainerClass} role="presentation" key={action.__typename}>
                                <Button
                                    aria-label={'Select an agent to ' + action.title}
                                    onClick={() => {
                                        action.trackEvent();
                                        open();
                                    }}
                                    fullWidth={true}
                                >
                                    {action.title}
                                </Button>
                            </div>
                        );
                    }
                    if (action.__typename === 'EnquiryActionButton') {
                        return (
                            <ScrollableGetInTouchButton
                                contactAgentEl={contactAgentEl}
                                label={action.title}
                                className={buttonContainerClass}
                                viewEmailAgentTracker={action.trackEvent}
                                key={action.__typename}
                            />
                        );
                    }

                    if (action.__typename === 'ApplyActionButton') {
                        return (
                            <div key={action.__typename} className={buttonContainerClass}>
                                {renderApplyOnlineButton(action.url, action.trackEvent, action.title, 'primary')}
                            </div>
                        );
                    }

                    if (action.__typename === 'RequestAppraisalActionButton') {
                        return (
                            <div className={buttonContainerClass} key={action.__typename}>
                                <Link
                                    href={action.url}
                                    rel="noopener nofollow"
                                    fullWidth={true}
                                    variant="button-primary"
                                >
                                    {action.title}
                                </Link>
                            </div>
                        );
                    }

                    return null;
                })}

                {isOpen && renderCallAgentModal(isOpen, close)}
            </StickyCTAConstrainer>
        </StickyCTA>
    );
}
