import React from 'react';
import View from './View';
import { ButtonVariant } from '@rea-group/construct-kit-core';

type Props = {
    contactAgentEl: () => Element | null;
    onClick?: () => void;
    className?: string;
    label?: string;
    variant?: ButtonVariant;
    viewEmailAgentTracker: () => void;
};

const ViewWithEmailTracker = ({
    contactAgentEl,
    onClick,
    className,
    label,
    variant = 'primary',
    viewEmailAgentTracker,
}: Props) => {
    return (
        <View
            contactAgentEl={contactAgentEl}
            onClick={onClick}
            label={label}
            className={className}
            viewEmailAgentTracker={viewEmailAgentTracker}
            variant={variant}
        />
    );
};

export default ViewWithEmailTracker;
