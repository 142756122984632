/* @flow */

import PropertyContext from './PropertyContext.graphql';
import getFragmentData from '../../../../util/getFragmentData';
import { compose3 as compose } from '../../../../util/functional/compose';
import type {
    Address,
    ResidentialListing,
    GetResidentialListing,
    ResidentialListingMedia,
} from '../../../../models/lexa';

type RequiredProps = {
    address: Address,
    media: ResidentialListingMedia,
    title: string,
};

type Response = {
    fullAddress: ?string,
    templatedUrl: string,
    listingAltText: string,
};

const dataAdapter = ({ address, media, title }: RequiredProps): Response => {
    return {
        fullAddress: address.display.fullAddress,
        templatedUrl: media.mainImage.templatedUrl,
        listingAltText: title,
    };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, Response>(
    dataAdapter,
    getFragmentData(PropertyContext),
    (data) => data.getListing()
);
