/* @flow */
import React from 'react';
import { default as adaptResidentialListingData } from './adaptResidentialListingData';
import { default as adaptProjectProfileData } from './adaptProjectProfileData';
import type { GetResidentialListing, GetProjectProfile } from '../../../models/lexa';
import type { Geocode } from '../../../models/lexa';
import type { NearbySchoolTracker } from './types';
import Service from './service';
import BrowserOnly from '../../browser-only';

type DataAdapter<D> = (data: D) => ?Geocode;
type NearbySchoolWithDataProps = {
    geoLocation: Geocode,
    trackInteraction: NearbySchoolTracker,
};

const View = React.lazy(() => {
    return import(/* webpackChunkName: "nearby-schools" */ './View');
});

const ViewWithData = ({ geoLocation, trackInteraction }: NearbySchoolWithDataProps) => {
    return (
        <Service geoLocation={geoLocation} count={5}>
            {({ data, progress }) => {
                if (progress === 'LOADING') return <div>Loading...</div>;
                if (data && data.length) return <View trackInteraction={trackInteraction} data={data} />;
                return null;
            }}
        </Service>
    );
};

function Schools({ adapter, data }: Object) {
    const geoLocation = adapter(data);
    const trackInteraction = data.getEventTracker().nearbySchoolsInteraction;
    return geoLocation ? <ViewWithData geoLocation={geoLocation} trackInteraction={trackInteraction} /> : null;
}

function createComponent<D>(adapter: DataAdapter<D>) {
    return function BrowserOnlySchools({ data }: Object) {
        return (
            <BrowserOnly name="Schools">
                <Schools adapter={adapter} data={data} />
            </BrowserOnly>
        );
    };
}

export const ResidentialListingSchools = createComponent<GetResidentialListing>(adaptResidentialListingData);

export const ProjectProfileSchools = createComponent<GetProjectProfile>(adaptProjectProfileData);
