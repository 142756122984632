import React, { FunctionComponent } from 'react';
import { StyledAvatar, StyledAvatarWrapper } from './styles';

type ConsumerAvatarProps = {
    initials: string;
};

const ConsumerAvatar: FunctionComponent<ConsumerAvatarProps> = ({ initials }) => {
    return (
        <StyledAvatarWrapper>
            <StyledAvatar>{initials}</StyledAvatar>
        </StyledAvatarWrapper>
    );
};

export default ConsumerAvatar;
