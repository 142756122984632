/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import dataAdapter, { type RequiredProps } from './dataAdapter';
import type { GetResidentialListing, ResidentialListing } from '../../../models/lexa';
import type { Geocode } from '../../../models/lexa';
import ResidentialSchools from './ResidentialSchools.graphql';

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, ?Geocode>(
    dataAdapter,
    getFragmentData(ResidentialSchools),
    (data) => data.getListing()
);
