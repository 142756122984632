/* @flow */

import { StarFilledMd, paletteHelper, spacingHelper, Text } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

export const AvgRatingText = styled(Text).attrs({ variant: 'body02', forwardedAs: 'span' })`
    margin-right: ${spacingHelper('twoExtraSmall')};
`;

export const ReviewsText = styled(Text).attrs({ variant: 'body02', forwardedAs: 'span' })`
    color: ${paletteHelper('textSecondary')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const AgentRatingContainer = styled.div`
    display: flex;
`;

export const StarRating = styled(StarFilledMd)`
    color: ${paletteHelper('graphicYellow')};
    margin-right: ${spacingHelper('twoExtraSmall')};
`;

export const Placeholder = styled.div`
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: ${paletteHelper('backgroundFocusedPrimary')};
    background: linear-gradient(
        to right,
        ${paletteHelper('backgroundFocusedPrimary')} 8%,
        ${paletteHelper('backgroundSecondary')} 18%,
        ${paletteHelper('backgroundFocusedPrimary')} 33%
    );
    background-size: 900px 104px;
    width: 70%;
    height: ${spacingHelper('medium')};
    margin-bottom: ${spacingHelper('twoExtraSmall')};
`;
