/* @flow */
import React from 'react';
import type { MapViewTypes } from '../mapTypes';
import { MAP, SATELLITE, STREET_VIEW } from '../mapTypes';
import { ButtonGroup, ButtonGroupButton, spacingHelper, typographyHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

type Props = {
    onClick: (MapViewTypes) => () => void,
};

const LARGE_SCREEN_WIDTH = 768;

const mapControlButtonsConfig = [
    { mapViewType: MAP, label: 'Map', defaultChecked: true },
    { mapViewType: SATELLITE, label: 'Satellite', defaultChecked: false },
    { mapViewType: STREET_VIEW, label: 'Street view', defaultChecked: false },
];

const StyledButtonGroup = styled(ButtonGroup)`
    label {
        ${typographyHelper('subtitle02')};
        padding: ${spacingHelper('17px extraSmall')}; // to match $mapControlsHeight
    }

    @media (min-width: ${LARGE_SCREEN_WIDTH}px) {
        margin: ${spacingHelper('extraSmall auto')};
        width: 70%;
        label {
            ${typographyHelper('subtitle01')};
            padding: ${spacingHelper('extraSmall')};
        }
    }
`;

const StyledButton = styled(ButtonGroupButton)`
    &.controls-button:first-child label {
        border-radius: 0;
        @media (min-width: ${LARGE_SCREEN_WIDTH}px) {
            border-top-left-radius: ${({ theme }) => theme.border.radius.medium};
            border-bottom-left-radius: ${({ theme }) => theme.border.radius.medium};
        }
    }
    &.controls-button:last-child label {
        border-radius: 0;
        @media (min-width: ${LARGE_SCREEN_WIDTH}px) {
            border-top-right-radius: ${({ theme }) => theme.border.radius.medium};
            border-bottom-right-radius: ${({ theme }) => theme.border.radius.medium};
        }
    }
`;

const MapViewControls = ({ onClick }: Props) => {
    return (
        <StyledButtonGroup
            name="report-filter2"
            sizeVariant="medium"
            fullWidth={true}
            label="map-controls"
            hideLabel={true}
        >
            {mapControlButtonsConfig.map(({ mapViewType, label, defaultChecked }, index) => {
                return (
                    <StyledButton
                        label={label}
                        value={mapViewType}
                        key={index}
                        defaultChecked={defaultChecked}
                        onClick={onClick(mapViewType)}
                        className="controls-button"
                    />
                );
            })}
        </StyledButtonGroup>
    );
};

export default MapViewControls;
