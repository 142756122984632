import React from 'react';
import { ViewProps } from './types';
import { Dialog, H2, Modal } from '@rea-group/construct-kit-core';
import { Grid, GroupTitle, OverviewGroup } from './styles';
import AgentTiles from './agent-tiles';
import AgencyTile from './agency-tile';

const View = ({ mediaOverviewGroups, title, onClose, galleryEventTracker }: ViewProps) => (
    <Modal
        opened={true}
        onRequestClose={onClose}
        getAppElement={() => document.getElementById('argonaut-wrapper') || ''} // TODO: What should this fallback be?
    >
        <Dialog header={<H2>{title}</H2>}>
            {mediaOverviewGroups.map(({ items, title }, groupIndex) => (
                <OverviewGroup key={`mediaOverviewGroup-${groupIndex}`}>
                    {title && <GroupTitle>{title}</GroupTitle>}
                    <Grid>
                        {items.map((item, itemIndex) => {
                            const { __typename } = item;
                            if (__typename === 'MediaAgentElevateProfile') {
                                return (
                                    <AgentTiles
                                        agents={item.agentAvatars}
                                        galleryEventTracker={galleryEventTracker}
                                        key={`${__typename}-${itemIndex}`}
                                        __typename={__typename}
                                    />
                                );
                            }
                            if (__typename === 'MediaAgencyElevateProfile') {
                                return <AgencyTile {...item} key={`${__typename}-${itemIndex}`} />;
                            }
                            return (
                                <button className={`overview-${__typename}`} key={`${__typename}-${itemIndex}`}>
                                    {__typename}
                                </button>
                            );
                        })}
                    </Grid>
                </OverviewGroup>
            ))}
        </Dialog>
    </Modal>
);

export default View;
