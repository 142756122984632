/* @flow */
import React from 'react';
import View from './View';

const ContentLayoutPlaceholder = () => (
    <div className="layout">
        <div className="layout__content">
            <View />
        </div>
    </div>
);

export default ContentLayoutPlaceholder;
