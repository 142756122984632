/* @flow */
import React, { useContext } from 'react';
import { type Channel } from '../../../../../models/Channel';
import ChannelContext from '../../../../../util/ChannelContext';
import { Select } from '@rea-group/construct-kit-core';
import flatten from 'lodash/flatten';
import values from 'lodash/values';

export type ConsumerType = {
    label: string,
    value: string,
};

type Props = {|
    selectedOption: ?ConsumerType,
    handleChange: Function,
|};

const typesForChannel: { [Channel]: Array<ConsumerType> } = {
    buy: [
        { label: 'Buy but keep my current home', value: 'looking_to_buy_again' },
        { label: 'Buy and live in my first home', value: 'first_home_buyer' },
        { label: 'Buy an investment property', value: 'looking_to_invest' },
        { label: 'Buy and sell property', value: 'looking_to_buy_and_sell' },
        { label: 'Monitor the market', value: 'monitoring_the_market' },
        { label: 'Representing a client', value: 'representing_a_client' },
    ],
    rent: [],
    sold: [],
};

const mappedPrefillValue = (prefillValue: string): string => {
    const oldValuesMapping = {
        Buyer: 'looking_to_buy_again',
        'First home buyer': 'first_home_buyer',
        Investor: 'looking_to_invest',
        'Monitoring the market': 'monitoring_the_market',
    };

    return oldValuesMapping[prefillValue] ? oldValuesMapping[prefillValue] : prefillValue;
};

const prefillValueToOption = (prefillValue: string, channel: Channel): ?ConsumerType => {
    const allOptions = flatten(values(typesForChannel[channel]));
    return allOptions.find((op) => values(op).includes(mappedPrefillValue(prefillValue)));
};

export const consumerTypeInputOverPrefill = (
    channel: Channel,
    inputValue: ?ConsumerType,
    prefillValue: ?string
): ?ConsumerType => {
    if (inputValue) {
        return inputValue;
    }

    if (prefillValue) {
        return prefillValueToOption(prefillValue, channel);
    }
};

const EnquiryConsumer = ({ selectedOption, handleChange }: Props) => {
    const channel = useContext(ChannelContext);
    const options = typesForChannel[channel];

    return (
        options.length > 0 && (
            <Select
                id="contactAgentConsumerType"
                label="What are you looking to do?"
                options={options}
                selectedOption={selectedOption}
                onSelectedOptionChange={handleChange}
                placeholder="Please select"
                clearable={false}
                searchable={false}
            />
        )
    );
};

export default EnquiryConsumer;
