/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import markerIcon from '../images/map-pin.svg';
import { DEFAULT_DISPLAY_ADDRESS } from '../../../../models/Address';
import type {
    GetResidentialListing,
    ResidentialListing,
    ResidentialListingViewConfiguration,
    Address,
} from '../../../../models/lexa';
import type { AdaptedDataProps } from './mapTypes';
import MapAddress from './MapAddress.graphql';
import { createMarker } from './mapHelper';

type RequiredProps = {
    address: Address,
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = (listing) => {
    const { geocode, fullAddress, shortAddress } = listing.address.display;
    const { viewConfiguration } = listing;
    const DEFAULT_PIN_WIDTH = 18;
    const DEFAULT_PIN_HEIGHT = 26;

    if (geocode) {
        return {
            markers: [createMarker({ geocode, markerIcon, width: DEFAULT_PIN_WIDTH, height: DEFAULT_PIN_HEIGHT })],
            mapModalTitle: shortAddress || DEFAULT_DISPLAY_ADDRESS,
            ariaLabel: fullAddress ? `Map of ${fullAddress}` : DEFAULT_DISPLAY_ADDRESS,
            showMapSkyscraper: !!viewConfiguration.details.adverts.mapSkyscraper,
        };
    }

    return null;
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, ?AdaptedDataProps>(
    dataAdapter,
    getFragmentData(MapAddress),
    (data) => data.getListing()
);
