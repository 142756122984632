/* @flow */
import type { RemovePersonalisedData } from '../types';
import { remove as defaultRemove, type Action } from '../../details/contact-agent/contact-agent-form/enquiry-prefill';
import type { Dispatch } from 'redux';

type Remove = () => (dispatch: Dispatch<Action>) => void;

const createRemoveEnquiryFormPrefillData = (remove: Remove = defaultRemove): RemovePersonalisedData<{}, {}> => ({
    remove: (__, _, dispatch: Dispatch<Action>) => {
        remove()(dispatch);
    },
});

export default createRemoveEnquiryFormPrefillData;
