import React, { FunctionComponent } from 'react';
import { P, H3 } from '@rea-group/construct-kit-core';

import { FormContentContainer, FooterDivider, FooterContainer, FooterButton } from '../styles';

type ViewProps = {
    listingType: 'residential' | 'project-profile';
};

const View: FunctionComponent<ViewProps> = ({ listingType }) => {
    return (
        <React.Fragment>
            <FormContentContainer>
                <H3>Title Lorem Ipsum</H3>
                <P>Step: 4</P>
                <P>Listing type: {listingType}</P>
            </FormContentContainer>
            <FooterDivider />
            <FooterContainer>
                <FooterButton fullWidth={true} variant="secondary" onClick={() => alert('view enquired properties')}>
                    View Enquired Properties
                </FooterButton>
            </FooterContainer>
        </React.Fragment>
    );
};

export default View;
