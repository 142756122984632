/* @flow */
import React from 'react';
import View, { type SharedProps } from './View';
import type { GetResidentialListing } from '../../../models/lexa';
import adaptLexaData, { type AdaptedProps } from '../../advert/details-medrec/adaptLexaData';

type Props = {|
    adsData?: GetResidentialListing,
    ...SharedProps,
|};

export const DetailsLayout = ({ adsData, ...props }: Props) => {
    const adaptedData: AdaptedProps = adsData ? adaptLexaData(adsData) : { showSidebarMedRec: false };
    return <View {...adaptedData} {...props} />;
};
