/* @flow */
import { default as adaptParentAndSiblingsData } from './adaptParentAndSiblingsData';
import { default as adaptBuilderProfileData } from './adaptBuilderProfileData';
import type { ProjectProfile, ResidentialListingMedia, ListingCompany } from '../../../../models/lexa';
export { toPhotoOverlayWithGalleryItem } from './toPhotoOverlayWithGalleryItem';

type Feature = {
    display: string,
    label: string,
};

type PropertySize = {
    display: string,
    type: string,
};

type GeneralFeatures = {
    bedrooms: ?Feature,
    bathrooms: ?Feature,
    parkingSpaces: ?Feature,
};

type GalleryItem = {
    image: string,
    heading: ?string,
    subheading: ?string,
    description: string,
    url: string,
    generalFeatures: ?GeneralFeatures,
    propertySize: ?PropertySize,
};

export type PhotoOverlayGalleryItem = {
    mediaType: 'photo overlay with gallery',
    src: {
        url: string,
    },
    heading: string,
    subHeading: string,
    link: {
        href: string,
        title: string,
    },
    gallery: Array<GalleryItem>,
};

export type PhotoOverlayGalleryData = {
    templatedUrl: string,
    heading: string,
    subHeading: string,
    href: string,
    title: string,
    gallery: Array<GalleryItem>,
};

type Props = {
    id: string,
    parent: ?ProjectProfile,
    media: ResidentialListingMedia,
    listingCompany: ?ListingCompany,
};

export const adapter = ({ id, media, parent, listingCompany }: Props): Array<PhotoOverlayGalleryItem> => {
    if (parent) {
        return adaptParentAndSiblingsData({ id, media, parent });
    } else if (listingCompany && listingCompany.__typename === 'Builder') {
        return adaptBuilderProfileData({ media, builder: listingCompany });
    } else return [];
};
