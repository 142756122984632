/* @flow */
import { CameraMd, FloorplanMd, ThreedeeMd, VideoMd } from '@rea-group/construct-kit-core';
import './styles.scss';
import React from 'react';

export type Props = {
    className?: string,
    photoCount: number,
    openPhoto?: ?(e: SyntheticEvent<>) => void | ?(() => void),
    openFloorplan?: ?(e: SyntheticEvent<>) => void | ?(() => void),
    openThreeDimensionalTour?: ?(e: SyntheticEvent<>) => void | ?(() => void),
    openYouTubeVideo?: ?(e: SyntheticEvent<>) => void | ?(() => void),
    externalVideoLink?:
        | ?{
              href: string,
              action: () => void,
          }
        | ?{
              href: string,
              action: (e: SyntheticEvent<>) => void,
          },
};

export default ({
    className,
    photoCount,
    openPhoto,
    openFloorplan,
    openThreeDimensionalTour,
    openYouTubeVideo,
    externalVideoLink,
}: Props) => {
    const combinedClassName = `media-type-bar ${className || ''}`;
    return (
        <div className={combinedClassName}>
            <div className="media-type-bar__container">
                {openPhoto && (
                    <button
                        aria-label={`${photoCount} ${photoCount > 1 ? 'photos' : 'photo'} available`}
                        className="media-type-bar__media"
                        onClick={openPhoto}
                    >
                        <CameraMd />
                        <span data-testid="countIcon" className="media-type-bar__count">
                            {photoCount}
                        </span>
                        <span data-testid="countIcon" className="media-type-bar__text">
                            {photoCount > 1 ? 'Images' : 'Image'}
                        </span>
                    </button>
                )}
                {openYouTubeVideo && (
                    <button aria-label="videos available" className="media-type-bar__media" onClick={openYouTubeVideo}>
                        <VideoMd />
                        <span className="media-type-bar__text">Video</span>
                    </button>
                )}
                {externalVideoLink && (
                    <a
                        aria-label="external link videos available"
                        className="media-type-bar__media"
                        href={externalVideoLink.href}
                        onClick={externalVideoLink.action}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                    >
                        <VideoMd />
                        <span className="media-type-bar__text">Video</span>
                    </a>
                )}
                {openThreeDimensionalTour && (
                    <button
                        aria-label="virtualtours available"
                        className="media-type-bar__media"
                        onClick={openThreeDimensionalTour}
                    >
                        <ThreedeeMd />
                        <span className="media-type-bar__text">Tour</span>
                    </button>
                )}
                {openFloorplan && (
                    <button aria-label="floorplans available" className="media-type-bar__media" onClick={openFloorplan}>
                        <FloorplanMd />
                        <span className="media-type-bar__text">Floorplan</span>
                    </button>
                )}
            </div>
        </div>
    );
};
