/* @flow */
import type { ProjectProfileMedia, ResidentialListingMedia, Video, YouTubeVideo } from '../../../../models/lexa';
import type { VideoPlayedPageSource } from '../../../../client/tracking/event/types';

const toVideoSlide = (
    videoData: YouTubeVideo,
    trackVideoPlayed: (inPageSource: VideoPlayedPageSource) => void
): VideoItem => {
    return {
        mediaType: 'video',
        src: {
            videoId: videoData.id,
        },
        onPlay: () => {
            trackVideoPlayed('media_viewer_video_player');
        },
    };
};

type Props = {
    media: ResidentialListingMedia | ProjectProfileMedia,
    trackVideoPlayed: (inPageSource: VideoPlayedPageSource) => void,
};

export type VideoItem = {
    mediaType: 'video',
    src: {
        videoId: string,
    },
    onPlay: () => void,
};

const filterYouTubeVideos = (acc: Array<YouTubeVideo>, item: Video): Array<YouTubeVideo> => {
    if (item.__typename === 'YouTubeVideo') {
        return [...acc, item];
    }
    return acc;
};

export const adapter = ({ media, trackVideoPlayed }: Props): Array<VideoItem> => {
    const youtubeVideos: Array<YouTubeVideo> = media.videos.reduce(filterYouTubeVideos, []);
    return youtubeVideos.map((videoData) => {
        return toVideoSlide(videoData, trackVideoPlayed);
    });
};
