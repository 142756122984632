/* @flow */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import type { Element } from 'react';

import appLogger from '../../../util/logging/appLogger';

import type { GetMapUrlParameters, FetchMapUrl } from './dependencies';
import MapPlaceholder from './MapPlaceholder';
import MapLoader from './MapLoader';

export const MAP_ERROR_MSG = 'A map could not be loaded';
export const CONTACT_AGENT_MSG = 'Contact agent for address';

type Props = {
    fetchMapUrl: FetchMapUrl,
    getMapUrlParameters: GetMapUrlParameters,
    children: (?string) => ?Element<*>,
};

const FetchAndRenderMap = (props: Props) => {
    const detailsMapEl = useRef<HTMLDivElement | null>(null);
    const [googleMapsUrl, setGoogleMapsUrl] = useState<string | null>(null);
    const [showError, setShowError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [contactAgentForAddress, setContactAgentForAddress] = useState<boolean>(false);

    const errorHandler = useCallback((error: Error): void => {
        appLogger.error('error fetching google maps url')(error);
        setIsLoading(false);
        setShowError(true);
    }, []);

    const { getMapUrlParameters, fetchMapUrl } = props;

    useEffect(() => {
        const fetchStaticMapUrl = () => {
            const parameters = getMapUrlParameters(detailsMapEl);

            fetchMapUrl(parameters)
                .then((result) => {
                    result.fold(errorHandler, (optionalUrl) =>
                        optionalUrl.fold(
                            () => setContactAgentForAddress(true),
                            (url) => setGoogleMapsUrl(url)
                        )
                    );

                    setIsLoading(false);

                    return result;
                })
                .catch(errorHandler);
        };
        fetchStaticMapUrl();
    }, [fetchMapUrl, getMapUrlParameters, errorHandler]);

    if (contactAgentForAddress) return <MapPlaceholder label={CONTACT_AGENT_MSG} />;
    if (showError) return <MapPlaceholder label={MAP_ERROR_MSG} />;

    return (
        <div className="details-map" ref={detailsMapEl}>
            {isLoading && <MapLoader />}
            {props.children(googleMapsUrl)}
        </div>
    );
};

export default FetchAndRenderMap;
