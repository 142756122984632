/* @flow */
import getFragmentData from '../../../../util/getFragmentData';
import { compose3 as compose } from '../../../../util/functional/compose';
import createSidebarAdAddon from './createSidebarAdAddon';
import type { ResidentialListingViewConfiguration } from '../../../../models/lexa/viewConfigurationType';
import type { GetResidentialListing, GetTrackingData, TrackingData } from '../../../../models/lexa';
import MediaViewerSidebar from './MediaViewerSidebar.graphql';

type DataType = GetResidentialListing & GetTrackingData;

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter =
    (trackingData: ?TrackingData) =>
    ({ viewConfiguration }: RequiredProps): ?Object => {
        const showMediaViewerSidebar = viewConfiguration.details.adverts.mediaViewerSidebar;
        if (!showMediaViewerSidebar) {
            return null;
        }

        if (trackingData) {
            return createSidebarAdAddon(trackingData);
        }

        return null;
    };

const getAddOnData = (trackingData) => {
    return compose(dataAdapter(trackingData), getFragmentData(MediaViewerSidebar), (data) => data.getListing());
};

export default (data: DataType) => getAddOnData(data.getTrackingData())(data);
