import React, { FunctionComponent } from 'react';
import { P, H3, PencilMd } from '@rea-group/construct-kit-core';
import ConsumerAvatar from '../consumer-avatar';
import { EditButton, ProfileCard, ProfileDetails } from './styles';

type ContactDetailsCardProps = {
    firstName: string;
    lastName: string;
    email: string;
    postcode?: string;
    phone?: string;
};

const ContactDetailsCard: FunctionComponent<ContactDetailsCardProps> = ({
    firstName,
    lastName,
    email,
    postcode,
    phone,
}) => {
    const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
    const fullName = `${firstName} ${lastName}`;

    return (
        <ProfileCard>
            <ProfileDetails>
                <ConsumerAvatar initials={initials} />
                <H3>{fullName}</H3>
                <P>{email}</P>
                <P>{postcode ? `Postcode: ${postcode}` : 'No postcode'}</P>
                <P>{phone ? phone : 'No phone number'}</P>
            </ProfileDetails>
            <EditButton icon={<PencilMd />} variant="outline" hideLabel={true} compact={true} sizeVariant="small" />
        </ProfileCard>
    );
};

export default ContactDetailsCard;
