/* @flow */

import type { SchoolData } from '../types';
type Distance = {
    unit: string,
    value: number,
};

type ChildcareData = {
    distance: Distance,
    name: string,
    opening_times?: string | null,
    url: string,
};

type SchoolServiceData = {
    distance: Distance,
    name: string,
    sector: string,
    school_type: string,
    url: string,
};

const convertDistance = (distance: Distance) =>
    distance.unit === 'm' ? `${(distance.value / 1000).toFixed(2)}km` : `${distance.value}${distance.unit}`;

const childcareRowAdapter = (apiRows: Array<ChildcareData>): Array<SchoolData> => {
    return apiRows.map((row: ChildcareData) => {
        const openingTimes = row.opening_times;
        return {
            name: row.name,
            url: row.url,
            distance: convertDistance(row.distance),
            info1: openingTimes ? `Opening times: ${openingTimes}` : `Not Available`,
            info2: '',
        };
    });
};

const schoolsRowAdapter = (apiRows: Array<SchoolServiceData>): Array<SchoolData> => {
    return apiRows.map((row) => {
        return {
            name: row.name,
            url: row.url,
            distance: convertDistance(row.distance),
            info1: row.school_type,
            info2: row.sector,
        };
    });
};

const childcareDataAdapter = (childcareApiData: Object) => {
    return childcareApiData.preschool.length
        ? [
              {
                  id: 'childcare',
                  title: 'Child care',
                  rows: childcareRowAdapter(childcareApiData.preschool),
              },
          ]
        : [];
};

const schoolsDataAdapter = (schoolsApiData: Object) => {
    return [
        {
            id: 'primary',
            title: 'Primary',
            rows: schoolsRowAdapter(schoolsApiData.primary),
        },
        {
            id: 'secondary',
            title: 'Secondary',
            rows: schoolsRowAdapter(schoolsApiData.secondary),
        },
    ];
};

export { childcareDataAdapter, schoolsDataAdapter };
