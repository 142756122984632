/* @flow */
import type { Address } from '../../../models/lexa';
import type { Geocode } from '../../../models/lexa';

export type RequiredProps = {
    address: Address,
};

export default ({ address }: RequiredProps): ?Geocode => {
    const geoLocation = address.display.geocode;
    if (geoLocation) {
        return { latitude: geoLocation.latitude, longitude: geoLocation.longitude };
    }
    return null;
};
