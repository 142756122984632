/* @flow */
import type { ListingCompany } from '../../../../models/lexa/listingCompanyType';
import type { ResidentialListingMedia } from '../../../../models/lexa/listingMediaType';
import type { Channel } from '../../../../models/Channel';
import type { Lister, ResidentialListingViewConfiguration } from '../../../../models/lexa';
import type { Link, ListingLinks } from '../../../../models/lexa/linksType';
import { scrollTo } from '../../../../util/DOMUtils';
import type { ViewEmailAgentPageSource } from '../../../../client/tracking/event/types';

type AgencyBrandingAgency = {
    name: ?string,
    logo: ?string,
    brandingColors: {
        primary: ?string,
    },
    links: {
        agencyProfile: ?{
            href: string,
        },
    },
};

type AgencyBrandingListing = {
    greeting: ?string,
    image: ?string,
    link: {
        enquiryForm: string | null,
    },
};

export type AgencyBranding = {
    mediaType: 'agency branding',
    agency: AgencyBrandingAgency,
    listing: AgencyBrandingListing,
    webview: boolean,
};

const convertListingCompany = (listingCompany: ListingCompany, cid: string): AgencyBrandingAgency => {
    const {
        name,
        address: {
            display: { fullAddress },
        },
        media: { logo },
        branding: { primaryColour },
        _links: {
            canonical: { href },
        },
        ratingsReviews,
    } = listingCompany;
    return {
        name,
        fullAddress,
        logo: logo ? logo.templatedUrl.replace('{size}', '170x32') : null,
        brandingColors: {
            primary: primaryColour,
        },
        links: {
            agencyProfile: {
                href: href.replace('cid={cid}', cid),
            },
        },
        totalReviews: ratingsReviews?.totalReviews ?? 0,
        avgRating: ratingsReviews?.avgRating,
    };
};

const convertListing = (
    media: ResidentialListingMedia,
    { path }: Link,
    contactAgentEl: ?Element,
    trackEnquiryForm: (action: ViewEmailAgentPageSource) => void
): AgencyBrandingListing => {
    const {
        mainImage: { templatedUrl },
    } = media;
    return {
        greeting: 'Proudly represented by',
        image: templatedUrl.replace('{size}', '800x600'),
        link: {
            callback: contactAgentEl
                ? () => {
                      contactAgentEl && scrollTo(contactAgentEl);
                      trackEnquiryForm('agency branding slide');
                  }
                : null,
            enquiryForm: path ? `${path || ''}#contactAgent` : null,
        },
    };
};

export type AgencyBrandingRequiredData = {
    media: ResidentialListingMedia,
    _links: ListingLinks,
    listingCompany: ?ListingCompany,
    listers: ?Array<Lister>,
    viewConfiguration: ResidentialListingViewConfiguration,
};

const defaultAgency = {
    name: '',
    logo: null,
    fullAddress: null,
    brandingColors: {
        primary: null,
    },
    links: {},
    totalReviews: 0,
    avgRating: 0,
};

export const adapter = (
    { listingCompany, media, _links, viewConfiguration }: AgencyBrandingRequiredData,
    channel: Channel,
    contactAgentEl: ?Element,
    trackEnquiryForm: (source: ViewEmailAgentPageSource) => void
): Array<AgencyBranding> => {
    const {
        details: { showAgencyInMediaViewer },
    } = viewConfiguration;
    const { canonical } = _links;
    const cid = `campaignType=internal&campaignChannel=in_product&campaignSource=rea&campaignName=sell_enq&campaignPlacement=property-carousel&campaignKeyword=early_seeker&campaignVariant=agency_elevate&sourcePage=${channel}:pdp&sourceElement=property-carousel`;
    const shouldShowAgencyBranding = showAgencyInMediaViewer && channel === 'rent';

    return shouldShowAgencyBranding
        ? [
              {
                  mediaType: 'agency branding',
                  agency: listingCompany ? convertListingCompany(listingCompany, cid) : defaultAgency,
                  listing: convertListing(media, canonical, contactAgentEl, trackEnquiryForm),
                  webview: false,
              },
          ]
        : [];
};
