/* @flow */
import React, { type Node } from 'react';
import styled from 'styled-components';
import AuthToEnquirePrompt from './AuthToEnquirePrompt';

const AuthToEnquireWrapper = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    height: auto;
    overflow: hidden;
`;

const OverLayWrapper = styled.div`
    grid-area: 1 / 1;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const TranslucentItem = styled.div`
    position: relative;
    width: 100%;
    height: 5.5rem;
    background: linear-gradient(to bottom, transparent 0%, var(--ck-backgroundPrimaryLow) 100%);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5.5rem;
        content: '';
        background-color: var(--ck-backgroundPrimaryLow);
        opacity: 0.4;
    }
`;

const FormWrapper = styled.div`
    height: 5.5rem;
    grid-area: 1 / 1;
    z-index: 1;
    overflow: hidden;
`;

type Props = {
    enquiryForm: Node,
    onJoinOrSignInSuccess: () => void,
};

const AuthToEnquire = ({ enquiryForm, onJoinOrSignInSuccess }: Props) => {
    return (
        <AuthToEnquireWrapper data-testid="auth-to-enquire">
            <FormWrapper>{enquiryForm}</FormWrapper>
            <OverLayWrapper data-testid="overlay-content">
                <TranslucentItem data-testid="translucent-item" />
                <AuthToEnquirePrompt onJoinOrSignInSuccess={onJoinOrSignInSuccess} />
            </OverLayWrapper>
        </AuthToEnquireWrapper>
    );
};

export default AuthToEnquire;
