/* @flow */
import React from 'react';
import ReactDOM from 'react-dom';
import { sidebarAddon } from 'media-viewer/lib/addons/sidebar/index';
import { currentLayout, shouldDisplayModal, LAYOUT_DEFINITION } from '../../../../client/device-capability';
import { MediaViewerSkyscraperAd } from '../../../advert';
import type { TrackingData } from '../../../../models/lexa';

const shouldDisplaySkyscraper = shouldDisplayModal;

const renderSkyscraperAd = (trackingData, skyscraperIndex, sidebar): void => {
    ReactDOM.render(<MediaViewerSkyscraperAd trackingData={trackingData} skyscraperIndex={skyscraperIndex} />, sidebar);
};

export default (trackingData: ?TrackingData) => {
    if (!trackingData || !shouldDisplaySkyscraper()) {
        return null;
    }

    let skyscraperIndex = 0;

    const shouldExpand = currentLayout() === LAYOUT_DEFINITION.XL;

    const sidebarData = {
        initCallback: (sidebar) => {
            renderSkyscraperAd(trackingData, skyscraperIndex, sidebar);
        },
        refreshCallback: (sidebar) => {
            skyscraperIndex += 1;
            renderSkyscraperAd(trackingData, skyscraperIndex, sidebar);
        },
        destroyCallback: (sidebar) => {
            sidebar && ReactDOM.unmountComponentAtNode(sidebar);
        },
    };

    const width = shouldExpand ? '340px' : '200px';

    const options = { width };

    return {
        type: 'sidebarAddon',
        ...sidebarAddon({
            ...sidebarData,
            options,
        }),
    };
};
