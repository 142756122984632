/* @flow */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { paletteHelper, spacingHelper, Stack, Button, H2, P } from '@rea-group/construct-kit-core';
import { useLockeAuthentication } from '@realestate.com.au/user-react';
import { createBrowserUser } from '@realestate.com.au/user';
import type { GetEventTracker } from '../../../../models/lexa';
import { eventTracker } from '../../../../client/tracking/event';

export const BoxWithPadding = styled.div`
    position: relative;
    padding: ${spacingHelper('medium')};
    border: 1px solid ${paletteHelper('borderSecondary')};
    border-radius: ${({ theme }) => theme.border.radius.medium};
    margin-top: ${spacingHelper('extraLarge')};
    background: ${paletteHelper('backgroundPrimary')};
`;

const Description = styled(P)`
    color: ${paletteHelper('textPrimary')};
`;

const ButtonsWrapper = styled(Stack).attrs({ gap: 'medium' })`
    ${({ theme }) => theme.breakpoints.medium} {
        flex-direction: row;
        & > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: ${spacingHelper('medium')};
        }
        & > * {
            flex-grow: 1;
        }
    }
`;

type DriveToRegistrationProps = {
    data: GetEventTracker,
    onJoinOrSignInSuccess: () => void,
};

const TRACKING_CONFIGURATION = {
    signIn: {
        tealiumOpenModal: 'post_enquiry_registration_sign_in',
        lockeInteraction: 'sign_in',
    },
    join: {
        tealiumOpenModal: 'post_enquiry_registration_join',
        lockeInteraction: 'join',
    },
};

const TEALIUM_TRACKING_TYPE = 'post_enquiry_registration';

const TITLE_TEXT = `Want us to remember your details in the future?`;
const DESCRIPTION_TEXT =
    'Sign in or create an REA account and we’ll remember your details when you enquire. That way, you can breeze right through your future enquiries.';

const getInteraction = (isNewUser) => (isNewUser ? 'successful_registration' : 'successful_sign_in');

const DriveToRegistration = ({ data, onJoinOrSignInSuccess }: DriveToRegistrationProps) => {
    const { openAuthenticationForm, authenticationSuccess } = useLockeAuthentication();
    const user = createBrowserUser();

    useEffect(() => {
        if (authenticationSuccess) {
            eventTracker.myREAInteraction(TEALIUM_TRACKING_TYPE, getInteraction(user.isNewUser));
            onJoinOrSignInSuccess();
        }
    }, [authenticationSuccess, onJoinOrSignInSuccess, user.isNewUser]);

    useEffect(() => {
        data.getEventTracker().personalisedImpression(TEALIUM_TRACKING_TYPE);
    }, [data]);

    const handleClick = (interaction) => {
        const { tealiumOpenModal, lockeInteraction } = TRACKING_CONFIGURATION[interaction];
        eventTracker.myREAModalOpen(tealiumOpenModal);
        openAuthenticationForm(lockeInteraction);
    };

    return (
        <BoxWithPadding>
            <Stack gap="medium">
                <H2>{TITLE_TEXT}</H2>
                <Description>{DESCRIPTION_TEXT}</Description>
                <ButtonsWrapper>
                    <Button variant="outline" onClick={() => handleClick('signIn')}>
                        Sign in
                    </Button>
                    <Button onClick={() => handleClick('join')}>Join</Button>
                </ButtonsWrapper>
            </Stack>
        </BoxWithPadding>
    );
};

export default DriveToRegistration;
