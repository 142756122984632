/* @flow */
import type { ProjectProfileMedia, ResidentialListingMedia, ThreeDimensionalTour } from '../../../../models/lexa';

const toThreeDimensionalTourItem = (
    threeDimensionalTourData: ThreeDimensionalTour,
    trackThreeDimensionalTourPlayed: () => void
): ThreeDimensionalTourItem => {
    return {
        mediaType: 'virtualtour',
        src: {
            url: threeDimensionalTourData.href,
        },
        onPlay: trackThreeDimensionalTourPlayed,
    };
};

type Props = {
    media: ResidentialListingMedia | ProjectProfileMedia,
    trackThreeDimensionalTourPlayed: () => void,
};

export type ThreeDimensionalTourItem = {
    mediaType: 'virtualtour',
    src: {
        url: string,
    },
    onPlay: () => void,
};

export const adapter = ({ media, trackThreeDimensionalTourPlayed }: Props): Array<ThreeDimensionalTourItem> => {
    return media.threeDimensionalTours.map((tour) => {
        return toThreeDimensionalTourItem(tour, trackThreeDimensionalTourPlayed);
    });
};
