/* @flow */

import type { ExternalBreadcrumbItem, InternalBreadcrumbItem } from '../../breadcrumb/types';
import type { PageType } from '../../navigation/reducer';
import type { GetProjectProfile } from '../../../models/lexa';
import { compose3 as compose } from '../../../util/functional/compose';
import { getProjectProfileBreadcrumbItems } from '../../../models/breadcrumb';
import getFragmentData from '../../../util/getFragmentData';
import ProjectProfileBreadcrumb from './ProjectProfileBreadcrumb.graphql';
import {
    matchesNewHomesURL,
    matchesBuySoldLandingPageURL,
    transformToExternalBreadcrumbItem,
    transformToInternalBreadcrumbItem,
} from '../../../models/breadcrumb';

type AdaptedProps = {
    items: Array<InternalBreadcrumbItem | ExternalBreadcrumbItem>,
};

const adaptProjectProfileData = (fromPage: PageType) =>
    compose(
        getProjectProfileBreadcrumbItems(fromPage),
        getFragmentData(ProjectProfileBreadcrumb),
        (data: GetProjectProfile) => data.getListing()
    );

export default (fromPage: PageType, data: GetProjectProfile): AdaptedProps => {
    const breadcrumbItems = adaptProjectProfileData(fromPage)(data);
    const items = breadcrumbItems.map((item) =>
        matchesBuySoldLandingPageURL(item.to) || matchesNewHomesURL(item.to)
            ? transformToExternalBreadcrumbItem(item)
            : transformToInternalBreadcrumbItem(item)
    );
    return { items };
};
