/* @flow */
import type { ListingCompany } from '../../../../models/lexa/listingCompanyType';
import type { ResidentialListingMedia } from '../../../../models/lexa/listingMediaType';
import type { Channel } from '../../../../models/Channel';
import type { Lister } from '../../../../models/lexa';
import type { Link, ListingLinks } from '../../../../models/lexa/linksType';
import { scrollTo } from '../../../../util/DOMUtils';
import type { ViewEmailAgentPageSource } from '../../../../client/tracking/event/types';

type PowerProfileAgency = {
    name?: string,
    logo: ?string,
    brandingColors: {
        primary: ?string,
    },
    links: {
        agencyProfile?: {
            href: string,
        },
        agencyProfileWebview?: {
            href: string,
        },
    },
};

type PowerProfileAgent = {
    id: string,
    agentId: ?string,
    name?: string,
    title?: string,
    photo: ?string,
    links: {
        agentProfile: ?{
            href: string,
        },
        agentProfileWebview?: {
            href: string,
        },
    },
};

type PowerProfileListing = {
    greeting: ?string,
    channel: Channel,
    cta?: string,
    image?: string,
    link: {
        callback: ?() => void,
        buyEnquiryForm: ?string,
    },
};

export type PowerProfile = {
    mediaType: 'power profile',
    agency: PowerProfileAgency,
    agents: Array<PowerProfileAgent>,
    listing: PowerProfileListing,
    webview: boolean,
};

const convertLister =
    (cid: string) =>
    (lister: Lister): PowerProfileAgent => {
        return {
            id: lister.id,
            agentId: lister.agentId,
            name: lister.name,
            title: lister.jobTitle || '',
            photo: lister.photo && lister.photo.templatedUrl.replace('{size}', '300x400'),
            links: {
                agentProfile: lister._links.canonical
                    ? { href: lister._links.canonical.href.replace('cid={cid}', cid) }
                    : null,
            },
        };
    };

const convertListingCompany = (listingCompany: ListingCompany, cid: string): PowerProfileAgency => {
    return {
        name: listingCompany.name,
        logo: listingCompany.media.logo ? listingCompany.media.logo.templatedUrl.replace('{size}', '170x32') : null,
        brandingColors: {
            primary: listingCompany.branding.primaryColour,
        },
        links: {
            agencyProfile: {
                href: listingCompany._links.canonical.href.replace('cid={cid}', cid),
            },
        },
    };
};

const convertListing = (
    media: ResidentialListingMedia,
    links: Link,
    channel: Channel,
    contactAgentEl: ?Element,
    trackEnquiryForm: (action: ViewEmailAgentPageSource) => void
): PowerProfileListing => {
    return {
        greeting: 'Proudly represented by',
        channel: channel,
        image: media.mainImage.templatedUrl.replace('{size}', '800x600'),
        link: {
            callback:
                contactAgentEl && channel === 'buy'
                    ? () => {
                          contactAgentEl && scrollTo(contactAgentEl);
                          trackEnquiryForm('power profile slide');
                      }
                    : null,
            buyEnquiryForm: channel === 'buy' ? `${links.path || ''}#contactAgent` : null,
        },
    };
};

export type PowerProfileRequiredData = {
    media: ResidentialListingMedia,
    _links: ListingLinks,
    listingCompany: ?ListingCompany,
    listers: ?Array<Lister>,
};

const defaultPowerProfileAgency = {
    logo: null,
    brandingColors: {
        primary: null,
    },
    links: {},
};

export const adapter = (
    data: PowerProfileRequiredData,
    channel: Channel,
    contactAgentEl: ?Element,
    trackEnquiryForm: (source: ViewEmailAgentPageSource) => void
): Array<PowerProfile> => {
    const cid = `campaignType=internal&campaignChannel=in_product&campaignSource=rea&campaignName=sell_enq&campaignPlacement=property-carousel&campaignKeyword=early_seeker&campaignVariant=agent_elevate&sourcePage=${channel}:pdp&sourceElement=property-carousel`;
    if (data.listers == null || data.listers.length === 0) return [];

    const listers = data.listers.filter((lister) => lister.showInMediaViewer).map(convertLister(cid));
    return listers.length === 0
        ? []
        : [
              {
                  mediaType: 'power profile',
                  agency: data.listingCompany
                      ? convertListingCompany(data.listingCompany, cid)
                      : defaultPowerProfileAgency,
                  agents: listers,
                  listing: convertListing(data.media, data._links.canonical, channel, contactAgentEl, trackEnquiryForm),
                  webview: false,
              },
          ];
};
