/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import type { GetEventTracker, GetResidentialListing, ResidentialListing } from '../../../../models/lexa';
import type { AdapterProps } from './types';
import type { ListingLinks } from '../../../../models/lexa/linksType';
import type { ResidentialListingViewConfiguration } from '../../../../models/lexa/viewConfigurationType';
import getFragmentData from '../../../../util/getFragmentData';
import type { ListingCompany } from '../../../../models/lexa/listingCompanyType';
import type { Channel } from '../../../../util/ChannelContext';

import ResidentialListingContactAgentForm from './ResidentialListingContactAgentForm.graphql';

export type Props = {
    id: string,
    _links: ListingLinks,
    listingCompany: ?ListingCompany,
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter =
    (channel: Channel) =>
    ({ id, _links, viewConfiguration, listingCompany }: Props): AdapterProps => ({
        listingId: id,
        url: _links.submitEnquiry.href,
        shouldRenderAgentContactForm: !(listingCompany === null && channel === 'sold'),
        preEnquiryLogin: viewConfiguration.details.enquiryForm.preEnquiryLogin,
    });

export const getTracker = (data: GetEventTracker) => data.getEventTracker().emailAgent;

export const getExperimentInteractionTracker = (data: GetEventTracker) => data.getEventTracker().experimentInteraction;

export default (channel: Channel) =>
    compose<GetResidentialListing, ResidentialListing, Props, AdapterProps>(
        dataAdapter(channel),
        getFragmentData(ResidentialListingContactAgentForm),
        (data) => data.getListing()
    );
