/* @flow */
import React, { useContext } from 'react';
import ChannelContext from '../../../../../util/ChannelContext';
import { Radio, Label, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { type Channel } from '../../../../../models/Channel';

type Props = {|
    selectedOption: ?string,
    handleChange: Function,
|};

export const preapprovalMessage = (preapproval: string) => {
    const lineBreak = '\n';
    const preapprovalAnswer =
        preapproval === 'yes'
            ? `${lineBreak}- I have finance pre-approval`
            : preapproval === 'no'
              ? `${lineBreak}- I don't have finance pre-approval yet`
              : '';
    return preapproval === 'yes' || preapproval === 'no'
        ? `${lineBreak}${lineBreak}Additional information:${preapprovalAnswer}`
        : '';
};

export const preapprovalInputOverPrefill = (channel: Channel, inputValue: string, prefillValue: ?string) => {
    if (channel === 'buy') {
        return inputValue ? inputValue : prefillValue ? prefillValue : '';
    }
    return '';
};

const Radios = styled.div`
    display: block;
    & > * {
        padding: ${spacingHelper('extraSmall extraLarge extraSmall 0')};
    }
    ${({ theme }) => theme.breakpoints.medium} {
        display: flex;
    }
`;

const Question = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${spacingHelper('twoExtraSmall')};
`;

const EnquiryPreapproval = ({ selectedOption, handleChange }: Props) => {
    const channel = useContext(ChannelContext);

    return channel === 'buy' ? (
        <div>
            <Question>
                <Label
                    tooltip={`Having finance pre-approval means you’ve gotten the thumbs up from your bank and you’re ready to make an offer.`}
                >
                    Do you have finance pre-approval?
                </Label>
            </Question>
            <Radios>
                <Radio
                    id="finance-pre-approval-yes"
                    name="finance-pre-approval"
                    label="Yes"
                    value="yes"
                    onChange={handleChange}
                    checked={selectedOption === 'yes'}
                />
                <Radio
                    id="finance-pre-approval-no"
                    name="finance-pre-approval"
                    label="No"
                    value="no"
                    onChange={handleChange}
                    checked={selectedOption === 'no'}
                />
                <Radio
                    id="finance-pre-approval-ratherNotSay"
                    name="finance-pre-approval"
                    label="Rather not say"
                    value="ratherNotSay"
                    onChange={handleChange}
                    checked={selectedOption === 'ratherNotSay'}
                />
            </Radios>
        </div>
    ) : null;
};

export default EnquiryPreapproval;
