/* @flow */
import { toPhotoOverlayWithGalleryItem } from './toPhotoOverlayWithGalleryItem';
import createPicture from '../../../../models/Picture';
import type { PhotoOverlayGalleryItem } from './index';
import type { Builder, HomeDesign, ResidentialListingMedia } from '../../../../models/lexa';
import type { HomeDesignGeneralFeatures, HomeDesignHouseSizeRange } from '../../../../models/lexa/homeDesignType';

type Props = {
    builder: Builder,
    media: ResidentialListingMedia,
};

const convertPhotoOverlayWithGalleryData = (backgroundImage: string, builder: Builder) => {
    const { name, homeDesigns } = builder;

    if (homeDesigns) {
        const { totalCount, designs } = homeDesigns;
        const numberOfHomeDesigns = totalCount > 1 ? `${totalCount} home designs` : 'home design';
        const cid = `nhbpp|buy:pdp:mediaviewer-overview`;
        return {
            heading: name,
            templatedUrl: backgroundImage,
            subHeading: `Explore ${numberOfHomeDesigns} by ${name}`,
            href: builder._links.canonical.href.replace('{cid}', cid),
            title: 'View more home designs',
            gallery: totalCount > 1 ? convertGalleryData(designs, name) : [],
        };
    }
};

const convertGalleryData = (galleryItems: Array<HomeDesign>, builderName: string) => {
    return galleryItems.slice(0, 3).map((design) => convertGalleryItem(design, builderName));
};

const convertGalleryItem = (design: HomeDesign, builderName: string) => {
    const cid = 'nhhdp|buy:pdp:mediaviewer-homedesign';
    const { _links, media, price, name, generalFeaturesDisplay, houseSizeRange } = design;
    return {
        url: _links.canonical.href.replace('{cid}', cid),
        image: createPicture(media.mainImage.templatedUrl).ofSize('310x175'),
        heading: price,
        subheading: name,
        description: `By ${builderName}`,
        generalFeatures: convertGeneralFeatures(generalFeaturesDisplay),
        propertySize: convertPropertySize(houseSizeRange),
    };
};

const convertGeneralFeatures = (generalFeatures: HomeDesignGeneralFeatures) => {
    const { bedrooms, bathrooms, parkingSpaces } = generalFeatures;

    return {
        bedrooms: generateFeature(bedrooms, 'bedroom'),
        bathrooms: generateFeature(bathrooms, 'bathroom'),
        parkingSpaces: generateFeature(parkingSpaces, 'parking space'),
    };
};

const generateFeature = (value: string, label: string) => {
    const numericalValue = parseFloat(value.indexOf('-') !== -1 ? value.split('-')[1] : value);
    return !isNaN(numericalValue) && numericalValue > 0
        ? {
              display: value,
              label: `${value} ${label}${numericalValue > 1 ? 's' : ''}`,
          }
        : null;
};

const convertPropertySize = (houseSizeRange: ?HomeDesignHouseSizeRange) => {
    if (!houseSizeRange) return null;

    return {
        display: generatePropertySizeDisplay(houseSizeRange),
        type: 'building',
    };
};

const generatePropertySizeDisplay = ({ min, max }) => {
    const unit = 'm\u00B2';
    return min.value === max.value ? `${max.displayValue}${unit}` : `${min.displayValue}-${max.displayValue}${unit}`;
};

export default ({ media, builder }: Props): Array<PhotoOverlayGalleryItem> => {
    const backgroundImage = media.mainImage.templatedUrl;
    const photoOverlayGalleryData = convertPhotoOverlayWithGalleryData(backgroundImage, builder);
    return photoOverlayGalleryData ? [toPhotoOverlayWithGalleryItem(photoOverlayGalleryData)] : [];
};
