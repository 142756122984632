import type { GetComponentEventTracker, GetComponentGetters } from '../../../types';
import compose from '../../util/functional/typescript/compose';
import { ActionPanel, AdaptedProps, TrackingAwareActions } from './types';

const dataAdapter = ({
    component,
    getComponentTracker,
}: {
    component: ActionPanel | null;
    getComponentTracker: GetComponentEventTracker['getComponentTracker'];
}): AdaptedProps => {
    if (!component) {
        return {
            actions: [],
        };
    }

    return {
        actions: component.actions.map((action) => {
            if ('interactionEventSchemaData' in action) {
                const { interactionEventSchemaData, ...rest } = action;
                return {
                    ...rest,
                    trackEvent: getComponentTracker(interactionEventSchemaData),
                } satisfies TrackingAwareActions;
            }

            return action;
        }),
    };
};

const adaptLexaData = compose(dataAdapter, (data: GetComponentGetters) => ({
    component: data.getComponent('ActionPanelComponent'),
    getComponentTracker: data.getComponentTracker,
}));

export default adaptLexaData;
