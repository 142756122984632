import { H3, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

export const OverviewGroup = styled.div`
    padding: ${spacingHelper('medium')} ${spacingHelper('medium')} ${spacingHelper('large')} ${spacingHelper('medium')};
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${spacingHelper('medium')};
    grid-auto-flow: dense;

    & > *:nth-child(3n - 2),
    & > .overview-MediaFloorplan,
    & > .overview-MediaVirtualTour,
    & > .overview-MediaYouTubeVideo,
    & > .overview-MediaExternalVideo,
    & > .overview-MediaAgentElevateProfile,
    & > .overview-MediaAgencyElevateProfile {
        grid-column-end: span 2;
    }
`;

export const GroupTitle = styled(H3)`
    padding-bottom: ${spacingHelper('medium')};
`;
