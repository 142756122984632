/* @flow */
import type { Lister } from '../../models/lexa';
import type { TrackingAgent } from '../../client/tracking/tealium/dataLayerFormat';
import type { DataType } from './adaptResidentialListingData';
import type { EventMediaData } from '../../client/tracking/event/types';

export const getTrackingAgents = (listers: ?Array<Lister>): Array<TrackingAgent> =>
    (listers || [])
        .filter((lister) => lister.showInMediaViewer)
        .map((lister) => ({ agent_id: lister.agentId || null, agent_profile_id: lister.id }));

type EventTrackerData = {
    eventTracker: (mediaData: EventMediaData) => void,
};

export const getEventTrackerData = (data: DataType): EventTrackerData => ({
    eventTracker: data.getEventTracker().mediaChange,
});
