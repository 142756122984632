import { spacingHelper, paletteHelper, StarFilledMd, Text, ArrowRightMd, Card } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { TemplatedLazyImage } from '../../../components/Image';

export const StyledCard = styled(Card)`
    display: flex;
    width: 100%;
    padding: ${spacingHelper('medium')} ${spacingHelper('small')};
    gap: ${spacingHelper('small')};
    background: ${paletteHelper('backgroundPrimary')};
    border: 1px solid;
    border-color: ${paletteHelper('borderSecondary')};
    align-items: center;
`;

export const AgentImg = styled(TemplatedLazyImage)`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
`;

export const AgentInfo = styled.div`
    gap: ${spacingHelper('twoExtraSmall')};
    flex: 1;
    min-width: 0;
`;

export const AgentName = styled(Text).attrs({ variant: 'subtitle01' })`
    color: ${paletteHelper('textPrimary')};
    font-size: 1rem;
`;

export const JobTitle = styled.p`
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${paletteHelper('textSecondary')};
`;

export const StarRating = styled(StarFilledMd)`
    color: ${paletteHelper('graphicYellow')};
`;

export const RatingReviews = styled.div`
    display: flex;
    align-items: stretch;
    font-size: 0.75rem;
    color: ${paletteHelper('textSecondary')};
    gap: 0.25rem;
`;

export const ArrowContainer = styled.div`
    margin-left: auto;
`;

export const Arrow = styled(ArrowRightMd)`
    color: ${paletteHelper('textPrimary')};
`;

export const AgentTileLink = styled.a`
    text-decoration: none;
`;
