/* @flow */
import { adapter as adaptThreeDimensionalTours } from './3d-tour';
import { adapter as adaptPhotoOverlayWithGallery } from './photoOverlayWithGallery';
import { adapter as adaptPowerProfile } from './powerProfile';
import { adapter as adaptAgencyBranding } from './agencyBranding';
import { adapter as adaptVideos } from './video';
import type {
    ProjectProfile,
    ListingCompany,
    ListingLinks,
    ResidentialListingMedia,
    Lister,
    GetBuyResidentialListing,
    BuyResidentialListing,
    ResidentialListingViewConfiguration,
} from '../../../models/lexa';
import type { Channel } from '../../../models/Channel';
import type { AdaptedProps } from '../adaptResidentialListingData';
import { toFloorplanItem, toPhotoItem } from '../toMediaViewerFormat';
import type { ViewEmailAgentPageSource, VideoPlayedPageSource } from '../../../client/tracking/event/types';
import getFragmentData from '../../../util/getFragmentData';
import { compose3 as compose } from '../../../util/functional/compose';
import ResidentialSlides from './ResidentialSlides.graphql';

export type RequiredData = {
    id: string,
    media: ResidentialListingMedia,
    _links: ListingLinks,
    listingCompany: ?ListingCompany,
    listers: ?Array<Lister>,
    parent: ?ProjectProfile,
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter =
    (
        channel: Channel,
        contactAgentEl: ?Element,
        trackEnquiryForm: (action: ViewEmailAgentPageSource) => void,
        trackVideoPlayed: (inPageSource: VideoPlayedPageSource) => void,
        trackThreeDimensionalTourPlayed: () => void
    ) =>
    ({ id, media, listingCompany, listers, _links, parent, viewConfiguration }: RequiredData): AdaptedProps => {
        const images = media.images.map(toPhotoItem);
        const floorplans = media.floorplans.map(toFloorplanItem);

        const mediaItems = images
            .concat(adaptVideos({ media, trackVideoPlayed }))
            .concat(adaptThreeDimensionalTours({ media, trackThreeDimensionalTourPlayed }))
            .concat(floorplans)
            .concat(adaptPhotoOverlayWithGallery({ id, media, parent, listingCompany }))
            .concat(
                adaptPowerProfile({ media, listingCompany, listers, _links }, channel, contactAgentEl, trackEnquiryForm)
            )
            .concat(
                adaptAgencyBranding(
                    { media, listingCompany, listers, _links, viewConfiguration },
                    channel,
                    contactAgentEl,
                    trackEnquiryForm
                )
            );
        return { mediaItems };
    };

export default (
    channel: Channel,
    contactAgentEl: ?Element,
    trackEnquiryForm: (action: ViewEmailAgentPageSource) => void,
    trackVideoPlayed: (inPageSource: VideoPlayedPageSource) => void,
    trackThreeDimensionalTourPlayed: () => void
) =>
    compose<GetBuyResidentialListing, BuyResidentialListing, RequiredData, AdaptedProps>(
        dataAdapter(channel, contactAgentEl, trackEnquiryForm, trackVideoPlayed, trackThreeDimensionalTourPlayed),
        getFragmentData(ResidentialSlides),
        (data) => data.getListing()
    );
