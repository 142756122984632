/* @flow */
import type { DetailsResponse } from '../../models/lexa';

export type DetailsData = {
    +type: 'FULL_DETAILS' | 'PARTIAL_DETAILS',
    +value: DetailsResponse,
};

export const FULL_DETAILS = 'FULL_DETAILS';
export const PARTIAL_DETAILS = 'PARTIAL_DETAILS';

export const fullDetails = <V: DetailsResponse>(value: V): { type: 'FULL_DETAILS', value: V } => ({
    type: FULL_DETAILS,
    value,
});

export const partialDetails = <V: DetailsResponse>(value: V): { type: 'PARTIAL_DETAILS', value: V } => ({
    type: PARTIAL_DETAILS,
    value,
});

export const isPartialDetails = (data: DetailsData): boolean => data.type === PARTIAL_DETAILS;

export const shouldRenderBelowTheFold = (data: DetailsData): boolean => data.type === FULL_DETAILS;
