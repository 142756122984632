/* @flow */
import React, { useCallback, useEffect, useState, type Node } from 'react';
import UrlChangeObserver from '../../UrlChangeObserver';
import type { GetPageTracker, GetActiveExperiments } from '../../../models/lexa';
import { stateProvider } from '../../navigation';
import type { PageType } from '../../navigation/reducer';
import type { RouterLocation } from '../../../util/routerTypes';
import { useExperimentsTrackingData } from '@rea-argonaut/experiment';
import { getExperimentsContextData } from '../../../experiments/tracking/getExperimentsContextData';
import type { CustomUserAttributes } from '../../../experiments/types';

type Props = {|
    data: GetPageTracker & GetActiveExperiments,
    location: RouterLocation,
    children: Node,
    fromPage: PageType,
    customUserAttributes?: CustomUserAttributes,
|};

function PageTracker(props: Props) {
    const {
        children,
        data: { getPageTracker, getActiveExperiments },
        fromPage,
        location,
        customUserAttributes,
    } = props;

    const [tracked, setTracked] = useState(false);

    const { getExperimentsTrackingData } = useExperimentsTrackingData();

    const handleUrlChange = useCallback(() => {
        const activeExperiments = getActiveExperiments();
        const experimentsContextData = getExperimentsContextData(
            activeExperiments,
            getExperimentsTrackingData,
            customUserAttributes
        );
        const trackedResult = getPageTracker().track(experimentsContextData);
        setTracked(trackedResult);
    }, [getActiveExperiments, getExperimentsTrackingData, customUserAttributes, getPageTracker]);

    useEffect(() => {
        if (!tracked) {
            handleUrlChange();
        }
    }, [children, getPageTracker, tracked, fromPage, handleUrlChange]);

    return (
        <UrlChangeObserver url={location} onUrlChange={handleUrlChange} triggerOnMount={false}>
            {children}
        </UrlChangeObserver>
    );
}

export default stateProvider(PageTracker);
export { PageTracker as PageTrackerWithoutStateProvider };
